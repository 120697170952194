import { History } from 'history';
import * as React from 'react';
import { Router } from 'react-router-dom';

interface HistoryRouterProps {
  basename?: string;
  children?: React.ReactNode;
  history: History;
}

/**
 * HistoryRouter
 *
 * Adds a `HistoryRouter` to use a standalone `history` object, which we need
 * for `Pharos UI` support.
 *
 * Based on:
 * @see {@link https://github.com/remix-run/react-router/pull/7586}
 *
 * TODO:
 * - Migrate to native `HistoryRouter` once the above PR is merged.
 */
export const HistoryRouter: React.FC<HistoryRouterProps> = ({
  basename,
  children,
  history,
}) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  /* eslint-disable react/no-children-prop */
  return React.createElement(Router, {
    basename,
    children,
    location: state.location,
    navigationType: state.action,
    navigator: history,
  });
};
