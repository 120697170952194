import { useQuery } from '@apollo/client';

import { STACK_ACTIONS, STACK_ACTIONS_V1 } from 'src/queries/rest/actions';
import { RbacVersion } from 'src/types/__generated__/types';
import { Action } from 'src/types/rest/rbac';
import { ActionListResponse } from 'src/types/rest/rbac.v1';

interface ActionsQueryV0 {
  stackActions: Action[];
}

interface ActionsQueryV1 {
  stackActionsV1: ActionListResponse;
}

type ActionsQuery = ActionsQueryV0 | ActionsQueryV1;

interface ActionsQueryVariables {
  styraStackId: string;
}

interface UseStackActionsProps {
  rbacVersion?: RbacVersion | null;
  styraStackId?: string | null;
}

export const useStackActions = ({
  rbacVersion,
  styraStackId,
}: UseStackActionsProps) => {
  const ACTIONS_QUERY =
    rbacVersion === RbacVersion.V0 ? STACK_ACTIONS : STACK_ACTIONS_V1;

  const skip = !styraStackId || !rbacVersion;
  const variables = styraStackId ? { styraStackId } : undefined;

  const { data, error, loading } = useQuery<
    ActionsQuery,
    ActionsQueryVariables
  >(ACTIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables,
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as ActionsQueryV0).stackActions
        : (data as ActionsQueryV1).stackActionsV1.results
      : undefined,
    error,
    loading,
  };
};
