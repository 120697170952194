import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { CREATE_TOKEN } from 'src/queries/rest/token';
import {
  TokenCreationInput,
  TokenCreationResponse,
} from 'src/types/rest/tokens';

interface CreateTokenMutation {
  createToken: TokenCreationResponse;
}

interface CreateTokenMutationVariables {
  input: TokenCreationInput;
}

interface UseCreateTokenProps {
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useCreateToken = ({ onCompleted, t }: UseCreateTokenProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    CreateTokenMutation,
    CreateTokenMutationVariables
  >(CREATE_TOKEN, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.created'),
          name: t('common:glossary.token'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'created',
        name: 'token',
      });
    },
  });

  return {
    data: data?.createToken,
    error,
    loading,
    mutate,
  };
};
