import { gql } from '@apollo/client';

/**
 * Queries
 */
export const TOKEN = gql`
  query Token($id: String!) {
    token(id: $id)
      @rest(endpoint: "tokens", path: "/tokens/{args.id}", type: "Token") {
      access_types
      allowed_paths
      created_at
      created_by
      created_by_account
      description
      expires_at
      id
      name
      resource
      type
    }
  }
`;

export const USER_TOKENS = gql`
  query UserTokens {
    userTokens
      @rest(endpoint: "tokens", path: "/tokens", type: "TokensResponse") {
      tokens @type(name: "Token") {
        id
        type
        name
        resource
        expires_at
      }
    }
  }
`;

/**
 * Mutations
 */
export const CREATE_TOKEN = gql`
  mutation CreateToken($input: TokenCreationInput!) {
    createToken(input: $input)
      @rest(
        endpoint: "tokens"
        method: "POST"
        path: "/tokens"
        type: "TokenCreationResponse"
      ) {
      token
      secret
    }
  }
`;

export const DELETE_TOKEN = gql`
  mutation DeleteToken($id: String!) {
    deleteToken(id: $id)
      @rest(
        endpoint: "tokens"
        method: "DELETE"
        path: "/tokens/{args.id}"
        type: "Token"
      ) {
      NoContent
    }
  }
`;
