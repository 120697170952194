import { useQuery } from '@apollo/client';

import { TOKEN } from 'src/queries/rest/token';
import { Token } from 'src/types/rest/tokens';

interface TokenQuery {
  token: Token;
}

interface TokenQueryVariables {
  id: Token['id'];
}

export const useToken = (id?: string) => {
  const skip = !id;
  const variables = id ? { id } : undefined;

  const { data, error, loading } = useQuery<TokenQuery, TokenQueryVariables>(
    TOKEN,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip,
      variables,
    },
  );

  return {
    data: data?.token,
    error,
    loading,
  };
};
