import {
  Icon,
  StandardProps,
  distance,
  getFontStyle,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { fontMono, sectionMarginBottom } from 'src/assets/styles/ui';
import { defaultBorderRadii, textMaxWidth } from 'src/helpers/constants';

const CodeBlock = styled.div`
  display: flex;
  gap: ${distance.small};
  align-items: center;
  justify-content: space-between;
  max-width: ${textMaxWidth};
  padding: ${distance.medium};
  overflow-x: auto;
  word-wrap: normal;
  background-color: ${themed(({ theme = {} }: StandardProps) => theme.ui4)};
  border-radius: ${defaultBorderRadii};

  ${sectionMarginBottom}
`;

const InlineCode = styled.code`
  ${fontMono}

  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
  ${getFontStyle({ size: 'xSmall' })}
`;

const StyledPre = styled.pre`
  margin: 0;
  white-space: break-spaces;
  word-break: break-all;
`;

const CopyIcon = styled(Icon)`
  align-self: flex-start;
  color: ${themed(({ theme = {} }: StandardProps) => theme.ui5)};
  background: inherit;
  cursor: pointer;
`;

interface Props {
  /**
   * Code block only supports text node as children
   */
  children: string;
  /**
   * Defines whether or not to render a 'copy' icon to allow copying
   * of the content of the code block.
   * @default false
   */
  copyable?: boolean;
  /**
   * Function to call on copied.
   */
  onCopied?(): void;
  /**
   * Style variant.
   * @default block
   */
  variant?: 'block' | 'inline';
}

export const Code: React.FC<Props> = ({
  children,
  copyable = false,
  onCopied,
  variant = 'block',
}) => {
  const copiedTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [textCopied, setTextCopied] = React.useState(false);

  const listener = () => {
    onCopied && onCopied();
  };

  React.useEffect(() => {
    document.addEventListener('copy', listener);

    return () => {
      document.removeEventListener('copy', listener);
      copiedTimer.current && clearTimeout(copiedTimer.current);
    };
  }, []);

  if (variant === 'inline') {
    return <InlineCode>{children}</InlineCode>;
  }

  const copyToClipboard = async () => {
    if (textCopied) {
      return;
    }

    await navigator.clipboard.writeText(children);

    setTextCopied(true);
    onCopied && onCopied();

    const TWO_SECONDS = 2000;
    copiedTimer.current = setTimeout(() => setTextCopied(false), TWO_SECONDS);
  };

  return (
    <CodeBlock>
      <StyledPre>
        <InlineCode>{children}</InlineCode>
      </StyledPre>
      {copyable && (
        <CopyIcon
          data-testid="copy-icon"
          name={textCopied ? 'Done' : 'ContentCopy'}
          onClick={copyToClipboard}
          size={2}
        />
      )}
    </CodeBlock>
  );
};
