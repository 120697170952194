import { useMutation } from '@apollo/client';
import decamelize from 'decamelize';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import {
  CREATE_ROLE_BINDING,
  CREATE_ROLE_BINDING_V1,
} from 'src/queries/rest/roleBindings';
import { RbacVersion } from 'src/types/__generated__/types';
import { RoleBinding } from 'src/types/rest/rbac';
import { RoleBindingResponse } from 'src/types/rest/rbac.v1';

interface CreateRoleBindingMutationV0 {
  createRoleBinding: RoleBinding;
}
interface CreateRoleBindingMutationV1 {
  createRoleBinding: RoleBindingResponse;
}

type CreateRoleBindingMutation =
  | CreateRoleBindingMutationV0
  | CreateRoleBindingMutationV1;

interface CreateRoleBindingMutationVariablesV0 {
  bodyBuilder: any;
  input: {
    scopeId?: string;
    subjectId: string;
  };
  roleName: string;
  styraStackId: string;
}
type CreateRoleBindingMutationVariablesV1 = Omit<
  CreateRoleBindingMutationVariablesV0,
  'input'
> & {
  input: {
    scopeName?: string;
    subjectId: string;
  };
};

export type CreateRoleBindingMutationVariables =
  | CreateRoleBindingMutationVariablesV0
  | CreateRoleBindingMutationVariablesV1;

interface UseCreateRoleBindingProps {
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useCreateRoleBinding = ({
  rbacVersion,
  t,
}: UseCreateRoleBindingProps) => {
  const CREATE_ROLE_BINDING_MUTATION =
    rbacVersion === RbacVersion.V0
      ? CREATE_ROLE_BINDING
      : CREATE_ROLE_BINDING_V1;

  const [mutate, { data, error, loading }] = useMutation<
    CreateRoleBindingMutation,
    CreateRoleBindingMutationVariables
  >(CREATE_ROLE_BINDING_MUTATION, {
    onCompleted: () => {
      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.created'),
          name: t('common:glossary.roleBinding'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'created',
        name: 'roleBinding',
      });
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as CreateRoleBindingMutationV0).createRoleBinding
        : (data as CreateRoleBindingMutationV1).createRoleBinding.result
      : undefined,
    error,
    loading,
    mutate,
  };
};

// TODO: share/extract if we have another mutation needing this
export const createRoleBindingBodyBuilder = (data: any) => {
  const { input } = data.args;

  const body: Record<string, any> = {};

  Object.keys(input).forEach((key) => {
    body[decamelize(key)] = input[key];
  });

  return body;
};
