import { useQuery } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, validateEmail } from 'src/helpers/utils';
import { USER_EMAIL } from 'src/queries/user';
import {
  UserEmailQuery,
  UserEmailQueryVariables,
} from 'src/types/__generated__/types';

interface UseUserByEmailProps {
  email: string;
  onCompleted?: () => void;
  skip: boolean;
  t: TFunction;
}

export const useUserByEmail = ({
  email,
  onCompleted,
  skip,
  t,
}: UseUserByEmailProps) => {
  const shouldSkip = skip || !validateEmail(email);

  const { data, error, loading } = useQuery<
    UserEmailQuery,
    UserEmailQueryVariables
  >(USER_EMAIL, {
    // Set to no-cache, otherwise gql query is triggered every time user enters
    // a character in the input field.
    fetchPolicy: 'no-cache',

    onCompleted: async () => {
      onCompleted && (await onCompleted());
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'updated',
        name: 'stack',
      });
    },

    skip: shouldSkip,
    variables: { email },
  });

  return {
    data: data?.userByEmail,
    error,
    loading,
  };
};
