import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as React from 'react';

import { APP_INSIGHTS_CONNECTION_STRING, IS_PROD } from 'src/helpers/constants';
import history from 'src/router/history';

export const reactPlugin = new ReactPlugin();

function initializeAppInsight() {
  if (!APP_INSIGHTS_CONNECTION_STRING || !IS_PROD) {
    // do not attempt initialization if we don't have an instrumentation key
    // or not in production.
    return;
  }

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: APP_INSIGHTS_CONNECTION_STRING,
      enableUnhandledPromiseRejectionTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: {
          history,
        },
      },
      extensions: [reactPlugin],
    },
  });

  try {
    appInsights.loadAppInsights();
  } catch {
    console.log('Unable to load app insights');
    return;
  }
}

export const AppInsightsProvider: React.FC = ({ children }) => {
  React.useEffect(() => {
    initializeAppInsight();
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};
