import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { ADD_DATA_SOURCE } from 'src/queries/dataSource';
import {
  AddDataSourceMutation,
  AddDataSourceMutationVariables,
} from 'src/types/__generated__/types';

interface UseAddDataSourceProps {
  onCompleted?: () => void;
  stackId?: string;
  t: TFunction<'common'[]>;
}

export const useAddDataSource = ({
  onCompleted,
  stackId,
  t,
}: UseAddDataSourceProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    AddDataSourceMutation,
    AddDataSourceMutationVariables
  >(ADD_DATA_SOURCE, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.added'),
          name: t('common:glossary.dataSource'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'added',
        name: 'dataSource',
      });
    },

    update: (cache) => {
      if (stackId) {
        cache.evict({ id: `Stack:${stackId}` });
        cache.gc();
      }
    },
  });

  return {
    data: data?.createDatasourceConfiguration,
    error,
    loading,
    mutate,
  };
};
