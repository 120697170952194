import { gql } from '@apollo/client';

/**
 * Fragments
 */

export const FRAGMENT_DATA_SOURCE_FIELDS = gql`
  fragment DataSourceFields on ZeissIDAgreementDatasourceConfiguration {
    agreementTemplateIDs
    environment
    finishedAt
    id
    importAcceptedTermsAndConditions
    importAccountCustomFields
    importClientApplications
    startedAt
    status
  }
`;

/**
 * Mutations
 */

export const ADD_DATA_SOURCE = gql`
  mutation AddDataSource(
    $stackId: StackIdentifier!
    $input: DatasourceConfigurationInput!
  ) {
    createDatasourceConfiguration(stackId: $stackId, input: $input) {
      created {
        ...DataSourceFields
      }
    }
  }
  ${FRAGMENT_DATA_SOURCE_FIELDS}
`;

export const REMOVE_DATA_SOURCE = gql`
  mutation RemoveDataSource($id: DatasourceIdentifier!) {
    deleteDatasource(id: $id) {
      id
    }
  }
`;

export const UPDATE_DATA_SOURCE_CONFIGURATION = gql`
  mutation UpdateDataSourceConfiguration(
    $id: DatasourceIdentifier!
    $input: DatasourceConfigurationInput!
  ) {
    updateDatasourceConfiguration(id: $id, input: $input) {
      created {
        ...DataSourceFields
      }
    }
  }
  ${FRAGMENT_DATA_SOURCE_FIELDS}
`;
