import { useLazyQuery } from '@apollo/client';

import { USER_STACKS_AND_SYSTEMS } from 'src/queries/stack';
import {
  UserStacksAndSystemsQuery,
  UserStacksAndSystemsQueryVariables,
} from 'src/types/__generated__/types';

export const useLazyUserStacksAndSystems = () => {
  const [getStacksAndSystems, { called, data, error, loading }] = useLazyQuery<
    UserStacksAndSystemsQuery,
    UserStacksAndSystemsQueryVariables
  >(USER_STACKS_AND_SYSTEMS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return {
    called,
    data: data && {
      userStacks: data.userStacks,
      userSystems: data.userSystems,
    },
    error,
    getStacksAndSystems,
    loading,
  };
};
