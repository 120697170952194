import { useQuery } from '@apollo/client';

import { STACK_ROLES, STACK_ROLES_V1 } from 'src/queries/rest/roles';
import { RbacVersion } from 'src/types/__generated__/types';
import { Role } from 'src/types/rest/rbac';
import { RolesListResponse } from 'src/types/rest/rbac.v1';

interface RolesQueryVariables {
  styraStackId: string;
}

interface RolesQueryV0 {
  stackRoles: Role[];
}

interface RolesQueryV1 {
  stackRolesV1: RolesListResponse;
}

type RolesQuery = RolesQueryV0 | RolesQueryV1;

interface UseStackRolesProps {
  rbacVersion?: RbacVersion | null;
  styraStackId?: string;
}

export const useStackRoles = ({
  rbacVersion,
  styraStackId,
}: UseStackRolesProps) => {
  const ROLES_QUERY =
    rbacVersion === RbacVersion.V0 ? STACK_ROLES : STACK_ROLES_V1;

  const skip = !styraStackId || !rbacVersion;
  const variables = styraStackId ? { styraStackId } : undefined;

  const { data, error, loading } = useQuery<RolesQuery, RolesQueryVariables>(
    ROLES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip,
      variables,
    },
  );

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as RolesQueryV0).stackRoles
        : (data as RolesQueryV1).stackRolesV1.results
      : undefined,
    error,
    loading,
  };
};
