import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { UPDATE_DATA_SOURCE_CONFIGURATION } from 'src/queries/dataSource';
import {
  UpdateDataSourceConfigurationMutation,
  UpdateDataSourceConfigurationMutationVariables,
} from 'src/types/__generated__/types';

interface UseUpdateDataSourceConfigurationProps {
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useUpdateDataSourceConfiguration = ({
  onCompleted,
  t,
}: UseUpdateDataSourceConfigurationProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    UpdateDataSourceConfigurationMutation,
    UpdateDataSourceConfigurationMutationVariables
  >(UPDATE_DATA_SOURCE_CONFIGURATION, {
    onCompleted: async () => {
      onCompleted && (await onCompleted());

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.updated').toLowerCase(),
          name: t('common:glossary.stack'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'updated',
        name: 'stack',
      });
    },
  });

  return {
    data: data?.updateDatasourceConfiguration,
    error,
    loading,
    mutate,
  };
};
