import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_ROLE, DELETE_ROLE_V1 } from 'src/queries/rest/roles';
import { RbacVersion } from 'src/types/__generated__/types';

export interface DeleteRoleMutation {
  deleteRole: {
    NoContent: null;
  };
}

export interface DeleteRoleMutationVariables {
  roleId: string;
  styraStackId: string;
}

interface UseDeleteRoleProps extends DeleteRoleMutationVariables {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useDeleteRole = ({
  onCompleted,
  rbacVersion,
  roleId,
  styraStackId,
  t,
}: UseDeleteRoleProps) => {
  const DELETE_MUTATION =
    rbacVersion === RbacVersion.V0 ? DELETE_ROLE : DELETE_ROLE_V1;

  const [mutate, { data, error, loading, reset }] = useMutation<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >(DELETE_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.role'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'role',
      });
    },

    update: (cache) => {
      cache.evict({
        id:
          rbacVersion === RbacVersion.V0
            ? `Role:${roleId}`
            : `RoleV1:${roleId}`,
      });
      cache.gc();
    },

    variables: {
      roleId,
      styraStackId,
    },
  });

  return {
    data: data?.deleteRole,
    error,
    loading,
    mutate,
    reset,
  };
};
