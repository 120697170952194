import { gql } from '@apollo/client';

/**
 * Fragments
 */

export const FRAGMENT_RESOURCE_FIELDS = gql`
  fragment ResourceFields on Resource {
    id
    metadata
    name
    pattern
  }
`;

/**
 * Queries
 */

export const STACK_RESOURCES = gql`
  query StackResources($styraStackId: String!) {
    stackResources(stackId: $styraStackId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/resources"
        type: "ResourceListResponse"
      ) {
      results @type(name: "Resource") {
        ...ResourceFields
      }
    }
  }
  ${FRAGMENT_RESOURCE_FIELDS}
`;

export const RESOURCE = gql`
  query Resource($styraStackId: String!, $resourceName: String!) {
    resource(stackId: $styraStackId, resourceName: $resourceName)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/resources/{args.resourceName}"
        type: "ResourceResponse"
      ) {
      result @type(name: "Resource") {
        ...ResourceFields
      }
    }
  }
  ${FRAGMENT_RESOURCE_FIELDS}
`;

/**
 * Mutations
 */

export const CREATE_RESOURCE = gql`
  mutation CreateResource(
    $styraStackId: String!
    $input: ResourceCreationInput!
  ) {
    createResource(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbacV1"
        method: "POST"
        path: "/stacks/{args.stackId}/resources"
        type: "ResourceListResponse"
      ) {
      results @type(name: "Resource") {
        ...ResourceFields
      }
    }
  }
  ${FRAGMENT_RESOURCE_FIELDS}
`;

export const DELETE_RESOURCE = gql`
  mutation DeleteResource($styraStackId: String!, $resourceName: String!) {
    deleteResource(stackId: $styraStackId, resourceName: $resourceName)
      @rest(
        endpoint: "rbacV1"
        method: "DELETE"
        path: "/stacks/{args.stackId}/resources/{args.resourceName}"
      ) {
      NoContent
    }
  }
`;

export const UPDATE_RESOURCE = gql`
  mutation UpdateResource(
    $styraStackId: String!
    $resourceName: String!
    $input: ResourceUpdateInput!
  ) {
    updateResource(
      stackId: $styraStackId
      resourceName: $resourceName
      input: $input
    )
      @rest(
        endpoint: "rbacV1"
        method: "PATCH"
        path: "/stacks/{args.stackId}/resources/{args.resourceName}"
        type: "ResourceResponse"
      ) {
      result @type(name: "Resource") {
        ...ResourceFields
      }
    }
  }
  ${FRAGMENT_RESOURCE_FIELDS}
`;
