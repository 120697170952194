import { useQuery } from '@apollo/client';

import { STACK_STYRA_ID } from 'src/queries/stack';
import {
  StackStyraIdQuery,
  StackStyraIdQueryVariables,
} from 'src/types/__generated__/types';

export const useStackStyraId = (id?: string) => {
  const stackId = Number(id);

  const skip = !id || Number.isNaN(stackId);
  const variables = id ? { id: stackId } : undefined;

  const { data, error, loading } = useQuery<
    StackStyraIdQuery,
    StackStyraIdQueryVariables
  >(STACK_STYRA_ID, {
    skip,
    variables,
  });

  return {
    data: data?.stackById?.styraStackId,
    error,
    loading,
  };
};
