import { ThemeProvider, themes } from '@zeiss/pharos';
import { FlagsProvider } from 'flagged';
import * as React from 'react';

import { GlobalStyle } from 'src/assets/styles/global';
import {
  ErrorBoundary,
  FatalErrorFallback,
} from 'src/components/shared/ErrorHandlers';
import { StyledSpinner, XYCenter } from 'src/components/styled/shared';
import { brandingTitle } from 'src/helpers/constants';
import { useUser } from 'src/hooks/api';

// Code-split, and lazy-load the used view
const AuthenticatedApp = React.lazy(() =>
  import('src/views/Authenticated/AuthenticatedView').then(
    ({ AuthenticatedView }) => ({ default: AuthenticatedView }),
  ),
);

const UnauthenticatedApp = React.lazy(() =>
  import('src/views/Unauthenticated/UnauthenticatedView').then(
    ({ UnauthenticatedView }) => ({ default: UnauthenticatedView }),
  ),
);

export const App: React.FC = () => {
  const { data: user, error: errorUser, loading: loadingUser } = useUser();

  const renderView = () => {
    if (loadingUser) return null;
    if (user && !errorUser) {
      return (
        <FlagsProvider features={user.features}>
          <AuthenticatedApp />
        </FlagsProvider>
      );
    }
    return <UnauthenticatedApp />;
  };

  return (
    <React.Suspense
      fallback={
        <XYCenter>
          {/* Don't use i18n on the fallback */}
          <StyledSpinner>{brandingTitle} is starting…</StyledSpinner>
        </XYCenter>
      }
    >
      <ThemeProvider theme={themes.light}>
        <GlobalStyle />
        <ErrorBoundary FallbackComponent={FatalErrorFallback}>
          {renderView()}
        </ErrorBoundary>
      </ThemeProvider>
    </React.Suspense>
  );
};
