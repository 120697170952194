/* eslint-disable no-console */

import {
  ApolloClient,
  HttpLink,
  NormalizedCacheObject,
  ServerError,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { RestLink } from 'apollo-link-rest';

import { cache } from 'src/apollo/cache';
import { reactPlugin } from 'src/components/shared/AppInsightsProvider';
import {
  API_PATH,
  OAUTH_API_PATH,
  ONBOARDING_API_PATH,
  RBAC_API_PATH,
  RBAC_V1_API_PATH,
  REST_API_PATH,
  TOKEN_API_PATH,
  basename,
} from 'src/helpers/constants';
import { typeDefs } from 'src/localStateFields';
import history from 'src/router/history';
import { ErrorCodes } from 'src/types/__generated__/types';
import { ErrorResponse } from 'src/types/rest/rbac';

const httpLink = new HttpLink({
  credentials: 'include',
  uri: `${API_PATH}`,
});

const errorLink = onError((errorHandler) => {
  const { graphQLErrors, networkError } = errorHandler;

  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { locations, message, path } = error;
      switch (error.message) {
        // If unauthorized push to landing page
        case 'system: UNAUTHORIZED_ACCESS': {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
          history.push(basename);
        }
      }

      const knownErrors = Object.values(ErrorCodes);
      if (!knownErrors.includes(error.extensions?.code as ErrorCodes)) {
        /**
         * Log error on application insights.
         *
         * The second argument is an object with custom properties to provide enough context
         * on the log about the error event.
         */
        reactPlugin.trackException(
          {
            exception: error,
            severityLevel: SeverityLevel.Error,
          },
          {
            currentPage: window.location.href,
            customErrorType: 'Unexpected GraphQL Error',
            graphQLVariables: errorHandler.operation.variables,
            originalError: error,
          },
        );
      }
    }
  }

  if (networkError) {
    const rootPathRegex = new RegExp(`^${basename}/?$`);

    if (
      (networkError as ServerError).statusCode === 401 &&
      !rootPathRegex.test(window.location.pathname) /* not on root path */
    ) {
      history.push(`${basename}/session-expired`);
    }
  }
});

/**
 * apollo-link-rest throws an error that becomes available under the
 * "Network error" category in apollo-link-error.
 *
 * @see {@link https://github.com/apollographql/apollo-link-rest/blob/699a600f67254bca72b0cdb36327958a7e7f62e4/src/restLink.ts#L1086}
 *
 * To get more control over the error handling and to avoid mixing it with the
 * GraphQL error handling, we control the error messages with a custom fetch
 * function and apply the `REST_ERR_` prefix.
 *
 * We don't `json` parse the response if the content type is not
 * `application/json`. This is to avoid 'Unexpected end of JSON input' errors.
 * Instead, we throw the statusText and the default error message will be
 * displayed on the UI
 */
const customRestFetch: RestLink.CustomFetch = async (request, init) => {
  const response = await fetch(request, init);

  if (response.status !== 401 && response.ok === false) {
    const contentType = response.headers.get('content-type');

    if (!contentType || !contentType.includes('application/json')) {
      throw response.statusText;
    }

    const result: ErrorResponse = await response.json();
    const errorCode = `REST_ERR_${result.error.code}`;
    throw new Error(errorCode);
  }

  return response;
};

const restLink = new RestLink({
  // This is a workaround to be able to send requests without a body
  bodySerializers: {
    text: (data, headers) => {
      return { body: data, headers };
    },
  },
  credentials: 'include',
  customFetch: customRestFetch,
  endpoints: {
    oauth: {
      uri: `${OAUTH_API_PATH}`,
    },
    onboarding: {
      uri: `${ONBOARDING_API_PATH}`,
    },
    rbac: {
      uri: `${RBAC_API_PATH}`,
    },
    rbacV1: {
      uri: `${RBAC_V1_API_PATH}`,
    },
    tokens: {
      uri: `${TOKEN_API_PATH}`,
    },
  },
  uri: `${REST_API_PATH}`,
});

export const client = new ApolloClient<NormalizedCacheObject>({
  cache,
  link: from([errorLink, restLink, httpLink]),
  typeDefs,
});
