import {
  StandardProps,
  css,
  displayFrom,
  distance,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { NamedSizes } from 'src/types';

interface Props {
  /**
   * Add visual divider between children.
   */
  divider?: boolean;
  /**
   * Set custom distance to the next element.
   * Useful when a visual heavy/light element is following.
   */
  dividerDistance?: NamedSizes;
}

const Container = styled.div<Props>`
  display: inline-flex;
  align-items: center;

  > * {
    &:not(:last-child) {
      margin-right: ${({ divider }) => (divider ? 0 : '0.75rem')};

      ${({ divider, dividerDistance }) =>
        divider &&
        css`
          &::after {
            margin: 0
              ${dividerDistance ? distance[dividerDistance] : distance.small};
            color: ${themed(({ theme = {} }: StandardProps) => theme.ui4)};
            content: '|';

            ${displayFrom('large')`
              margin: 0 ${
                dividerDistance ? distance[dividerDistance] : distance.medium
              };
            `}
          }
        `}

      ${displayFrom('large')<Props>`
        margin-right: ${({ divider }) => (divider ? 0 : distance.medium)};
      `}
    }
  }
`;

export const SectionBarMeta: React.FC<Props> = ({
  children,
  divider,
  dividerDistance,
}) => (
  <Container divider={divider} dividerDistance={dividerDistance}>
    {children}
  </Container>
);
