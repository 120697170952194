import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { UPDATE_ACTION, UPDATE_ACTION_V1 } from 'src/queries/rest/actions';
import { RbacVersion } from 'src/types/__generated__/types';
import { ActionResponse } from 'src/types/rest/rbac.v1';

interface UpdateActionMutationV0 {
  updateAction: {
    NoContent: null;
  };
}

interface UpdateActionMutationV1 {
  updateActionV1: ActionResponse;
}

type UpdateActionMutation = UpdateActionMutationV0 | UpdateActionMutationV1;

export interface UpdateActionMutationVariables {
  actionName: string;
  input: {
    name: string;
  };
  styraStackId: string;
}

interface UseUpdateActionProps {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useUpdateAction = ({
  onCompleted,
  rbacVersion,
  t,
}: UseUpdateActionProps) => {
  const UPDATE_MUTATION =
    rbacVersion === RbacVersion.V0 ? UPDATE_ACTION : UPDATE_ACTION_V1;

  const [mutate, { data, error, loading }] = useMutation<
    UpdateActionMutation,
    UpdateActionMutationVariables
  >(UPDATE_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.updated'),
          name: t('common:glossary.action'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'updated',
        name: 'action',
      });
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as UpdateActionMutationV0).updateAction
        : (data as UpdateActionMutationV1).updateActionV1.result
      : undefined,
    error,
    loading,
    mutate,
  };
};
