import {
  StandardProps,
  css,
  displayFrom,
  distance,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { getDistanceWithFallback } from 'src/helpers/utils';
import { DistanceSizes } from 'src/types';

const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      padding-bottom: ${distance.small};
      border-bottom: 1px solid
        ${themed(({ theme = {} }: StandardProps) => theme.ui3)};
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ withColumns }) =>
    withColumns &&
    css`
      justify-content: space-between;

      ${withColumns === 'responsive' && 'display: block;'};

      ${displayFrom('large')`
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
      `}
    `}
`;

interface Props {
  /**
   * Add a border below.
   */
  borderBottom?: boolean;
  /**
   * Set custom distance to the next element.
   * Useful when a visual heavy/light element is following.
   * @default medium
   */
  distance?: DistanceSizes;
  /**
   * Function to call when clicked.
   * Useful to uncollapse/collapse children.
   */
  onClick?(): void;
  /**
   * If `responsive` use columns for children from screen large, or on all
   * screens if `always`.
   * Useful to display the headline on the left and other elements on the right.
   */
  withColumns?: 'always' | 'responsive';
}

/**
 * Section Header
 *
 * Component that shoud house the `SectionHeaderTitle` and optionally some
 * additional content.
 */
export const SectionHeader: React.FC<Props> = ({
  borderBottom,
  children,
  distance = 'medium',
  onClick,
  withColumns,
  ...rest
}) => (
  <Container
    borderBottom={borderBottom}
    distance={distance}
    onClick={onClick}
    withColumns={withColumns}
    {...rest}
  >
    {children}
  </Container>
);
