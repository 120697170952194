import { useQuery } from '@apollo/client';

import { STACK_RBAC_ENABLED } from 'src/queries/stack';
import {
  StackRbacEnabledQuery,
  StackRbacEnabledQueryVariables,
} from 'src/types/__generated__/types';

export const useStackRbacEnabled = (id?: string) => {
  const stackId = Number(id);

  const skip = !id || Number.isNaN(stackId);
  const variables = id ? { id: stackId } : undefined;

  const { data, error, loading } = useQuery<
    StackRbacEnabledQuery,
    StackRbacEnabledQueryVariables
  >(STACK_RBAC_ENABLED, {
    skip,
    variables,
  });

  return {
    data: data?.stackById
      ? {
          enabled: data?.stackById?.rbacEnabled,
          version: data?.stackById?.rbacVersion,
        }
      : undefined,
    error,
    loading,
  };
};
