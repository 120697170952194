import { useLazyQuery } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { defaultPollInterval } from 'src/helpers/constants';
import { SYSTEM } from 'src/queries/system';
import {
  SystemQuery,
  SystemQueryVariables,
} from 'src/types/__generated__/types';

interface UseSystemProps {
  /**
   * Poll until the user has access.
   *
   * Helpful, when RBAC changes take longer, but we are certain the user should
   * have access to the system.
   *
   * @default false
   */
  pollUntilAccess?: boolean;
}

export const useLazySystem = ({ pollUntilAccess = false }: UseSystemProps) => {
  const [
    getSystem,
    { called, data, error, loading, startPolling, stopPolling },
  ] = useLazyQuery<SystemQuery, SystemQueryVariables>(SYSTEM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const system = data?.systemById;

  const hasErrorForbidden = error?.graphQLErrors.some(
    (e: GraphQLError) => e.extensions.code === 'FORBIDDEN',
  );

  if (called && pollUntilAccess) {
    startPolling(defaultPollInterval);

    /**
     * Stop polling when:
     * - `system` data exists.
     * - There is an error that is not of code `FORBIDDEN`.
     */
    if (system || (error && !hasErrorForbidden)) {
      stopPolling();
    }
  }

  return {
    data: system,
    error,
    getSystem,
    loading,
  };
};
