import { useQuery } from '@apollo/client';

import { SYSTEM_ONBOARDING } from 'src/queries/rest/onboarding';
import { ForceSkip } from 'src/types';
import {
  SystemID,
  SystemOnboarding,
  SystemOnboardingStep,
} from 'src/types/rest/onboarding';

const stepOrder: readonly SystemOnboardingStep[] = Object.freeze([
  'account',
  'datasource',
  'policy',
  'opa',
  'request',
]);

interface SystemOnboardingQuery {
  systemOnboarding: SystemOnboarding;
}

interface SystemOnboardingQueryVariables {
  id: SystemID;
}

interface UseSystemOnboardingProps extends ForceSkip {
  id?: string;
}

export const useSystemOnboarding = ({
  forceSkip,
  id,
}: UseSystemOnboardingProps) => {
  const skip = forceSkip || !id;
  const variables = id ? { id } : undefined;

  const { data, error, loading } = useQuery<
    SystemOnboardingQuery,
    SystemOnboardingQueryVariables
  >(SYSTEM_ONBOARDING, {
    skip,
    variables,
  });

  const { steps } = data?.systemOnboarding ?? {};

  const remainingSteps = steps
    ? Object.entries(steps)
        .map(([key, rest]) => ({
          name: key as SystemOnboardingStep,
          ...rest,
        }))
        .filter(({ done }) => !done)
        .sort(
          (a, b) =>
            (stepOrder as string[]).indexOf(a.name) -
            (stepOrder as string[]).indexOf(b.name),
        )
    : [];

  return {
    data: {
      ...data?.systemOnboarding,
      remainingSteps,
    },
    error,
    loading,
  };
};
