import { useLazyQuery } from '@apollo/client';

import { STACK_SCOPES, STACK_SCOPES_V1 } from 'src/queries/rest/scopes';
import { RbacVersion } from 'src/types/__generated__/types';
import {
  ScopesQuery,
  ScopesQueryV0,
  ScopesQueryV1,
  ScopesQueryVariables,
} from './types';

interface UseLazyStackScopesProps {
  rbacVersion?: RbacVersion | null;
}

export const useLazyStackScopes = ({
  rbacVersion,
}: UseLazyStackScopesProps) => {
  const STACK_SCOPES_QUERY =
    rbacVersion === RbacVersion.V0 ? STACK_SCOPES : STACK_SCOPES_V1;

  const [getStackScopes, { called, data, error, loading }] = useLazyQuery<
    ScopesQuery,
    ScopesQueryVariables
  >(STACK_SCOPES_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return {
    called,
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as ScopesQueryV0).stackScopes
        : (data as ScopesQueryV1).stackScopesV1.results
      : undefined,
    error,
    getStackScopes: rbacVersion ? getStackScopes : undefined,
    loading,
  };
};
