import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_SCOPE, DELETE_SCOPE_V1 } from 'src/queries/rest/scopes';
import { RbacVersion } from 'src/types/__generated__/types';

export interface DeleteScopeMutation {
  deleteScope: {
    NoContent: null;
  };
}

export interface DeleteScopeMutationVariables {
  scopeId: string;
  styraStackId: string;
}

interface UseDeleteScopeProps extends DeleteScopeMutationVariables {
  onCompleted?: () => void;
  rbacVersion: RbacVersion;
  t: TFunction<'common'[]>;
}

export const useDeleteScope = ({
  onCompleted,
  rbacVersion,
  scopeId,
  styraStackId,
  t,
}: UseDeleteScopeProps) => {
  const DELETE_MUTATION =
    rbacVersion === RbacVersion.V0 ? DELETE_SCOPE : DELETE_SCOPE_V1;

  const [mutate, { data, error, loading, reset }] = useMutation<
    DeleteScopeMutation,
    DeleteScopeMutationVariables
  >(DELETE_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.scope'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'scope',
      });
    },

    update: (cache) => {
      cache.evict({
        id:
          rbacVersion === RbacVersion.V0
            ? `Scope:${scopeId}`
            : `ScopeV1:${scopeId}`,
      });
      cache.gc();
    },

    variables: {
      scopeId,
      styraStackId,
    },
  });

  return {
    data: data?.deleteScope,
    error,
    loading,
    mutate,
    reset,
  };
};
