import {
  StandardProps,
  displayFrom,
  distance,
  getFontStyle,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { getDistanceWithFallback } from 'src/helpers/utils';
import { NamedSizes } from 'src/types';

interface Props {
  /**
   * Set custom distance to the next element.
   * Useful when a visual heavy/light element is following.
   */
  distance?: NamedSizes;
}

const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};
  color: ${themed(({ theme = {} }: StandardProps) => theme.text2)};

  ${getFontStyle({ lineHeight: 'small', size: 'small', weight: 'regular' })}

  > * {
    &:not(:last-child) {
      margin-right: 0.75rem;

      ${displayFrom('large')`
        margin-right: ${distance.medium};
      `}
    }
  }
`;

export const SectionBar: React.FC<Props> = ({
  children,
  distance,
  ...rest
}) => (
  <Container distance={distance} {...rest}>
    {children}
  </Container>
);
