import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_TOKEN } from 'src/queries/rest/token';
import { Token } from 'src/types/rest/tokens';

export interface DeleteTokenMutation {
  deleteToken: {
    NoContent: null;
  };
}

export interface DeleteTokenMutationVariables {
  id: string;
}

interface UseDeleteTokenProps {
  id: Token['id'];
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useDeleteToken = ({ id, onCompleted, t }: UseDeleteTokenProps) => {
  const [mutate, { data, error, loading, reset }] = useMutation<
    DeleteTokenMutation,
    DeleteTokenMutationVariables
  >(DELETE_TOKEN, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.token'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'token',
      });
    },

    update: (cache) => {
      cache.evict({ id: `Token:${id}` });
      cache.gc();
    },

    variables: {
      id,
    },
  });

  return {
    data: data?.deleteToken,
    error,
    loading,
    mutate,
    reset,
  };
};
