import { StandardProps, distance, getFontStyle, themed } from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { PlainListData } from './';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${distance.small};

  ${getFontStyle({ size: 'medium' })}

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-right: ${distance.medium};
  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
`;

const Meta = styled.div`
  color: ${themed(({ theme = {} }: StandardProps) => theme.text2)};
  text-align: right;
`;

interface Props {
  /**
   * Single list item data.
   */
  data: PlainListData;
}

export const PlainListItem: React.FC<Props> = ({ data: { content, meta } }) => {
  return (
    <Container>
      <Content>{content}</Content>
      <Meta>{meta}</Meta>
    </Container>
  );
};
