import { useQuery } from '@apollo/client';

import { SCOPE, SCOPE_V1 } from 'src/queries/rest/scopes';
import { RbacVersion } from 'src/types/__generated__/types';
import { Scope } from 'src/types/rest/rbac';
import { ScopeResponse } from 'src/types/rest/rbac.v1';

interface ScopeQueryV0 {
  scope: Scope;
}

interface ScopeQueryV1 {
  scopeV1: ScopeResponse;
}

type ScopeQuery = ScopeQueryV0 | ScopeQueryV1;

export interface ScopeQueryVariables {
  scopeId: string;
  styraStackId: string;
}

interface UseScopeProps {
  rbacVersion?: RbacVersion | null;
  scopeId?: string;
  styraStackId?: string;
}

export const useScope = ({
  rbacVersion,
  scopeId,
  styraStackId,
}: UseScopeProps) => {
  const SCOPE_QUERY = rbacVersion === RbacVersion.V0 ? SCOPE : SCOPE_V1;

  const skip = !styraStackId || !scopeId || !rbacVersion;
  const variables =
    styraStackId && scopeId ? { scopeId, styraStackId } : undefined;

  const { data, error, loading } = useQuery<ScopeQuery, ScopeQueryVariables>(
    SCOPE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip,
      variables,
    },
  );

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as ScopeQueryV0).scope
        : (data as ScopeQueryV1).scopeV1.result
      : undefined,
    error,
    loading,
  };
};
