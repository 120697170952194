import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import {
  DELETE_ROLE_BINDING,
  DELETE_ROLE_BINDING_V1,
} from 'src/queries/rest/roleBindings';
import { RbacVersion } from 'src/types/__generated__/types';

export interface DeleteRoleBindingMutation {
  deleteRoleBinding: {
    NoContent: null;
  };
}

export interface DeleteRoleBindingMutationVariables {
  roleBindingId: string;
  styraStackId: string;
}

interface UseDeleteRoleBindingProps extends DeleteRoleBindingMutationVariables {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useDeleteRoleBinding = ({
  onCompleted,
  rbacVersion,
  roleBindingId,
  styraStackId,
  t,
}: UseDeleteRoleBindingProps) => {
  const DELETE_ROLE_BINDING_MUTATION =
    rbacVersion === RbacVersion.V0
      ? DELETE_ROLE_BINDING
      : DELETE_ROLE_BINDING_V1;

  const [mutate, { data, error, loading, reset }] = useMutation<
    DeleteRoleBindingMutation,
    DeleteRoleBindingMutationVariables
  >(DELETE_ROLE_BINDING_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.roleBinding'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'roleBinding',
      });
    },

    update: (cache) => {
      cache.evict({
        id:
          rbacVersion === RbacVersion.V0
            ? `ExtendedRoleBinding:${roleBindingId}`
            : `ExtendedRoleBindingV1:${roleBindingId}`,
      });
      cache.gc();
    },

    variables: {
      roleBindingId,
      styraStackId,
    },
  });

  return {
    data: data?.deleteRoleBinding,
    error,
    loading,
    mutate,
    reset,
  };
};
