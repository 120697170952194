import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { TFunction } from 'i18next';
import * as React from 'react';

import {
  defaultMutationRetryInterval,
  defaultMutationRetryMaxAttempts,
  defaultPollInterval,
} from 'src/helpers/constants';
import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { CREATE_STACK, STACK } from 'src/queries/stack';
import {
  CreateStackMutation,
  CreateStackMutationVariables,
  StackQuery,
  StackQueryVariables,
} from 'src/types/__generated__/types';

interface UseCreateStackProps {
  retryMutation?: boolean;
  t: TFunction<'common'[]>;
  variables: CreateStackMutationVariables;
}

interface Result {
  data?: CreateStackMutation | null;
  error?: ApolloError;
}

export const useCreateStack = ({
  retryMutation,
  t,
  variables,
}: UseCreateStackProps) => {
  const [result, setResult] = React.useState<Result>({});
  const [retryCount, setRetryCount] = React.useState(0);
  const [shouldRetry, setShouldRetry] = React.useState(false);

  const [mutate, { data, error, loading }] = useMutation<
    CreateStackMutation,
    CreateStackMutationVariables
  >(CREATE_STACK, {
    // Cache is updated through the polled data
    fetchPolicy: 'no-cache',

    onCompleted: () => setResult({ data }),

    onError: ({ graphQLErrors }) => {
      const errorIsForbidden = graphQLErrors.some(
        (e: GraphQLError) => e.extensions.code === 'FORBIDDEN',
      );
      // Only set retry on the expected error codes
      if (retryMutation && errorIsForbidden) {
        setShouldRetry(true);
      } else {
        setResult({ error });
        showErrorNotification(graphQLErrors, t, {
          action: 'created',
          name: 'stack',
        });
      }
    },
    variables,
  });

  /**
   * Retry mutation, if desired.
   *
   * Apollo Retry Link only supports network errors, so we couldn’t use it.
   * @see {@link https://www.apollographql.com/docs/react/api/link/apollo-link-retry/}
   */
  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (
      !result.data &&
      shouldRetry &&
      retryCount < defaultMutationRetryMaxAttempts
    ) {
      interval = setTimeout(async () => {
        const { data: retriedData } = await mutate({ variables });

        if (retriedData) {
          setShouldRetry(false);
          setResult({ data: retriedData });
        } else {
          if (retryCount === defaultMutationRetryMaxAttempts - 1) {
            setShouldRetry(false);
            setResult({ error });
          }
          setRetryCount(retryCount + 1);
        }
      }, defaultMutationRetryInterval);
    }

    return () => clearTimeout(interval);
  }, [shouldRetry, retryCount, result.data]);

  const stackId = result.data?.createStack.created?.stack.id;
  const stackQueryVariables = stackId ? { id: Number(stackId) } : undefined;

  const {
    data: dataVerifyStack,
    error: errorVerifyStack,
    loading: loadingVerifyStack,
    stopPolling,
  } = useQuery<StackQuery, StackQueryVariables>(STACK, {
    onCompleted: () => {
      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.created'),
          name: t('common:glossary.stack'),
        })}`,
        {
          type: 'success',
        },
      );
    },
    pollInterval: defaultPollInterval,
    skip: !stackId,
    variables: stackQueryVariables,
  });

  const errorVerifyStackIsForbidden = errorVerifyStack?.graphQLErrors.some(
    (e: GraphQLError) => e.extensions.code === 'FORBIDDEN',
  );

  /**
   * Stop polling when:
   * - `dataVerifyStack` exists.
   * - There is an `error` on the mutation.
   * - There is an `errorVerifyStack` that is not of code `FORBIDDEN`.
   */
  if (
    dataVerifyStack ||
    result.error ||
    (errorVerifyStack && !errorVerifyStackIsForbidden)
  ) {
    stopPolling();
  }

  // Extend the returned loading
  const getLoadingAccessCheckStatus = () => {
    if (!shouldRetry) {
      if (errorVerifyStack && !errorVerifyStackIsForbidden) return false;
      if (dataVerifyStack) return false;
    }
    if (result.error) return false;
    return true;
  };
  const loadingAccessCheck = getLoadingAccessCheckStatus();

  /**
   * Only respond with the mutation result, if the stack can be queried without
   * errors.
   */
  const finalData =
    errorVerifyStack || loadingVerifyStack
      ? undefined
      : result.data?.createStack;

  return {
    data: finalData,
    error: result.error,
    loading: loading || loadingAccessCheck,
    mutate,
  };
};
