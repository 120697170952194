import * as React from 'react';
import styled, { css } from 'styled-components/macro';

import { xScrollContainer as xScrollContainerStyle } from 'src/assets/styles/ui';
import { contentMaxWidth, textMaxWidth } from 'src/helpers/constants';
import { getDistanceWithFallback } from 'src/helpers/utils';
import { DistanceSizes } from 'src/types';

const Container = styled.div<Props>`
  margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};

  ${({ width }) =>
    width &&
    css`
      max-width: ${width === 'text' ? textMaxWidth : contentMaxWidth};
    `}

  ${({ xScrollContainer }) =>
    xScrollContainer &&
    css`
      ${xScrollContainerStyle}
    `}
`;

interface Props {
  /**
   * Set custom distance to the next element.
   * Useful when a visual heavy/light element is following.
   * @defaultValue large
   */
  distance?: DistanceSizes;
  /**
   * Max-width.
   */
  width?: 'content' | 'text';
  /**
   * Make content scrollable on the x-axis if it overflows.
   */
  xScrollContainer?: boolean;
}

/**
 * SectionContentElement
 *
 * Same spacing as `SectionText` but uses a div instead of a p as the container,
 * so that we can have div's as children.
 */
export const SectionContentElement: React.FC<Props> = ({
  children,
  distance = 'large',
  width,
  xScrollContainer,
  ...rest
}) => (
  <Container
    distance={distance}
    width={width}
    xScrollContainer={xScrollContainer}
    {...rest}
  >
    {children}
  </Container>
);
