import { useApolloClient, useQuery } from '@apollo/client';

import { IsMobileNavOpen as IsMobileNavOpenType } from 'src/queries/__generated__/IsMobileNavOpen';
import { IS_MOBILE_NAV_OPEN } from 'src/queries/ui';

export const useMobileNav = () => {
  const client = useApolloClient();
  const { data, error, loading } =
    useQuery<IsMobileNavOpenType>(IS_MOBILE_NAV_OPEN);

  const updateStatus = (status: boolean) => {
    client.writeQuery({
      data: {
        isMobileNavOpen: status,
      },
      query: IS_MOBILE_NAV_OPEN,
    });
  };

  return {
    data: {
      isMobileNavOpen: data?.isMobileNavOpen,
      setIsMobileNavOpen: updateStatus,
    },
    error,
    loading,
  };
};
