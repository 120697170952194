import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_ACTION, DELETE_ACTION_V1 } from 'src/queries/rest/actions';
import { RbacVersion } from 'src/types/__generated__/types';
import { Action as OriginalAction } from 'src/types/rest/rbac';

type Action = Omit<OriginalAction, 'styra_stack_id'>;

export interface DeleteActionMutation {
  deleteAction: {
    NoContent: null;
  };
}

export interface DeleteActionMutationVariables {
  actionName: string;
  styraStackId: string;
}

interface UseDeleteActionProps extends Action {
  onCompleted?: () => void;
  rbacVersion: RbacVersion;
  styraStackId: string;
  t: TFunction<'common'[]>;
}

export const useDeleteAction = ({
  id,
  name,
  onCompleted,
  rbacVersion,
  styraStackId,
  t,
}: UseDeleteActionProps) => {
  const DELETE_MUTATION =
    rbacVersion === RbacVersion.V0 ? DELETE_ACTION : DELETE_ACTION_V1;

  const [mutate, { data, error, loading, reset }] = useMutation<
    DeleteActionMutation,
    DeleteActionMutationVariables
  >(DELETE_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.action'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'action',
      });
    },

    update: (cache) => {
      cache.evict({
        id: rbacVersion === RbacVersion.V0 ? `Action:${id}` : `ActionV1:${id}`,
      });
      cache.gc();
    },

    variables: {
      actionName: encodeURIComponent(name),
      styraStackId,
    },
  });

  return {
    data: data?.deleteAction,
    error,
    loading,
    mutate,
    reset,
  };
};
