import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import {
  CREATE_ROLE,
  CREATE_ROLE_V1,
  CREATE_SCOPED_ROLE,
  CREATE_SCOPED_ROLE_V1,
} from 'src/queries/rest/roles';
import { RbacVersion } from 'src/types/__generated__/types';
import { Role } from 'src/types/rest/rbac';
import { RoleCreationInput, RoleResponse } from 'src/types/rest/rbac.v1';

interface CreateRoleMutationV0 {
  createRole: Role;
}

interface CreateRoleMutationV1 {
  createRoleV1: RoleResponse;
}

type CreateRoleMutation = CreateRoleMutationV0 | CreateRoleMutationV1;

export interface CreateRoleMutationVariablesV0 {
  input: {
    actions: string[];
    name: string;
    scope?: string;
  };
  scopeId?: string;
  styraStackId: string;
}

export interface CreateRoleMutationVariablesV1 {
  input: RoleCreationInput;
  scopeName?: string;
  styraStackId: string;
}

type CreateRoleMutationVariables =
  | CreateRoleMutationVariablesV0
  | CreateRoleMutationVariablesV1;

interface UseCreateRoleProps {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  scoped?: boolean;
  t: TFunction<'common'[]>;
}

export const useCreateRole = ({
  onCompleted,
  rbacVersion,
  scoped,
  t,
}: UseCreateRoleProps) => {
  let CREATE_ROLE_MUTATION = scoped ? CREATE_SCOPED_ROLE : CREATE_ROLE;

  if (rbacVersion === RbacVersion.V1) {
    CREATE_ROLE_MUTATION = scoped ? CREATE_SCOPED_ROLE_V1 : CREATE_ROLE_V1;
  }

  const [mutate, { data, error, loading }] = useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(CREATE_ROLE_MUTATION, {
    onCompleted: async () => {
      onCompleted && (await onCompleted());

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.created'),
          name: t('common:glossary.role'),
        })}`,
        {
          type: 'success',
        },
      );
    },
    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'created',
        name: 'role',
      });
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as CreateRoleMutationV0).createRole
        : (data as CreateRoleMutationV1).createRoleV1.result
      : undefined,
    error,
    loading,
    mutate,
  };
};
