import { useLazyQuery } from '@apollo/client';

import { VALIDATE_REGEX } from 'src/queries/validateRegex';
import {
  ValidateRegexQuery,
  ValidateRegexQueryVariables,
} from 'src/types/__generated__/types';

export const useLazyValidateRegex = () => {
  const [validateRegex, { data, error, loading }] = useLazyQuery<
    ValidateRegexQuery,
    ValidateRegexQueryVariables
  >(VALIDATE_REGEX);

  return {
    data: data?.validateRegex,
    error,
    loading,
    validateRegex,
  };
};
