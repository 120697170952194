import { useLazyQuery } from '@apollo/client';

import { USER_QUERY } from 'src/queries/rest/oauth';
import { UserQuery } from 'src/types/rest/oauth';

interface UseLayzUserProps {
  /**
   * Whether or not to refetch the user data from the network.
   */
  refetch?: boolean;
}

export const useLazyUser = ({ refetch }: UseLayzUserProps = {}) => {
  const [getUser, { called, data, error, loading }] = useLazyQuery<UserQuery>(
    USER_QUERY,
    {
      fetchPolicy: refetch ? 'network-only' : 'cache-first',
    },
  );

  return {
    called,
    data: data?.user,
    error,
    getUser,
    loading,
  };
};
