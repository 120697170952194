import { useLazyQuery, useQuery } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { defaultPollInterval } from 'src/helpers/constants';
import { SYSTEM, USER_SYSTEMS } from 'src/queries/system';
import {
  SystemQuery,
  SystemQueryVariables,
  UserSystemsQuery,
  UserSystemsQueryVariables,
} from 'src/types/__generated__/types';

interface UseSystemProps {
  /**
   * Id of the system.
   */
  id?: string;
  /**
   * Poll until the user has access.
   *
   * Helpful, when RBAC changes take longer, but we are certain the user should
   * have access to the system.
   *
   * @default false
   */
  pollUntilAccess?: boolean;
}

export const useSystem = ({ id, pollUntilAccess = false }: UseSystemProps) => {
  const systemId = Number(id);

  const skip = !id || Number.isNaN(systemId);
  const variables = id ? { id: systemId } : undefined;

  const { data, error, loading, startPolling, stopPolling } = useQuery<
    SystemQuery,
    SystemQueryVariables
  >(SYSTEM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables,
  });

  const system = data?.systemById;

  const hasErrorForbidden = error?.graphQLErrors.some(
    (e: GraphQLError) => e.extensions.code === 'FORBIDDEN',
  );

  const [
    loadUserSystems,
    { called: calledUserSystems, data: dataUserSystems },
  ] = useLazyQuery<UserSystemsQuery, UserSystemsQueryVariables>(USER_SYSTEMS, {
    fetchPolicy: 'cache-first',
  });

  if (!calledUserSystems && hasErrorForbidden && pollUntilAccess) {
    loadUserSystems();
  }

  const inUserSystems = dataUserSystems?.userSystems.some(
    (s) => s.id === systemId,
  );

  // Only poll, if it exists for the user
  if (pollUntilAccess && inUserSystems) {
    startPolling(defaultPollInterval);
  }

  /**
   * Stop polling when:
   * - `system` data exists.
   * - There is an error that is not of code `FORBIDDEN` and the system
   *   does not exist for the user at all.
   */
  if (pollUntilAccess) {
    if (system || (error && !hasErrorForbidden && !inUserSystems)) {
      stopPolling();
    }
  }

  return {
    data: system,
    error,
    loading,
    shouldHaveAccess: inUserSystems ?? false,
  };
};
