import { registerIcons } from '@zeiss/pharos';
import * as React from 'react';

// TODO: Ask Precise UI to export it
interface SvgIconProps {
  height?: string | null;
  style?: React.CSSProperties;
  version?: string;
  viewBox?: string;
  width?: string | null;
}

const CustomIconDefaultProps = {
  height: '24px',
  version: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 24 24',
  width: '24px',
};

export const AddOutlineIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      clipRule: 'evenodd',
      d: 'M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM11.25 12.75V16.5H12.75V12.75H16.5V11.25H12.75V7.5H11.25V11.25H7.5V12.75H11.25Z',
      fillRule: 'evenodd',
    }),
  );
};

AddOutlineIcon.defaultProps = CustomIconDefaultProps;

export const ApiIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M14,12l-2,2l-2-2l2-2L14,12z M12,6l2.12,2.12l2.5-2.5L12,1L7.38,5.62l2.5,2.5L12,6z M6,12l2.12-2.12l-2.5-2.5L1,12 l4.62,4.62l2.5-2.5L6,12z M18,12l-2.12,2.12l2.5,2.5L23,12l-4.62-4.62l-2.5,2.5L18,12z M12,18l-2.12-2.12l-2.5,2.5L12,23l4.62-4.62 l-2.5-2.5L12,18z',
    }),
  );
};

ApiIcon.defaultProps = CustomIconDefaultProps;

export const ArrowRightIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z',
    }),
  );
};

ArrowRightIcon.defaultProps = CustomIconDefaultProps;

export const ArticleIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3L19,3z',
    }),
    React.createElement('path', {
      d: 'M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z',
    }),
  );
};

ArticleIcon.defaultProps = CustomIconDefaultProps;

export const AssignmentIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H9.2C9.43333 2.4 9.8 1.91667 10.3 1.55C10.8 1.18333 11.3667 1 12 1C12.6333 1 13.2 1.18333 13.7 1.55C14.2 1.91667 14.5667 2.4 14.8 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM12 4.25C12.2167 4.25 12.396 4.179 12.538 4.037C12.6793 3.89567 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6793 3.104 12.538 2.962C12.396 2.82067 12.2167 2.75 12 2.75C11.7833 2.75 11.6043 2.82067 11.463 2.962C11.321 3.104 11.25 3.28333 11.25 3.5C11.25 3.71667 11.321 3.89567 11.463 4.037C11.6043 4.179 11.7833 4.25 12 4.25ZM5 17.85C5.9 16.9667 6.946 16.2707 8.138 15.762C9.32933 15.254 10.6167 15 12 15C13.3833 15 14.671 15.254 15.863 15.762C17.0543 16.2707 18.1 16.9667 19 17.85V5H5V17.85ZM12 13C12.9667 13 13.7917 12.6583 14.475 11.975C15.1583 11.2917 15.5 10.4667 15.5 9.5C15.5 8.53333 15.1583 7.70833 14.475 7.025C13.7917 6.34167 12.9667 6 12 6C11.0333 6 10.2083 6.34167 9.525 7.025C8.84167 7.70833 8.5 8.53333 8.5 9.5C8.5 10.4667 8.84167 11.2917 9.525 11.975C10.2083 12.6583 11.0333 13 12 13ZM7 19H17V18.75C16.3 18.1667 15.525 17.7293 14.675 17.438C13.825 17.146 12.9333 17 12 17C11.0667 17 10.175 17.146 9.325 17.438C8.475 17.7293 7.7 18.1667 7 18.75V19ZM12 11C11.5833 11 11.2293 10.854 10.938 10.562C10.646 10.2707 10.5 9.91667 10.5 9.5C10.5 9.08333 10.646 8.72933 10.938 8.438C11.2293 8.146 11.5833 8 12 8C12.4167 8 12.7707 8.146 13.062 8.438C13.354 8.72933 13.5 9.08333 13.5 9.5C13.5 9.91667 13.354 10.2707 13.062 10.562C12.7707 10.854 12.4167 11 12 11Z',
    }),
  );
};

AssignmentIcon.defaultProps = CustomIconDefaultProps;

export const BackIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M9.88 14.12L3.77333 8L9.88 1.88L8 0L0 8L8 16L9.88 14.12Z',
    }),
  );
};

BackIcon.defaultProps = CustomIconDefaultProps;

export const BulletListIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M4.5 19.25q-.525 0-.888-.363-.362-.362-.362-.887t.362-.887q.363-.363.888-.363t.888.363q.362.362.362.887t-.362.887q-.363.363-.888.363Zm3.75-.5v-1.5h12.5v1.5Zm-3.75-5.5q-.525 0-.888-.363-.362-.362-.362-.887t.362-.887q.363-.363.888-.363t.888.363q.362.362.362.887t-.362.887q-.363.363-.888.363Zm3.75-.5v-1.5h12.5v1.5ZM4.5 7.25q-.525 0-.888-.363Q3.25 6.525 3.25 6t.362-.888q.363-.362.888-.362t.888.362q.362.363.362.888t-.362.887q-.363.363-.888.363Zm3.75-.5v-1.5h12.5v1.5Z',
    }),
  );
};

BulletListIcon.defaultProps = CustomIconDefaultProps;

export const DataSourceIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M0 0h24v24H0V0z',
      fill: 'none',
    }),
    React.createElement('path', {
      d: 'M2 20h20v-4H2v4zm2-3h2v2H4v-2zM2 4v4h20V4H2zm4 3H4V5h2v2zm-4 7h20v-4H2v4zm2-3h2v2H4v-2z',
    }),
  );
};

DataSourceIcon.defaultProps = CustomIconDefaultProps;

export const GroupsIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M.5 17.8v-1.225q0-1.025 1.05-1.65t2.7-.625q.325 0 .6.012.275.013.525.063-.275.45-.425.95-.15.5-.15 1.05V17.8Zm6 0v-1.375q0-1.45 1.525-2.312Q9.55 13.25 12 13.25q2.475 0 3.988.863 1.512.862 1.512 2.312V17.8Zm12.7 0v-1.425q0-.55-.138-1.05-.137-.5-.412-.95.275-.05.55-.063.275-.012.55-.012 1.675 0 2.712.625 1.038.625 1.038 1.65V17.8ZM12 14.75q-1.55 0-2.675.412-1.125.413-1.25 1.013v.125h7.875v-.125q-.15-.6-1.262-1.013-1.113-.412-2.688-.412Zm-7.75-1.425q-.7 0-1.2-.513-.5-.512-.5-1.212t.5-1.2q.5-.5 1.2-.5.725 0 1.225.5.5.5.5 1.2t-.5 1.212q-.5.513-1.225.513Zm15.5 0q-.7 0-1.2-.513-.5-.512-.5-1.212t.5-1.2q.5-.5 1.2-.5.725 0 1.213.5.487.5.487 1.2t-.487 1.212q-.488.513-1.213.513ZM12 12.5q-1.075 0-1.837-.762Q9.4 10.975 9.4 9.9q0-1.1.763-1.85.762-.75 1.837-.75 1.1 0 1.85.75t.75 1.85q0 1.075-.75 1.838-.75.762-1.85.762Zm0-3.7q-.45 0-.775.325T10.9 9.9q0 .45.325.775T12 11q.475 0 .788-.325.312-.325.312-.775t-.312-.775Q12.475 8.8 12 8.8Zm0 7.5Zm0-6.4Z',
    }),
  );
};

GroupsIcon.defaultProps = CustomIconDefaultProps;

export const MenuBookIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M13.95 9.75V8.425q.8-.375 1.7-.563.9-.187 1.85-.187.6 0 1.163.087.562.088 1.137.213V9.25q-.55-.2-1.112-.288-.563-.087-1.188-.087-.95 0-1.85.225-.9.225-1.7.65Zm0 5.475V13.9q.8-.375 1.7-.575.9-.2 1.85-.2.6 0 1.163.087.562.088 1.137.238v1.25q-.55-.2-1.112-.288-.563-.087-1.188-.087-.95 0-1.85.225-.9.225-1.7.675Zm0-2.725v-1.35q.8-.375 1.7-.563.9-.187 1.85-.187.6 0 1.163.087.562.088 1.137.238v1.25q-.55-.2-1.112-.287-.563-.088-1.188-.088-.95 0-1.85.237-.9.238-1.7.663ZM6.5 16.025q1.25 0 2.425.275 1.175.275 2.325.875V7.35q-1.05-.675-2.275-1.025-1.225-.35-2.475-.35-.9 0-1.688.15-.787.15-1.612.45-.1.05-.15.125-.05.075-.05.15v9.45q0 .15.1.212.1.063.2.013.725-.25 1.512-.375.788-.125 1.688-.125Zm6.25 1.15q1.15-.6 2.325-.875 1.175-.275 2.425-.275.9 0 1.688.125.787.125 1.512.375.1.05.2-.013.1-.062.1-.212V6.85q0-.075-.05-.15t-.15-.125q-.825-.3-1.612-.45-.788-.15-1.688-.15-1.25 0-2.475.35T12.75 7.35Zm-.75 2.2q-1.2-.875-2.6-1.363-1.4-.487-2.9-.487-.925 0-1.8.2t-1.7.6q-.525.25-1.012-.075-.488-.325-.488-.925V6.475q0-.325.163-.613.162-.287.487-.412 1-.5 2.113-.738 1.112-.237 2.237-.237 1.45 0 2.85.4 1.4.4 2.65 1.175 1.25-.775 2.65-1.175t2.85-.4q1.125 0 2.238.237 1.112.238 2.112.738.325.125.487.412.163.288.163.613v10.85q0 .6-.5.913-.5.312-1.05.062-.825-.375-1.687-.575-.863-.2-1.763-.2-1.5 0-2.9.487-1.4.488-2.6 1.363Zm-4.875-7.8Z',
    }),
  );
};

MenuBookIcon.defaultProps = CustomIconDefaultProps;

export const NotificationsActiveIcon: React.FC<SvgIconProps> = (
  props: SvgIconProps,
) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M0 0h24v24H0z',
      fill: 'none',
    }),
    React.createElement('path', {
      d: 'M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z',
    }),
  );
};

NotificationsActiveIcon.defaultProps = CustomIconDefaultProps;

export const OpenInNewIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h7v2H5v14h14v-7h2v7q0 .825-.587 1.413Q19.825 21 19 21Zm4.7-5.3-1.4-1.4L17.6 5H14V3h7v7h-2V6.4Z',
    }),
  );
};

OpenInNewIcon.defaultProps = CustomIconDefaultProps;

export const PasswordIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M2 19V17H22V19H2ZM3.15 12.95L1.85 12.2L2.7 10.7H1V9.2H2.7L1.85 7.75L3.15 7L4 8.45L4.85 7L6.15 7.75L5.3 9.2H7V10.7H5.3L6.15 12.2L4.85 12.95L4 11.45L3.15 12.95ZM11.15 12.95L9.85 12.2L10.7 10.7H9V9.2H10.7L9.85 7.75L11.15 7L12 8.45L12.85 7L14.15 7.75L13.3 9.2H15V10.7H13.3L14.15 12.2L12.85 12.95L12 11.45L11.15 12.95ZM19.15 12.95L17.85 12.2L18.7 10.7H17V9.2H18.7L17.85 7.75L19.15 7L20 8.45L20.85 7L22.15 7.75L21.3 9.2H23V10.7H21.3L22.15 12.2L20.85 12.95L20 11.45L19.15 12.95Z',
    }),
  );
};

PasswordIcon.defaultProps = CustomIconDefaultProps;

export const SegmentIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      d: 'M9 18v-2h12v2Zm0-5v-2h12v2Zm-6 -5v-2h18v2Z',
    }),
  );
};

SegmentIcon.defaultProps = CustomIconDefaultProps;

export const StackIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('rect', {
      fill: 'none',
      height: 24,
      width: 24,
    }),
    React.createElement('path', {
      d: 'M3,5v14h18V5H3z M8.33,17H5V7h3.33V17z M13.67,17h-3.33v-4h3.33V17z M19,17h-3.33v-4H19V17z M19,11h-8.67V7H19V11z',
    }),
  );
};

StackIcon.defaultProps = CustomIconDefaultProps;

export const StyraIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      clipRule: 'evenodd',
      d: 'M17.3734 12.1435C17.6145 12.7775 17.7466 14.4617 17.7466 15.1805C17.7466 18.3541 17.6575 15.7844 12 15.7844C6.4484 15.7844 6.25328 18.3541 6.25328 15.1802C6.25328 14.4615 6.38555 12.7773 6.62639 12.1432C5.20571 11.0659 4.0679 9.32235 5.78689 6.81765C5.98555 6.52807 6.40756 6.74202 6.33479 7.09445C6.13143 8.07429 6.22975 9.53681 7.89445 10.1634C8.93765 9.09815 10.3914 8.43698 12 8.43698C13.6084 8.43698 15.0625 9.09815 16.1055 10.163C17.7703 9.53697 17.8686 8.07429 17.6652 7.09445C17.5923 6.74219 18.0145 6.52807 18.2131 6.81765C19.9321 9.32235 18.7943 11.0662 17.3734 12.1435ZM12 2C6.47731 2 2 6.47698 2 12C2 17.5227 6.47731 22 12 22C17.5227 22 22 17.5227 22 12C22 6.47698 17.5227 2 12 2Z',
      fillRule: 'evenodd',
    }),
  );
};

StyraIcon.defaultProps = CustomIconDefaultProps;

export const SyncCircleIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => {
  return React.createElement(
    'svg',
    props,
    React.createElement('path', {
      clipRule: 'evenodd',
      d: 'M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12ZM18.5267 12H17.3333C17.3333 9.05333 14.9467 6.66666 12 6.66666C11.3067 6.66666 10.64 6.8 10.0333 7.04666C9.58667 7.22666 9.46667 7.79999 9.80667 8.13999C9.98667 8.31999 10.26 8.39333 10.5 8.29333C10.96 8.1 11.4733 8 12 8C14.2067 8 16 9.79333 16 12H14.8067C14.5067 12 14.36 12.36 14.5667 12.5733L16.4267 14.4333C16.56 14.5667 16.7667 14.5667 16.9 14.4333L18.76 12.5667C18.9733 12.36 18.8267 12 18.5267 12ZM12 16C9.79333 16 8 14.2067 8 12H9.19333C9.49333 12 9.64 11.64 9.43333 11.4333L7.57333 9.57333C7.44 9.44 7.23333 9.44 7.1 9.57333L5.24 11.4333C5.02667 11.64 5.17333 12 5.47333 12H6.66667C6.66667 14.9467 9.05333 17.3333 12 17.3333C12.6933 17.3333 13.36 17.2 13.9667 16.9533C14.4133 16.7733 14.5333 16.2 14.1933 15.86C14.0133 15.68 13.74 15.6067 13.5 15.7067C13.04 15.9 12.5267 16 12 16Z',
      fillRule: 'evenodd',
    }),
  );
};

SyncCircleIcon.defaultProps = CustomIconDefaultProps;

/**
 * Register custom global icons
 *
 * TODO: Add re-usable (general) icons to Precise UI
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface AdditionalIconNames {
    AddOutline: string;
    Api: string;
    ArrowRight: string;
    Article: string;
    Assignment: string;
    Back: string;
    BulletList: string;
    DataSource: string;
    Groups: string;
    MenuBook: string;
    NotificationsActive: string;
    OpenInNew: string;
    Password: string;
    Segment: string;
    Stack: string;
    Styra: string;
    SyncCircle: string;
  }
}

export const registerCustomIcons = () =>
  registerIcons({
    AddOutline: <AddOutlineIcon />,
    Api: <ApiIcon />,
    ArrowRight: <ArrowRightIcon />,
    Article: <ArticleIcon />,
    Assignment: <AssignmentIcon />,
    Back: <BackIcon />,
    BulletList: <BulletListIcon />,
    DataSource: <DataSourceIcon />,
    Groups: <GroupsIcon />,
    MenuBook: <MenuBookIcon />,
    NotificationsActive: <NotificationsActiveIcon />,
    OpenInNew: <OpenInNewIcon />,
    Password: <PasswordIcon />,
    Segment: <SegmentIcon />,
    Stack: <StackIcon />,
    Styra: <StyraIcon />,
    SyncCircle: <SyncCircleIcon />,
  });
