import { gql } from '@apollo/client';

export const IS_MOBILE_NAV_OPEN = gql`
  query IsMobileNavOpen {
    isMobileNavOpen @client
  }
`;

export const IS_DEBUG = gql`
  query IsDebug {
    isDebug @client
  }
`;

export const INITIALIZE_LOCAL_STATE = gql`
  query InitializeLocalState {
    isDebug @client
    isMobileNavOpen @client
  }
`;
