import * as React from 'react';

import history from 'src/router/history';

/**
 * RouterContextWrapper
 *
 * Pharos UI assumes react-router v4 in `Anchor` components. This workaround
 * makes the `history` object available to it, so that `to` instead of `href`
 * is being used.
 */
export class RouterContextWrapper extends React.PureComponent {
  static contextType = React.createContext({ history });

  static childContextTypes = {
    router: () => null,
  };

  getChildContext() {
    return {
      router: this.context,
    };
  }

  render() {
    return <>{this.props.children}</>;
  }
}
