import { useQuery } from '@apollo/client';

import {
  SUBJECT_ROLE_BINDINGS,
  SUBJECT_ROLE_BINDINGS_V1,
} from 'src/queries/rest/roleBindings';
import { RbacVersion } from 'src/types/__generated__/types';
import { ExtendedRoleBinding } from 'src/types/rest/rbac';
import { ExtendedRoleBindingListResponse } from 'src/types/rest/rbac.v1';

interface SubjectRoleBindingsQueryV0 {
  subjectRoleBindings: ExtendedRoleBinding[];
}

interface SubjectRoleBindingsQueryV1 {
  subjectRoleBindingsV1: ExtendedRoleBindingListResponse;
}

interface UseSubjectRoleBindingsProps {
  rbacVersion?: RbacVersion | null;
  styraStackId?: string;
  subjectId?: ExtendedRoleBinding['subject_id'];
}

type SubjectRoleBindingsQuery =
  | SubjectRoleBindingsQueryV0
  | SubjectRoleBindingsQueryV1;

interface SubjectRoleBindingsQueryVariables {
  styraStackId: string;
  subjectId: string;
}

export const useSubjectRoleBindings = ({
  rbacVersion,
  styraStackId,
  subjectId,
}: UseSubjectRoleBindingsProps) => {
  const SUBJECT_ROLE_BINDINGS_QUERY =
    rbacVersion === RbacVersion.V0
      ? SUBJECT_ROLE_BINDINGS
      : SUBJECT_ROLE_BINDINGS_V1;

  const skip = !styraStackId || !subjectId || !rbacVersion;
  const variables = !skip ? { styraStackId, subjectId } : undefined;

  const { data, error, loading } = useQuery<
    SubjectRoleBindingsQuery,
    SubjectRoleBindingsQueryVariables
  >(SUBJECT_ROLE_BINDINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables,
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as SubjectRoleBindingsQueryV0).subjectRoleBindings
        : (data as SubjectRoleBindingsQueryV1).subjectRoleBindingsV1.results
      : undefined,
    error,
    loading,
  };
};
