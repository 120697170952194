import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from 'src/App';
import { writeInitialCacheData } from 'src/apollo/cache';
import { client } from 'src/apollo/init';
import { AppInsightsProvider } from 'src/components/shared/AppInsightsProvider';
import { HistoryRouter } from 'src/components/shared/HistoryRouter';
import { RouterContextWrapper } from 'src/components/shared/RouterContextWrapper';
import { registerCustomIcons } from 'src/components/styled/Icons';
import { ENV_NODE, basename } from 'src/helpers/constants';
import 'src/i18n';
import history from 'src/router/history';

async function main() {
  /**
   * Set up msw when env is explicitly set
   */
  if (ENV_NODE === 'development') {
    if (window.location.pathname === '/admin') {
      window.location.pathname = '/admin/';
      return;
    }

    const worker = await import('src/mocks/browser').then(
      ({ worker }) => worker,
    );

    await worker.start({
      serviceWorker: {
        url: `${basename}/mockServiceWorker.js`,
      },
    });
  }

  // Initialize local state
  writeInitialCacheData();

  // Register callback to reset local state
  // eslint-disable-next-line require-await
  client.onResetStore(async () => writeInitialCacheData);

  registerCustomIcons();

  ReactDOM.render(
    <ApolloProvider client={client}>
      <HistoryRouter basename={basename} history={history}>
        <RouterContextWrapper>
          <AppInsightsProvider>
            <App />
          </AppInsightsProvider>
        </RouterContextWrapper>
      </HistoryRouter>
    </ApolloProvider>,
    document.getElementById('root'),
  );
}

main();
