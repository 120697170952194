import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_STACK } from 'src/queries/stack';
import {
  DeleteStackMutation,
  DeleteStackMutationVariables,
} from 'src/types/__generated__/types';

interface UseDeleteStackProps {
  id: string;
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useDeleteStack = ({ id, onCompleted, t }: UseDeleteStackProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    DeleteStackMutation,
    DeleteStackMutationVariables
  >(DELETE_STACK, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.stack'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'stack',
      });
    },

    update: (cache) => {
      cache.evict({ id: `Stack:${id}` });
      cache.gc();
    },

    variables: { id: Number(id) },
  });

  return {
    data: data?.deleteStackById,
    error,
    loading,
    mutate,
  };
};
