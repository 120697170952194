import {
  StandardProps,
  css,
  displayFrom,
  distance,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { StyledHeadline } from 'src/components/styled/shared';
import { MetaAside, NamedSizes } from 'src/types';

interface ContainerStyleProps {
  distance?: NamedSizes;
  metaAsideAlignment?: MetaAside['alignment'];
}

const Container = styled.div<ContainerStyleProps>`
  display: flex;
  align-items: center;

  ${({ metaAsideAlignment }) =>
    metaAsideAlignment === 'offsite' &&
    css`
      flex-grow: 1;
      justify-content: space-between;
    `}
`;

interface SectionHeaderMetaStyleProps {
  metaAsideAttachedDistance?: MetaAside['attachedDistance'];
}

const SectionHeaderMeta = styled.div<SectionHeaderMetaStyleProps>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: ${({ metaAsideAttachedDistance }) =>
    metaAsideAttachedDistance
      ? distance[metaAsideAttachedDistance]
      : '0.75rem'};
  color: ${themed(({ theme = {} }: StandardProps) => theme.text3)};

  ${displayFrom('large')<SectionHeaderMetaStyleProps>`
    margin-left: ${({ metaAsideAttachedDistance }) =>
      metaAsideAttachedDistance
        ? distance[metaAsideAttachedDistance]
        : distance.medium};
  `}
`;

interface Props {
  /**
   * Headline text.
   */
  children?: React.ReactNode;
  /**
   * Headings level (1-5).
   * @default 2
   */
  level?: 1 | 2 | 3 | 4 | 5;
  /**
   * Extra content to display aside.
   */
  metaAside?: MetaAside;
}

/**
 * Section Header Title
 *
 * Should be placed inside `SectionHeader`, which controls the distance to the
 * next element.
 */
export const SectionHeaderTitle: React.FC<Props> = ({
  children,
  level = 2,
  metaAside,
  ...rest
}) =>
  metaAside ? (
    <Container metaAsideAlignment={metaAside.alignment} {...rest}>
      <StyledHeadline level={level}>{children}</StyledHeadline>
      <SectionHeaderMeta metaAsideAttachedDistance={metaAside.attachedDistance}>
        {metaAside.content}
      </SectionHeaderMeta>
    </Container>
  ) : (
    <StyledHeadline level={level} {...rest}>
      {children}
    </StyledHeadline>
  );
