import * as React from 'react';

/**
 * Get debounced value after specified delay
 *
 * Based on {@link https://github.com/uidotdev/usehooks/blob/acce70639fa09051dc6d9e1ba6517d5bb3f034d3/index.js#L225-L239} (MIT)
 */
export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
