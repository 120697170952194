import { useMutation } from '@apollo/client';

import {
  SYSTEM_ONBOARDING,
  UPDATE_SYSTEM_ONBOARDING,
} from 'src/queries/rest/onboarding';
import {
  SetOnboarding,
  SystemID,
  SystemOnboarding,
} from 'src/types/rest/onboarding';

interface UpdateSystemOnboardingMutation {
  updateSystemOnboarding: SystemOnboarding;
}

interface UpdateSystemOnboardingMutationVariables {
  id: SystemID;
  input: SetOnboarding;
}

export const useUpdateSystemOnboarding = () => {
  const [mutate, { data, error, loading }] = useMutation<
    UpdateSystemOnboardingMutation,
    UpdateSystemOnboardingMutationVariables
  >(UPDATE_SYSTEM_ONBOARDING, {
    refetchQueries: [SYSTEM_ONBOARDING],
  });

  return {
    data,
    error,
    loading,
    mutate,
  };
};
