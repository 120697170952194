import { ApolloError } from '@apollo/client';
import {
  Headline,
  Icon,
  InputError,
  StandardProps,
  displayFrom,
  distance,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { PlainList, PlainListData } from 'src/components/styled/PlainList';
import { BodyText } from 'src/components/styled/shared';
import { getDistanceWithFallback, uuid } from 'src/helpers/utils';
import { NamedSizes } from 'src/types';
import { ErrorCode } from 'src/types/rest/rbac';

type RestErrorCode = `REST_ERR_${ErrorCode}`;

const knownRestErrors: RestErrorCode[] = [
  'REST_ERR_CONFLICT',
  'REST_ERR_INTERNAL_SERVER_ERROR',
  'REST_ERR_NOT_FOUND',
  'REST_ERR_OPERATION_NOT_FOUND',
  'REST_ERR_RBAC_NOT_ENABLED',
  'REST_ERR_REQUEST_VALIDATION_FAILED',
];

interface StyleProps {
  distance?: NamedSizes;
}

const ErrorContainer = styled.div<StyleProps>`
  display: flex;
  margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};
  padding: ${distance.small};
  border: 1px solid
    ${themed(({ theme = {} }: StandardProps) => theme.notificationColorError)};
  border-left-width: 5px;

  ${displayFrom('large')`
    padding-right: ${distance.medium};
    padding-left: ${distance.medium};
  `}
`;

const StyledIcon = styled(Icon)`
  margin-right: ${distance.small};
  color: ${themed(
    ({ theme = {} }: StandardProps) => theme.notificationColorError,
  )};
`;

const Title = styled(Headline)`
  &&& {
    padding-top: ${distance.xxsmall};
    padding-bottom: 0;
    color: ${themed(
      ({ theme = {} }: StandardProps) => theme.notificationColorError,
    )};

    ${displayFrom('large')`
      padding-bottom: ${distance.xsmall};
    `}
  }
`;

const MinimalErrorContainer = styled(BodyText)<StyleProps>`
  margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};
  color: ${themed(
    ({ theme = {} }: StandardProps) => theme.notificationColorError,
  )};
`;

interface ErrorProps {
  /**
   * Set custom distance to the next element.
   */
  distance?: NamedSizes;
  /**
   * `ApolloError` or error message to pass through.
   */
  error: ApolloError | string;
  /**
   * Style variant.
   * @default default
   */
  variant?: 'default' | 'inputError' | 'minimal';
}

export const Error: React.FC<ErrorProps> = ({
  distance,
  error,
  variant = 'default',
  ...rest
}) => {
  const { t } = useTranslation(['common']);

  const isApolloError = typeof error !== 'string';

  const errorMessages: string[] = [];
  const graphQLErrors = isApolloError && error?.graphQLErrors;
  const networkError = isApolloError && error?.networkError;

  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      /**
       * `extensions` can be undefined unlike the deprecated
       * `ApolloError.extensions` defined it.
       * @see {@link https://github.com/graphql/graphql-js/blob/main/src/error/GraphQLError.ts#L40}
       */
      const code = error?.extensions?.code as string;

      const message: string = code
        ? t(`error.${code}`, {
            defaultValue: code,
            ns: 'common',
          })
        : t('common:error.CONFLICT');
      errorMessages.push(message);
    });
  }

  if (networkError) {
    let { message } = networkError;
    if (message && message.startsWith('REST_ERR_')) {
      /**
       * Errors from our REST endpoints thrown in our `customFetch`
       * @see {/src/apollo/init.ts}
       */
      if (knownRestErrors.includes(message as RestErrorCode)) {
        message = t(`error.${message}`, {
          defaultValue: message,
          ns: 'common',
        });
      }
    } else {
      // GraphQL network errors or unknown errors
      message = message || t('common:error.CONFLICT');
    }
    errorMessages.push(message);
  }

  if (!isApolloError) {
    errorMessages.push(error);
  }

  const finalErrorCount = errorMessages.length;

  const plainListData: PlainListData[] = errorMessages.map((error) => ({
    content: error,
    key: uuid(),
  }));

  if (finalErrorCount) {
    if (variant === 'minimal') {
      return (
        <MinimalErrorContainer distance={distance} {...rest}>
          {errorMessages[0]}
          {finalErrorCount > 1 &&
            ` (${t('common:error.textAndCountMoreError', {
              count: finalErrorCount - 1,
            })})`}
        </MinimalErrorContainer>
      );
    }

    if (variant === 'inputError') {
      return (
        <InputError>
          {finalErrorCount > 1
            ? `${t('common:error.error', {
                count: finalErrorCount,
              })}: ${errorMessages.join('; ')}`
            : errorMessages[0]}
        </InputError>
      );
    }

    return (
      <ErrorContainer distance={distance} {...rest}>
        <StyledIcon name="Error" size={1.5} />
        <div>
          <Title level={5}>
            {t('common:error.error', { count: finalErrorCount })}
          </Title>
          <PlainList data={plainListData} />
        </div>
      </ErrorContainer>
    );
  }

  return null;
};
