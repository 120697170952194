import { useQuery } from '@apollo/client';

import { STACK } from 'src/queries/stack';
import { StackQuery, StackQueryVariables } from 'src/types/__generated__/types';

export const useStack = (id?: string) => {
  const stackId = Number(id);

  const skip = !id || Number.isNaN(stackId);
  const variables = id ? { id: stackId } : undefined;

  const { data, error, loading } = useQuery<StackQuery, StackQueryVariables>(
    STACK,
    {
      skip,
      variables,
    },
  );

  return {
    data: data?.stackById,
    error,
    loading,
  };
};
