import { useQuery } from '@apollo/client';

import { USER_QUERY } from 'src/queries/rest/oauth';
import { UserQuery } from 'src/types/rest/oauth';

interface UseUserProps {
  /**
   * Whether or not to refetch the user data from the network.
   */
  refetch?: boolean;
}

// TODO: This is a workaround until we have a graphql API for the user data
export const useUser = ({ refetch }: UseUserProps = {}) => {
  const { data, error, loading } = useQuery<UserQuery>(USER_QUERY, {
    fetchPolicy: refetch ? 'network-only' : 'cache-first',
  });

  return {
    data: data?.user,
    error,
    loading,
  };
};
