import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { CREATE_SYSTEM } from 'src/queries/system';
import {
  CreateSystemMutation,
  CreateSystemMutationVariables,
} from 'src/types/__generated__/types';

interface UseCreateSystemProps {
  onCompleted?: () => void;
  showSuccessNotification?: boolean;
  t: TFunction<'common'[]>;
}

export const useCreateSystem = ({
  onCompleted,
  showSuccessNotification = true,
  t,
}: UseCreateSystemProps) => {
  const [mutate, { called, data, error, loading, reset }] = useMutation<
    CreateSystemMutation,
    CreateSystemMutationVariables
  >(CREATE_SYSTEM, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showSuccessNotification &&
        showNotification(
          `${t('common:notification.successX', {
            action: t('common:action.created').toLowerCase(),
            name: t('common:glossary.system'),
          })}`,
          {
            type: 'success',
          },
        );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'created',
        name: 'system',
      });
    },
  });

  return {
    called,
    data: data?.createSystem,
    error,
    loading,
    mutate,
    reset,
  };
};
