import { useQuery } from '@apollo/client';

import { RESOURCE } from 'src/queries/rest/resources';
import { ResourceResponse } from 'src/types/rest/rbac.v1';

interface ResourceQuery {
  resource: ResourceResponse;
}

interface ResourceQueryVariables {
  resourceName: string;
  styraStackId: string;
}

interface UseResourceProps {
  resourceName?: string;
  styraStackId?: string;
}

export const useResource = ({
  resourceName,
  styraStackId,
}: UseResourceProps) => {
  const skip = !styraStackId || !resourceName;

  // Encode the resourceName to prevent errors from non-URL-safe characters.
  const variables =
    styraStackId && resourceName
      ? { resourceName: encodeURIComponent(resourceName), styraStackId }
      : undefined;

  const { data, error, loading } = useQuery<
    ResourceQuery,
    ResourceQueryVariables
  >(RESOURCE, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables,
  });

  return {
    data: data?.resource.result,
    error,
    loading,
  };
};
