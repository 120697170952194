import { createGlobalStyle } from '@zeiss/pharos';

import fontBoldItalic from 'src/assets/fonts/bold-italic.woff';
import fontBold from 'src/assets/fonts/bold.woff';
import fontLightItalic from 'src/assets/fonts/light-italic.woff';
import fontLight from 'src/assets/fonts/light.woff';
import fontMediumItalic from 'src/assets/fonts/medium-italic.woff';
import fontMedium from 'src/assets/fonts/medium.woff';
import fontRegularItalic from 'src/assets/fonts/regular-italic.woff';
import fontRegular from 'src/assets/fonts/regular.woff';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-weight: 300;
    font-family: "ZEISS Frutiger Next";
    src: url(${fontLight}) format("woff");
    font-display: fallback;
  }

  @font-face {
    font-weight: 300;
    font-family: "ZEISS Frutiger Next";
    font-style: italic;
    src: url(${fontLightItalic}) format("woff");
    font-display: fallback;
  }

  @font-face {
    font-family: "ZEISS Frutiger Next";
    font-display: fallback;
    src: url(${fontRegular}) format("woff");
  }

  @font-face {
    font-family: "ZEISS Frutiger Next";
    font-style: italic;
    font-display: fallback;
    src: url(${fontRegularItalic}) format("woff");
  }

  @font-face {
    font-weight: 500;
    font-family: "ZEISS Frutiger Next";
    src: url(${fontMedium}) format("woff");
    font-display: fallback;
  }

  @font-face {
    font-weight: 500;
    font-family: "ZEISS Frutiger Next";
    font-style: italic;
    src: url(${fontMediumItalic}) format("woff");
    font-display: fallback;
  }

  @font-face {
    font-weight: 700;
    font-family: "ZEISS Frutiger Next";
    src: url(${fontBold}) format("woff");
    font-display: fallback;
  }

  @font-face {
    font-weight: 700;
    font-family: "ZEISS Frutiger Next";
    font-style: italic;
    src: url(${fontBoldItalic}) format("woff");
    font-display: fallback;
  }

  html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-family: 'ZEISS Frutiger Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1.4;
  }

  p {
    margin-top: 0;
  }

  /* Print styles */
  @media print {
    * {
      overflow: visible !important;
    }
  }
`;
