import { useMutation } from '@apollo/client';

import { UPDATE_SYSTEM_ONBOARDING_STEP } from 'src/queries/rest/onboarding';
import {
  SetOnboardingStep,
  SystemID,
  SystemOnboarding,
  SystemOnboardingStep,
} from 'src/types/rest/onboarding';

interface UpdateSystemOnboardingStepMutation {
  updateSystemOnboarding: SystemOnboarding;
}

interface UpdateSystemOnboardingStepMutationVariables {
  id: SystemID;
  input: SetOnboardingStep;
  step: SystemOnboardingStep;
}

export const useUpdateSystemOnboardingStep = () => {
  const [mutate, { data, error, loading }] = useMutation<
    UpdateSystemOnboardingStepMutation,
    UpdateSystemOnboardingStepMutationVariables
  >(UPDATE_SYSTEM_ONBOARDING_STEP);

  return {
    data,
    error,
    loading,
    mutate,
  };
};
