import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { REMOVE_DATA_SOURCE } from 'src/queries/dataSource';
import {
  RemoveDataSourceMutation,
  RemoveDataSourceMutationVariables,
} from 'src/types/__generated__/types';

interface UseRemoveDataSourceProps {
  id: RemoveDataSourceMutationVariables['id'];
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useRemoveDataSource = ({
  id,
  onCompleted,
  t,
}: UseRemoveDataSourceProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    RemoveDataSourceMutation,
    RemoveDataSourceMutationVariables
  >(REMOVE_DATA_SOURCE, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.removed'),
          name: t('common:glossary.dataSource'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'removed',
        name: 'dataSource',
      });
    },

    update: (cache) => {
      cache.evict({ id: `ZeissIDAgreementDatasourceConfiguration:${id}` });
      cache.gc();
    },

    variables: { id },
  });

  return {
    data: data?.deleteDatasource,
    error,
    loading,
    mutate,
  };
};
