import {
  // eslint-disable-next-line no-restricted-imports
  ActionLink as OriginalActionLink,
  ActionLinkProps,
  FontWeight,
  StandardProps,
  css,
  getFontStyle,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { useAppendBasename } from 'src/hooks';
import { AdditionalAnchorProps, StyleVariants } from 'src/types';

type ComponentStyleVariants = Extract<
  StyleVariants,
  'danger' | 'primary' | 'secondary'
>;

interface StyleProps {
  size: 'medium' | 'small';
  variant: ComponentStyleVariants;
  weight: FontWeight;
}

const StyledActionLink = styled(OriginalActionLink)<StyleProps>`
  ${({ size }) => size === 'small' && getFontStyle({ size: 'small' })}

  ${({ weight }) => getFontStyle({ weight })};

  ${({ variant }) =>
    variant === 'danger' &&
    css`
      &,
      &:hover,
      &:focus,
      &:active {
        color: ${themed(
          ({ theme = {} }: StandardProps) => theme.notificationColorError,
        )};
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      color: ${themed(({ theme = {} }: StandardProps) => theme.text2)};
    `}
`;

interface Props extends ActionLinkProps, AdditionalAnchorProps {
  /**
   * Size.
   * @default medium
   */
  size?: 'medium' | 'small';
  /**
   * Style variant.
   * @default primary
   */
  variant?: ComponentStyleVariants;
  /**
   * Font weight.
   * @default medium
   */
  weight?: FontWeight;
}

/**
 * ActionLink
 *
 * Based on `Precise UI` ActionLink, but with some opinionated tweaks, and
 * support for `history` v5.
 */
export const ActionLink: React.FC<Props> = ({
  children,
  size = 'medium',
  to,
  variant = 'primary',
  weight = 'medium',
  ...rest
}) => {
  const resTo = useAppendBasename(to);

  return (
    <StyledActionLink
      size={size}
      to={resTo}
      variant={variant}
      weight={weight}
      {...rest}
    >
      {children}
    </StyledActionLink>
  );
};
