import { useQuery } from '@apollo/client';

import { SYSTEM_NAME } from 'src/queries/system';
import {
  SystemNameQuery,
  SystemNameQueryVariables,
} from 'src/types/__generated__/types';

export const useSystemName = (id?: string) => {
  const systemId = Number(id);

  const skip = !id || Number.isNaN(systemId);
  const variables = id ? { id: systemId } : undefined;

  const { data, error, loading } = useQuery<
    SystemNameQuery,
    SystemNameQueryVariables
  >(SYSTEM_NAME, {
    skip,
    variables,
  });

  return {
    data: data?.systemById,
    error,
    loading,
  };
};
