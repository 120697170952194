import { useQuery } from '@apollo/client';

import {
  STACK_ROLE_BINDINGS,
  STACK_ROLE_BINDINGS_V1,
} from 'src/queries/rest/roleBindings';
import { RbacVersion } from 'src/types/__generated__/types';
import { ExtendedRoleBinding } from 'src/types/rest/rbac';
import { ExtendedRoleBindingListResponse } from 'src/types/rest/rbac.v1';

interface RoleBindingsQueryV0 {
  roleBindings: ExtendedRoleBinding[];
}

interface RoleBindingsQueryV1 {
  roleBindingsV1: ExtendedRoleBindingListResponse;
}

type RoleBindingsQuery = RoleBindingsQueryV0 | RoleBindingsQueryV1;

interface RoleBindingsQueryVariables {
  styraStackId: string;
}

interface UseStackRoleBindingsProps {
  rbacVersion?: RbacVersion | null;
  styraStackId?: string | null;
}

export const useStackRoleBindings = ({
  rbacVersion,
  styraStackId,
}: UseStackRoleBindingsProps) => {
  const ROLE_BINDINGS_QUERY =
    rbacVersion === RbacVersion.V0
      ? STACK_ROLE_BINDINGS
      : STACK_ROLE_BINDINGS_V1;

  const skip = !styraStackId || !rbacVersion;
  const variables = styraStackId ? { styraStackId } : undefined;

  const { data, error, loading } = useQuery<
    RoleBindingsQuery,
    RoleBindingsQueryVariables
  >(ROLE_BINDINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables,
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as RoleBindingsQueryV0).roleBindings
        : (data as RoleBindingsQueryV1).roleBindingsV1.results
      : undefined,
    error,
    loading,
  };
};
