import { gql } from '@apollo/client';

/**
 * Fragments
 */

const FRAGMENT_SYSTEM_FIELDS = gql`
  fragment SystemFields on System {
    administrators {
      id
      email
    }
    createdAt
    id
    linkedStacks {
      linkedAt
      stack {
        id
        name
      }
    }
    author {
      id
    }
    name
    styraSystemId
    zeissIdApplicationIdentifier {
      id
      zeissIdClientApplicationId
      zeissIdEnvironmentId
    }
  }
`;

export const USER_SYSTEMS = gql`
  query UserSystems {
    userSystems {
      ...SystemFields
    }
  }
  ${FRAGMENT_SYSTEM_FIELDS}
`;

export const SYSTEM = gql`
  query System($id: SystemIdentifier!) {
    systemById(id: $id) {
      ...SystemFields
    }
  }
  ${FRAGMENT_SYSTEM_FIELDS}
`;

export const SYSTEM_NAME = gql`
  query SystemName($id: SystemIdentifier!) {
    systemById(id: $id) {
      id
      name
    }
  }
`;

/**
 * Mutations
 */

export const CREATE_SYSTEM = gql`
  mutation CreateSystem($input: SystemCreationInput!) {
    createSystem(input: $input) {
      ...SystemFields
    }
  }
  ${FRAGMENT_SYSTEM_FIELDS}
`;

export const UPDATE_SYSTEM = gql`
  mutation UpdateSystem($id: SystemIdentifier!, $input: SystemUpdateInput!) {
    updateSystemById(id: $id, input: $input) {
      system {
        ...SystemFields
      }
    }
  }
  ${FRAGMENT_SYSTEM_FIELDS}
`;

export const DELETE_SYSTEM = gql`
  mutation DeleteSystem($id: SystemIdentifier!) {
    deleteSystemById(id: $id) {
      id
    }
  }
`;
