import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LOCALE, IS_PROD, basename } from 'src/helpers/constants';
import { upperFirst } from 'src/helpers/utils';

const backendOptions: HttpBackendOptions = {
  loadPath: `${basename}/locales/{{lng}}/{{ns}}.json`,
};

const initOptions: InitOptions = {
  backend: backendOptions,
  debug: !IS_PROD,
  defaultNS: 'common',
  fallbackLng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false, // Not needed for react as it escapes by default
    format: function (value, format) {
      if (format === 'lowercase') return value.toLowerCase();
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'upperFirst') return upperFirst(value);
      return value;
    },
  },
  load: 'languageOnly', // Currently no locales, so we don’t need to load all
  ns: 'common',
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(initOptions);

export default i18n;
