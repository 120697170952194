import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { UPDATE_ROLE, UPDATE_ROLE_V1 } from 'src/queries/rest/roles';
import { RbacVersion } from 'src/types/__generated__/types';
import { RoleUpdateInput } from 'src/types/rest/rbac';
import {
  RoleResponse,
  RoleUpdateInput as RoleUpdateInputV1,
} from 'src/types/rest/rbac.v1';

interface UpdateRoleMutationV0 {
  updateRole: {
    NoContent: null;
  };
}

interface UpdateRoleMutationV1 {
  updateRoleV1: RoleResponse;
}

type UpdateRoleMutation = UpdateRoleMutationV0 | UpdateRoleMutationV1;

interface UpdateRoleMutationVariablesV0 {
  input: RoleUpdateInput;
  roleId: string;
  styraStackId: string;
}

interface UpdateRoleMutationVariablesV1 {
  input: RoleUpdateInputV1;
  roleId: string;
  styraStackId: string;
}

type UpdateRoleMutationVariables =
  | UpdateRoleMutationVariablesV0
  | UpdateRoleMutationVariablesV1;

interface UseUpdateRoleProps {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  roleId: string;
  t: TFunction<'common'[]>;
}

export const useUpdateRole = ({
  onCompleted,
  rbacVersion,
  roleId,
  t,
}: UseUpdateRoleProps) => {
  const UPDATE_MUTATION =
    rbacVersion === RbacVersion.V0 ? UPDATE_ROLE : UPDATE_ROLE_V1;

  const [mutate, { data, error, loading, reset }] = useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(UPDATE_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.updated'),
          name: t('common:glossary.role'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'updated',
        name: 'role',
      });
    },

    update: (cache) => {
      cache.evict({ id: `Role:${roleId}` });
      cache.gc();
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as UpdateRoleMutationV0).updateRole
        : (data as UpdateRoleMutationV1).updateRoleV1.result
      : undefined,
    error,
    loading,
    mutate,
    reset,
  };
};
