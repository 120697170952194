import { gql } from '@apollo/client';

/**
 * Queries
 */

export const USER_QUERY = gql`
  query OAuthUser {
    user @rest(endpoint: "oauth", path: "/user", type: "User") {
      __typename
      id
      email
      features
      name
    }
  }
`;
