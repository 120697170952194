import { useMutation } from '@apollo/client';

import {
  STACK_ONBOARDING,
  UPDATE_STACK_ONBOARDING,
} from 'src/queries/rest/onboarding';
import {
  SetOnboarding,
  StackID,
  StackOnboarding,
} from 'src/types/rest/onboarding';

interface UpdateStackOnboardingMutation {
  updateStackOnboarding: StackOnboarding;
}

interface UpdateStackOnboardingMutationVariables {
  id: StackID;
  input: SetOnboarding;
}

export const useUpdateStackOnboarding = () => {
  const [mutate, { data, error, loading }] = useMutation<
    UpdateStackOnboardingMutation,
    UpdateStackOnboardingMutationVariables
  >(UPDATE_STACK_ONBOARDING, {
    refetchQueries: [STACK_ONBOARDING],
  });

  return {
    data,
    error,
    loading,
    mutate,
  };
};
