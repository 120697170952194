import { useQuery } from '@apollo/client';

import { STACK_NAME } from 'src/queries/stack';
import {
  StackNameQuery,
  StackNameQueryVariables,
} from 'src/types/__generated__/types';

export const useStackName = (id?: string) => {
  const stackId = Number(id);

  const skip = !id || Number.isNaN(stackId);
  const variables = id ? { id: stackId } : undefined;

  const { data, error, loading } = useQuery<
    StackNameQuery,
    StackNameQueryVariables
  >(STACK_NAME, {
    skip,
    variables,
  });

  return {
    data: data?.stackById,
    error,
    loading,
  };
};
