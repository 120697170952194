import { useQuery } from '@apollo/client';

import { USER_STACKS } from 'src/queries/stack';
import {
  UserStacksQuery,
  UserStacksQueryVariables,
} from 'src/types/__generated__/types';

export const useUserStacks = () => {
  const { data, error, loading } = useQuery<
    UserStacksQuery,
    UserStacksQueryVariables
  >(USER_STACKS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return {
    data: data?.userStacks,
    error,
    loading,
  };
};
