import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { CREATE_SCOPES, CREATE_SCOPES_V1 } from 'src/queries/rest/scopes';
import { RbacVersion } from 'src/types/__generated__/types';
import { Scope } from 'src/types/rest/rbac';
import { ScopeListResponse } from 'src/types/rest/rbac.v1';

interface CreateScopesMutationV0 {
  createScopes: {
    scopes: Scope[];
  };
}

interface CreateScopesMutationV1 {
  createScopesV1: ScopeListResponse;
}

type CreateScopesMutation = CreateScopesMutationV0 | CreateScopesMutationV1;

export interface CreateScopesMutationVariables {
  input: {
    names: string[];
  };
  styraStackId: string;
}

interface UseCreateScopesProps {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useCreateScopes = ({
  onCompleted,
  rbacVersion,
  t,
}: UseCreateScopesProps) => {
  const CREATE_SCOPES_MUTATION =
    rbacVersion === RbacVersion.V0 ? CREATE_SCOPES : CREATE_SCOPES_V1;

  const [mutate, { data, error, loading }] = useMutation<
    CreateScopesMutation,
    CreateScopesMutationVariables
  >(CREATE_SCOPES_MUTATION, {
    onCompleted: (mutationData) => {
      onCompleted && onCompleted();

      const count =
        rbacVersion === RbacVersion.V0
          ? (mutationData as CreateScopesMutationV0).createScopes.scopes.length
          : (mutationData as CreateScopesMutationV1).createScopesV1.results
              ?.length;

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.created'),
          count,
          name: t('common:glossary.scope', {
            count,
          }),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'created',
        name: 'scope',
      });
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as CreateScopesMutationV0).createScopes
        : (data as CreateScopesMutationV1).createScopesV1.results
      : undefined,
    error,
    loading,
    mutate,
  };
};
