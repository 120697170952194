import { useQuery } from '@apollo/client';

import { USER_SYSTEMS } from 'src/queries/system';
import {
  UserSystemsQuery,
  UserSystemsQueryVariables,
} from 'src/types/__generated__/types';

export const useUserSystems = () => {
  const { data, error, loading } = useQuery<
    UserSystemsQuery,
    UserSystemsQueryVariables
  >(USER_SYSTEMS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return {
    data: data?.userSystems,
    error,
    loading,
  };
};
