import { useQuery } from '@apollo/client';

import { ACTION, ACTION_V1 } from 'src/queries/rest/actions';
import { RbacVersion } from 'src/types/__generated__/types';
import { Action } from 'src/types/rest/rbac';
import { ActionResponse } from 'src/types/rest/rbac.v1';

interface ActionQueryV0 {
  action: Action;
}

interface ActionQueryV1 {
  actionV1: ActionResponse;
}

type ActionQuery = ActionQueryV0 | ActionQueryV1;

export interface ActionQueryVariables {
  actionName: string;
  styraStackId: string;
}

interface UseActionProps {
  actionName?: string;
  rbacVersion?: RbacVersion | null;
  styraStackId?: string;
}

export const useAction = ({
  actionName,
  rbacVersion,
  styraStackId,
}: UseActionProps) => {
  const ACTION_QUERY = rbacVersion === RbacVersion.V0 ? ACTION : ACTION_V1;

  const skip = !styraStackId || !actionName || !rbacVersion;

  // Encode the actionName to prevent errors from non-URL-safe characters.
  const variables =
    styraStackId && actionName
      ? { actionName: encodeURIComponent(actionName), styraStackId }
      : undefined;

  const { data, error, loading } = useQuery<ActionQuery, ActionQueryVariables>(
    ACTION_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip,
      variables,
    },
  );

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as ActionQueryV0).action
        : (data as ActionQueryV1).actionV1.result
      : undefined,
    error,
    loading,
  };
};
