import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_SYSTEM } from 'src/queries/system';
import {
  DeleteSystemMutation,
  DeleteSystemMutationVariables,
} from 'src/types/__generated__/types';

interface UseDeleteSystemProps {
  id: string;
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useDeleteSystem = ({
  id,
  onCompleted,
  t,
}: UseDeleteSystemProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    DeleteSystemMutation,
    DeleteSystemMutationVariables
  >(DELETE_SYSTEM, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.system'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'system',
      });
    },

    update: (cache) => {
      cache.evict({ id: `System:${id}` });
      cache.gc();
    },

    variables: { id: Number(id) },
  });

  return {
    data: data?.deleteSystemById,
    error,
    loading,
    mutate,
  };
};
