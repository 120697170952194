import { gql } from '@apollo/client';

/**
 * Queries
 */
export const STACK_ONBOARDING = gql`
  query StackOnboarding($id: String!) {
    stackOnboarding(id: $id)
      @rest(
        endpoint: "onboarding"
        path: "/stacks/{args.id}"
        type: "StackOnboarding"
      ) {
      done
      reason
      steps
    }
  }
`;

export const SYSTEM_ONBOARDING = gql`
  query SystemOnboarding($id: String!) {
    systemOnboarding(id: $id)
      @rest(
        endpoint: "onboarding"
        path: "/systems/{args.id}"
        type: "SystemOnboarding"
      ) {
      done
      reason
      steps
    }
  }
`;

/**
 * Mutations
 */
export const UPDATE_STACK_ONBOARDING = gql`
  mutation UpdateStackOnboarding($id: String!, $input: SetOnboarding!) {
    updateStackOnboarding(id: $id, input: $input)
      @rest(
        endpoint: "onboarding"
        method: "PUT"
        path: "/stacks/{args.id}"
        type: "StackOnboarding"
      ) {
      done
      reason
      steps
    }
  }
`;

export const UPDATE_STACK_ONBOARDING_STEP = gql`
  mutation UpdateStackOnboardingStep(
    $id: String!
    $step: StackOnboardingStep!
    $input: SetOnboardingStep!
  ) {
    updateStackOnboardingStep(id: $id, step: $step, input: $input)
      @rest(
        endpoint: "onboarding"
        method: "POST"
        path: "/stacks/{args.id}/{args.step}"
        type: "StackOnboarding"
      ) {
      done
      reason
      steps
    }
  }
`;

export const UPDATE_SYSTEM_ONBOARDING = gql`
  mutation UpdateSystemOnboarding($id: String!, $input: SetOnboarding!) {
    updateSystemOnboarding(id: $id, input: $input)
      @rest(
        endpoint: "onboarding"
        method: "PUT"
        path: "/systems/{args.id}"
        type: "SystemOnboarding"
      ) {
      done
      reason
      steps
    }
  }
`;

export const UPDATE_SYSTEM_ONBOARDING_STEP = gql`
  mutation UpdateSystemOnboardingStep(
    $id: String!
    $step: SystemOnboardingStep!
    $input: SetOnboardingStep!
  ) {
    updateSystemOnboardingStep(id: $id, step: $step, input: $input)
      @rest(
        endpoint: "onboarding"
        method: "PUT"
        path: "/systems/{args.id}/{args.step}"
        type: "SystemOnboarding"
      ) {
      done
      reason
      steps
    }
  }
`;
