import { gql } from '@apollo/client';

/**
 * Fragments
 */

const FRAGMENT_STACK_FIELDS = gql`
  fragment StackFields on Stack {
    administrators {
      id
      email
    }
    linkedSystems {
      linkedAt
      system {
        createdAt
        name
        id
        zeissIdApplicationIdentifier {
          id
          zeissIdClientApplicationId
          zeissIdEnvironmentId
        }
      }
    }
    author {
      id
      email
    }
    datasources {
      ... on ZeissIDAgreementDatasourceConfiguration {
        agreementTemplateIDs
        environment
        finishedAt
        id
        importAcceptedTermsAndConditions
        importAccountCustomFields
        importClientApplications
        startedAt
        status
      }
    }
    id
    name
    rbacEnabled
    rbacVersion
    styraStackId
  }
`;

/**
 * Queries
 */

export const STACK = gql`
  query Stack($id: StackIdentifier!) {
    stackById(id: $id) {
      ...StackFields
    }
  }
  ${FRAGMENT_STACK_FIELDS}
`;

export const STACK_NAME = gql`
  query StackName($id: StackIdentifier!) {
    stackById(id: $id) {
      id
      name
    }
  }
`;

export const STACK_RBAC_ENABLED = gql`
  query StackRbacEnabled($id: StackIdentifier!) {
    stackById(id: $id) {
      id
      rbacEnabled
      rbacVersion
    }
  }
`;

export const STACK_RBAC_METADATA = gql`
  query StackRbacMetadata($id: StackIdentifier!) {
    stackById(id: $id) {
      id
      rbacEnabled
      rbacVersion
      styraStackId
    }
  }
`;

export const STACK_STYRA_ID = gql`
  query StackStyraId($id: StackIdentifier!) {
    stackById(id: $id) {
      id
      styraStackId
    }
  }
`;

export const USER_STACKS = gql`
  query UserStacks {
    userStacks {
      ...StackFields
    }
  }
  ${FRAGMENT_STACK_FIELDS}
`;

// TODO: Verify if field rename is matched with existing cached data,
// otherwise refactor.
export const USER_STACKS_AND_SYSTEMS = gql`
  query UserStacksAndSystems {
    userStacks {
      id
      name
      styraId: styraStackId
      rbacEnabled
      rbacVersion
    }
    userSystems {
      id
      name
      styraId: styraSystemId
    }
  }
`;

/**
 * Mutations
 */

export const CREATE_STACK = gql`
  mutation CreateStack($input: StackCreationInput!) {
    createStack(input: $input) {
      created {
        stack {
          ...StackFields
        }
        rbacAPIKey
      }
    }
  }
  ${FRAGMENT_STACK_FIELDS}
`;

export const UPDATE_STACK = gql`
  mutation UpdateStack($id: StackIdentifier!, $input: StackUpdateInput!) {
    updateStackById(id: $id, input: $input) {
      rbacAPIKey
      stack {
        ...StackFields
      }
    }
  }
  ${FRAGMENT_STACK_FIELDS}
`;

export const DELETE_STACK = gql`
  mutation DeleteStack($id: StackIdentifier!) {
    deleteStackById(id: $id) {
      id
    }
  }
`;
