import {
  StandardProps,
  css,
  displayFrom,
  distance,
  keyframes,
  themed,
} from '@zeiss/pharos';

import { enableCustomScrollbar } from 'src/helpers/constants';

/**
 * Layout
 */
export const mainContainerPadding = css`
  padding: ${distance.medium};

  ${displayFrom('medium')`
    padding: ${distance.large};
  `}

  ${displayFrom('large')`
    padding: ${distance.xlarge};
  `}

  ${displayFrom('max')`
    padding-right: ${distance.xxlarge};
    padding-left: ${distance.xxlarge};
  `}
`;

export const balanceOutMainContainerPaddingX = css`
  margin-right: -${distance.medium};
  margin-left: -${distance.medium};

  ${displayFrom('medium')`
    margin-right: -${distance.large};
    margin-left: -${distance.large};
  `}

  ${displayFrom('large')`
    margin-right: -${distance.xlarge};
    margin-left: -${distance.xlarge};
  `}

  ${displayFrom('max')`
    margin-right: -${distance.xxlarge};
    margin-left: -${distance.xxlarge};
  `}
`;

interface ComponentPaddingStyleProps {
  subtractPaddingL?: string;
}

export const componentPadding = ({
  subtractPaddingL = '0px',
}: ComponentPaddingStyleProps) => css`
  padding: ${distance.small} ${distance.medium} ${distance.small}
    calc(${distance.medium} - ${subtractPaddingL});

  ${displayFrom('medium')`
    padding: {distance.medium} ${distance.large} ${distance.medium} calc(${distance.large} - ${subtractPaddingL});
  `}

  ${displayFrom('large')`
    padding-right: ${distance.xlarge};
    padding-left: calc(${distance.xlarge} - ${subtractPaddingL});
  `}

  ${displayFrom('max')`
    padding-right: ${distance.xxlarge};
    padding-left: calc(${distance.xxlarge} - ${subtractPaddingL});
  `}
`;

export const componentPaddingX = css`
  padding-right: ${distance.medium};
  padding-left: ${distance.medium};

  ${displayFrom('medium')`
    padding-right: ${distance.large};
    padding-left: ${distance.large};
  `}

  ${displayFrom('large')`
    padding-right: ${distance.xlarge};
    padding-left: ${distance.xlarge};
  `}

  ${displayFrom('max')`
    padding-right: ${distance.xxlarge};
    padding-left: ${distance.xxlarge};
  `}
`;

export const vhWithoutTopBarAndFooter = css`
  height: calc(100vh - 57px - 55px);
`;

export const sectionMarginBottom = css`
  margin-bottom: ${distance.large};

  ${displayFrom('large')`
    margin-bottom: ${distance.xlarge};
  `}
`;

export const xCenter = css`
  display: inline-flex;
  justify-content: center;
`;

export const yCenter = css`
  display: inline-flex;
  align-items: center;
`;

/**
 * Animations
 */
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const rotateAnimation = css`
  animation: ${rotate} 3s linear infinite;
`;

/**
 * Transitions
 */
export const bgColorTransition = css`
  transition: background-color cubic-bezier(0.5, 0, 0.1, 1);
`;

/**
 * Resets
 */
export const listResets = css`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

/**
 * Fixes
 *
 * TODO: Fix in Pharos UI.
 */
export const tableFixes = css`
  thead {
    color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
  }
`;

export const balanceIconButtonWithButton = css`
  padding: calc(${distance.small} - 1px);
`;

export const svgIconCenter = css`
  display: inline-block;
  vertical-align: top;
`;

/**
 * Component styles
 */
export const fontMono = css`
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
`;

const customScrollbar = css`
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${themed(({ theme = {} }: StandardProps) => theme.ui4)};
  }
`;

export const xScrollContainer = css`
  /* Support scrolling on mobile where there is no hover */
  overflow-x: auto;

  /* On larger screens use hover to support scrolling with a custom scrollbar */
  ${displayFrom('medium')`
    overflow-x: hidden;

    :hover {
      overflow-x: auto;
      ${enableCustomScrollbar && customScrollbar}
    }
  `}
`;

export const yScrollContainer = css`
  /* Support scrolling on mobile where there is no hover */
  overflow-y: auto;

  /* On larger screens use hover to support scrolling with a custom scrollbar */
  ${displayFrom('medium')`
    overflow-y: hidden;

    :hover {
      overflow-y: auto;
      ${enableCustomScrollbar && customScrollbar}
    }
  `}
`;

export const connectionLineActiveOrDone = css`
  background-color: ${themed(({ theme = {} }: StandardProps) => theme.ui5)};
`;

export const connectionLineUpcoming = css`
  background-image: radial-gradient(
    2px 2px at center,
    ${themed(({ theme = {} }: StandardProps) => theme.ui4)} 1px,
    transparent 0
  );
  background-repeat: repeat-y;
  background-size: 2px 4px;
`;

/**
 * Print
 */
export const hideOnPrint = css`
  @media print {
    display: none;
  }
`;
