import { gql } from '@apollo/client';

/**
 * Fragments
 */

const FRAGMENT_ROLE_BINDINGS_V1_FIELDS = gql`
  fragment RoleBindingsV1Fields on ExtendedRoleBindingV1 {
    id
    role
    role_name
    scope_name
    subject_id
  }
`;

/**
 * Queries
 */

export const STACK_ROLE_BINDINGS = gql`
  query RoleBindings($styraStackId: String!) {
    roleBindings(stackId: $styraStackId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/rolebindings?include_roles=true"
        type: "ExtendedRoleBinding"
      ) {
      id
      role
      role_id
      scope
      scope_id
      subject_id
    }
  }
`;

export const SUBJECT_ROLE_BINDINGS = gql`
  query SubjectRoleBindings($styraStackId: String!, $subjectId: String!) {
    subjectRoleBindings(stackId: $styraStackId, subjectId: $subjectId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/subjects/{args.subjectId}/rolebindings?include_roles=true"
        type: "ExtendedRoleBinding"
      ) {
      id
      role
      role_id
      scope
      scope_id
      subject_id
    }
  }
`;

/**
 * V1 Queries
 */

export const STACK_ROLE_BINDINGS_V1 = gql`
  query RoleBindingsV1($styraStackId: String!) {
    roleBindingsV1(stackId: $styraStackId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/rolebindings?include_roles=true"
        type: "ExtendedRoleBindingListResponse"
      ) {
      results @type(name: "ExtendedRoleBindingV1") {
        ...RoleBindingsV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_BINDINGS_V1_FIELDS}
`;

export const SUBJECT_ROLE_BINDINGS_V1 = gql`
  query SubjectRoleBindingsV1($styraStackId: String!, $subjectId: String!) {
    subjectRoleBindingsV1(stackId: $styraStackId, subjectId: $subjectId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/subjects/{args.subjectId}/rolebindings?include_roles=true"
        type: "ExtendedRoleBinding"
      ) {
      results @type(name: "ExtendedRoleBindingV1") {
        ...RoleBindingsV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_BINDINGS_V1_FIELDS}
`;

/**
 * Mutations
 */

export const CREATE_ROLE_BINDING = gql`
  mutation CreateRoleBinding(
    $styraStackId: String!
    $roleName: String!
    $input: RoleBindingCreationInput!
    $bodyBuilder: any
  ) {
    createRoleBinding(
      stackId: $styraStackId
      roleName: $roleName
      input: $input
    )
      @rest(
        endpoint: "rbac"
        method: "POST"
        path: "/stacks/{args.stackId}/roles/name/{args.roleName}/scopes/rolebindings"
        type: "RoleBinding"
        bodyBuilder: $bodyBuilder
      ) {
      id
      role_name
      scope_id
      subject_id
    }
  }
`;

export const DELETE_ROLE_BINDING = gql`
  mutation DeleteRoleBinding($styraStackId: String!, $roleBindingId: String!) {
    deleteRoleBinding(stackId: $styraStackId, roleBindingId: $roleBindingId)
      @rest(
        endpoint: "rbac"
        method: "DELETE"
        path: "/stacks/{args.stackId}/rolebindings/{args.roleBindingId}"
      ) {
      NoContent
    }
  }
`;

/**
 * V1 Mutations
 */

export const CREATE_ROLE_BINDING_V1 = gql`
  mutation CreateRoleBinding(
    $styraStackId: String!
    $roleName: String!
    $input: RoleBindingCreationInput!
    $bodyBuilder: any
  ) {
    createRoleBinding(
      stackId: $styraStackId
      roleName: $roleName
      input: $input
    )
      @rest(
        endpoint: "rbacV1"
        method: "POST"
        path: "/stacks/{args.stackId}/roles/{args.roleName}/rolebindings"
        type: "RoleBinding"
        bodyBuilder: $bodyBuilder
      ) {
      result @type(name: "ExtendedRoleBindingV1") {
        ...RoleBindingsV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_BINDINGS_V1_FIELDS}
`;

export const DELETE_ROLE_BINDING_V1 = gql`
  mutation DeleteRoleBindingV1(
    $styraStackId: String!
    $roleBindingId: String!
  ) {
    deleteRoleBindingV1(stackId: $styraStackId, roleBindingId: $roleBindingId)
      @rest(
        endpoint: "rbacV1"
        method: "DELETE"
        path: "/stacks/{args.stackId}/rolebindings/{args.roleBindingId}"
        type: "ExtendedRoleBindingV1"
      ) {
      NoContent
    }
  }
`;
