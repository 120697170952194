import { GraphQLErrors } from '@apollo/client/errors';
import {
  IconName,
  NotificationOption,
  PreciseFullTheme,
  colors,
  distance,
  notify,
} from '@zeiss/pharos';
import { intlFormat, isThisWeek, isThisYear, isToday } from 'date-fns';
import { TFunction } from 'i18next';
import * as React from 'react';

import { defaultMaxChars, defaultMinChars } from 'src/helpers/constants';
import {
  Context,
  DistanceSizes,
  KeyPath,
  RouteKey,
  Status,
  StatusEnum,
  Validator,
} from 'src/types';
import { ZeissIdEnvironment } from 'src/types/__generated__/types';

// TODO: rename to toCapitalCase
export const upperFirst = (str: string) => {
  const lowerCaseStr = str.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

export const convertToBackendType = (kind: string, value: unknown) => {
  switch (kind) {
    case 'BOOL':
      return value === 'true';
    case 'INT': {
      if (typeof value === 'string') {
        return parseInt(value, 10);
      }
      break;
    }
    case 'FLOAT':
      if (typeof value === 'string') {
        return parseFloat(value);
      }
      break;
    case 'FILE':
      return value;
    case 'FILELIST':
      return value;
    case 'STRING':
    default:
      return value;
  }
};

interface EnumerateStringArrayProps {
  /**
   * Grammatical conjunction to use.
   * @defaultValue and
   */
  conjunction?: 'and' | 'or';
  /**
   * Translation function.
   */
  t: TFunction;
  /**
   * Array of string values to list.
   */
  value: string[];
}

export const enumerateStringArray = ({
  conjunction = 'and',
  t,
  value,
}: EnumerateStringArrayProps): string => {
  if (value.length === 1) {
    return value[0];
  }

  const translatedConjunction =
    conjunction === 'and'
      ? t('and', { ns: 'common' })
      : t('or', { ns: 'common' });

  if (value.length === 2) {
    return value.join(` ${translatedConjunction} `);
  }

  // Join last value with oxford comma
  return `${value
    .slice(0, -1)
    .join(', ')}, ${translatedConjunction} ${value.slice(-1)}`;
};

export const envToTranslation = (t: TFunction, env: ZeissIdEnvironment) => {
  return t('zeissIdWithEnv', {
    env: upperFirst(env.toString()),
    ns: 'common',
  });
};

/**
 * formatDate
 *
 * - today: 9:21 AM
 * - this week: Sun-Sat
 * - this year: Jan 10
 * - not this year: 1/10/20
 */
export const formatDate = (date: Date) => {
  if (isToday(date))
    return intlFormat(date, { hour: 'numeric', minute: '2-digit' });
  if (isThisWeek(date)) return intlFormat(date, { weekday: 'short' });
  if (isThisYear(date))
    return intlFormat(date, { day: 'numeric', month: 'short' });

  return intlFormat(date, {
    day: '2-digit',
    month: 'numeric',
    year: '2-digit',
  });
};

export const formValidateLength = (
  t: TFunction,
  value: string,
  max?: number,
  min?: number,
) => {
  if (min && value.length < min) {
    return t('validation.minCharacters', {
      min,
      ns: 'common',
    });
  } else if (max && value.length > max) {
    return t('validation.maxCharacters', {
      max,
      ns: 'common',
    });
  }
  return true;
};

export const formValidateMandatoryTextField = (t: TFunction, value?: string) =>
  !value ? t('validation.required', { ns: 'common' }) : true;

export const formValidateMandatoryDropdownField = (
  t: TFunction,
  value?: unknown[],
) =>
  !value || value.length === 0
    ? t('validation.required', { ns: 'common' })
    : true;

export const formValidateRegex = (
  exp: RegExp,
  errorText: string,
  value?: string,
) => {
  if (typeof value !== 'undefined' && !exp.test(value)) return errorText;
  return true;
};

export const formValidateType = (
  t: TFunction,
  type: 'number' | 'string',
  value?: string,
) => {
  switch (type) {
    case 'number': {
      const valid = /^[0-9\b]+$/;
      if (value && !valid.test(value))
        return t('validation.typeX', {
          ns: 'common',
          type: t('number', { ns: 'common' }),
        });
      return true;
    }
    case 'string':
    default:
      return true;
  }
};

/**
 * @param minChars @defaultValue defaultMinChars
 * @param maxChars @defaultValue defaultMaxChars
 *
 * @returns RegExp - to validate that text is in the specified
 * min and max range and does not start with a space
 */
export const createTextValidationRegex = (
  minChars = defaultMinChars,
  maxChars = defaultMaxChars,
) => new RegExp(`^(?=.{${minChars},${maxChars}}$)[^\\s]+.*$`);

interface TextValidatorProps {
  /**
   * Maximum characters allowed
   * @defaultValue defaultMaxChars
   */
  maxChars?: number;
  /**
   * Minimum characters allowed
   * @defaultValue defaultMinChars
   */
  minChars?: number;
  t: TFunction;
}

type TextValidator = (opts: TextValidatorProps) => Validator;

export const formValidateText: TextValidator = ({
  maxChars = defaultMaxChars,
  minChars = defaultMinChars,
  t,
}) => {
  return (value: string) =>
    formValidateRegex(
      createTextValidationRegex(minChars, maxChars),
      t('validation.name', {
        max: maxChars,
        min: minChars,
        ns: 'common',
      }),
      value,
    );
};

export const generateEnvLink = (env: ZeissIdEnvironment, path: string) => {
  const normalizedPath = path.charAt(0) === '/' ? path.replace('/', '') : path;
  switch (env) {
    case ZeissIdEnvironment.Test:
      return `https://id-mgmt-test.zeiss.com/${normalizedPath}`;
    case ZeissIdEnvironment.Stage:
      return `https://id-mgmt-stage.zeiss.com/${normalizedPath}`;
    case ZeissIdEnvironment.Prod:
      return `https://id-mgmt.zeiss.com/${normalizedPath}`;
    default:
      return '#';
  }
};

/**
 * TODO:
 * - BE should include a `name` field to describe the data source. Currently,
 *   we only have the Agreement data source.
 */
export const getDataSourceName = (env: ZeissIdEnvironment, t: TFunction) => {
  return `${envToTranslation(t, env)} ${t('glossary.agreement', {
    ns: 'common',
  })}`;
};

export const getStatusDate = ({
  finishedAt,
  startedAt,
}: {
  finishedAt?: string | null;
  startedAt?: string | null;
}) => {
  if (finishedAt) return formatDate(new Date(finishedAt));
  if (startedAt) return formatDate(new Date(startedAt));
  return;
};

// TODO: Add 0 as a valid size to Precise UI, to make workaround obsolete
export const getDistanceWithFallback = (
  fallbackDefault: DistanceSizes,
  size?: DistanceSizes,
) => {
  if (size === 0) return 0;
  if (size) return distance[size];
  if (fallbackDefault === 0) return 0;
  return distance[fallbackDefault];
};

export const getInitials = function (fullName: string) {
  const names = fullName.toUpperCase().split(' ');
  let initials = names[0].charAt(0);

  if (names.length > 1) {
    initials += names[names.length - 1].charAt(0);
  }
  return initials;
};

export const mapContextToIcon = (context: Context): IconName => {
  switch (context) {
    case 'Data Source':
      return 'DataSource';
    case 'Stack':
      return 'Stack';
    case 'System':
      return 'Apps';
    default:
      return 'MoreHoriz';
  }
};

export const mapDropdownEnvToEnum = (key: number): ZeissIdEnvironment => {
  switch (key) {
    case 0:
      return ZeissIdEnvironment.Prod;
    case 1:
      return ZeissIdEnvironment.Stage;
    case 2:
      return ZeissIdEnvironment.Test;
    default:
      return ZeissIdEnvironment.Prod;
  }
};

export const mapEnvToDropdownValue = (env?: ZeissIdEnvironment): number[] => {
  switch (env) {
    case ZeissIdEnvironment.Test:
      return [2];
    case ZeissIdEnvironment.Stage:
      return [1];
    case ZeissIdEnvironment.Prod:
    default:
      return [0];
  }
};

export const mapNavKeyToIcon = (key?: RouteKey): IconName => {
  switch (key) {
    case 'dashboard':
      return 'Home';
    case 'stacks':
      return 'Stack';
    case 'systems':
      return 'Apps';
    case 'docs':
      return 'Article';
    case 'styra':
      return 'Styra';
    case 'tokens':
      return 'Password';
    default:
      return 'Home';
  }
};

export const mapStatusToColors = (status: Status, theme: PreciseFullTheme) => {
  const defaultStyles = {
    iconColor: theme.ui5,
    iconColorSecondary: theme.ui0,
  };

  switch (status) {
    case StatusEnum.Failed:
      return {
        iconColor: theme.notificationColorError,
        textColor: theme.notificationColorError,
      };
    case StatusEnum.Running:
      return {
        iconColor: theme.ui0,
        iconColorSecondary: theme.ui1,
      };
    case StatusEnum.Finished:
      return {
        iconColor: colors.green,
        textColor: colors.green,
      };
    case StatusEnum.Warning:
      return {
        iconColor: colors.brightLemon,
        textColor: colors.brightLemon,
      };
    case StatusEnum.NotStarted:
    case StatusEnum.Orphaned:
    default:
      return defaultStyles;
  }
};

export const mapStatusToIcon = (status: Status) => {
  switch (status) {
    case StatusEnum.Failed:
      return 'Cancel';
    case StatusEnum.Running:
      return 'SyncCircle';
    case StatusEnum.Finished:
      return 'CheckCircle';
    case StatusEnum.Warning:
      return 'Error';
    case StatusEnum.NotStarted:
    case StatusEnum.Orphaned:
    default:
      return 'RadioButtonUnchecked';
  }
};

const expand = <T>(obj: T, path: KeyPath<T>): number | string | undefined => {
  const properties = path.split('.');

  return properties.reduce((acc: any, cur: any) => acc?.[cur], obj);
};

interface SearchItemProps<T> {
  /**
   * List of items to search through.
   */
  items: T[];
  /**
   * List of {@link KeyPath}(s) in T.
   * The 'words' in the `query` are only searched for in the values at these
   * paths that are `numbers` or `strings`.
   */
  params: KeyPath<T>[];
  /**
   * Query containing space separated 'words' to use in search.
   */
  query: string;
}

export const searchItems = <T>({
  items,
  params,
  query,
}: SearchItemProps<T>) => {
  const result: T[] = [];

  const wordsInQuery = query.toLocaleLowerCase().trim().split(' ');

  items.forEach((item) => {
    let isItemFound = false;

    for (const word of wordsInQuery) {
      if (isItemFound) break;

      for (const param of params) {
        const val = expand(item, param);

        if (
          (typeof val === 'number' && val.toString().includes(word)) ||
          (typeof val === 'string' && val.toLocaleLowerCase().includes(word))
        ) {
          isItemFound = true;
          result.push(item);
          break;
        }
      }
    }
  });

  return result;
};

export const showNotification = (
  content: React.ReactChild,
  options: NotificationOption,
) => {
  const { type, ...rest } = options;
  notify({
    content,
    options: {
      ...rest,
      autoClose: 5000,
      position: 'bottom-right',
      type: type || 'success',
    },
  });
};

/**
 * Generate RFC-4122 compliant random UUIDs (version 4)
 * @see {@link https://stackoverflow.com/posts/2117523/revisions}
 */
export const uuid = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};

export const getResourceTranslation = (
  t: TFunction,
  currentRouteParent: string,
) => {
  switch (currentRouteParent) {
    case 'stacks':
      return t('glossary.stack', { ns: 'common' });
    case 'systems':
      return t('glossary.system', { ns: 'common' });
  }
};

export const validateEmail = (email: string) => {
  const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);
  return emailRegex.test(email);
};

interface NotificationMessageOptions {
  action: string;
  name:
    | 'action'
    | 'dataSource'
    | 'resource'
    | 'role'
    | 'roleBinding'
    | 'scope'
    | 'stack'
    | 'system'
    | 'token';
}

export const showErrorNotification = (
  graphQLErrors: GraphQLErrors,
  t: TFunction,
  messageOptions: NotificationMessageOptions,
) => {
  const { action, name } = messageOptions;

  const errorCount = graphQLErrors.length;
  const code = errorCount && graphQLErrors[0].extensions?.code;

  const errorMessage =
    errorCount > 1
      ? t('notification.multipleErrors', {
          count: errorCount,
          ns: 'common',
        })
      : t('notification.errorX', {
          action: t(`action.${action}`, {
            defaultValue: action,
            ns: 'common',
          }),
          additionalMessage: code
            ? t(`error.${code}`, {
                defaultValue: `${code}`,
                ns: 'common',
              })
            : '',
          name: t(`glossary.${name}`, { defaultValue: name, ns: 'common' }),
          ns: 'common',
        });

  showNotification(errorMessage, { type: 'error' });
};
