import { Pagination, distance } from '@zeiss/pharos';
import * as React from 'react';
import styled from 'styled-components/macro';

import { plainListPaginationSize } from 'src/helpers/constants';
import { PlainListItem } from './';

export interface PlainListData {
  content: React.ReactNode;
  key: string;
  meta?: React.ReactNode;
}

const StyledPagination = styled(Pagination)`
  margin-bottom: ${distance.small};
`;

interface Props {
  /**
   * Data for the list to render within the card.
   */
  data: PlainListData[];
  /**
   * Pagination size.
   * @default plainListPaginationSize
   */
  paginationSize?: number;
}

export const PlainList: React.FC<Props> = ({
  data,
  paginationSize = plainListPaginationSize,
}) => {
  if (data.length > paginationSize) {
    return (
      <StyledPagination size={paginationSize}>
        {data.map((item) => (
          <PlainListItem data={item} key={item.key} />
        ))}
      </StyledPagination>
    );
  }

  return (
    <>
      {data.map((item) => (
        <PlainListItem data={item} key={item.key} />
      ))}
    </>
  );
};
