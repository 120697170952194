import { gql } from '@apollo/client';

/**
 * Fragments
 */

const FRAGMENT_ACTION_V1_FIELDS = gql`
  fragment ActionV1Fields on ActionV1 {
    id
    name
  }
`;

/**
 * Queries
 */
export const STACK_ACTIONS = gql`
  query StackActions($styraStackId: String!) {
    stackActions(stackId: $styraStackId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/actions"
        type: "Action"
      ) {
      id
      name
    }
  }
`;

export const ACTION = gql`
  query Action($styraStackId: String!, $actionName: String!) {
    action(stackId: $styraStackId, actionName: $actionName)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/actions/{args.actionName}"
        type: "Action"
      ) {
      id
      name
    }
  }
`;

/**
 * V1 Queries
 */

export const STACK_ACTIONS_V1 = gql`
  query StackActionsV1($styraStackId: String!) {
    stackActionsV1(stackId: $styraStackId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/actions"
        type: "ActionListResponse"
      ) {
      results @type(name: "ActionV1") {
        ...ActionV1Fields
      }
    }
  }
  ${FRAGMENT_ACTION_V1_FIELDS}
`;

export const ACTION_V1 = gql`
  query Action($styraStackId: String!, $actionName: String!) {
    actionV1(stackId: $styraStackId, actionName: $actionName)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/actions/{args.actionName}"
        type: "ActionV1"
      ) {
      result @type(name: "ActionV1") {
        ...ActionV1Fields
      }
    }
  }
  ${FRAGMENT_ACTION_V1_FIELDS}
`;

/**
 * Mutations
 */

export const CREATE_ACTIONS = gql`
  mutation CreateActions($styraStackId: String!, $input: ActionCreationInput!) {
    createActions(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbac"
        method: "POST"
        path: "/stacks/{args.stackId}/actions"
        type: "Action"
      ) {
      id
      name
    }
  }
`;

export const DELETE_ACTION = gql`
  mutation DeleteAction($styraStackId: String!, $actionName: String!) {
    deleteAction(stackId: $styraStackId, actionName: $actionName)
      @rest(
        endpoint: "rbac"
        method: "DELETE"
        path: "/stacks/{args.stackId}/actions/{args.actionName}"
        type: "Action"
      ) {
      NoContent
    }
  }
`;

export const UPDATE_ACTION = gql`
  mutation UpdateAction(
    $styraStackId: String!
    $actionName: String!
    $input: ActionUpdateInput!
  ) {
    updateAction(
      stackId: $styraStackId
      actionName: $actionName
      input: $input
    )
      @rest(
        endpoint: "rbac"
        method: "PATCH"
        path: "/stacks/{args.stackId}/actions/{args.actionName}"
        type: "Action"
      ) {
      NoContent
    }
  }
`;

/**
 * V1 Mutations
 */

export const CREATE_ACTIONS_V1 = gql`
  mutation CreateActions($styraStackId: String!, $input: ActionCreationInput!) {
    createActionsV1(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbacV1"
        method: "POST"
        path: "/stacks/{args.stackId}/actions"
        type: "ActionListResponse"
      ) {
      result @type(name: "ActionV1") {
        ...ActionV1Fields
      }
    }
  }
  ${FRAGMENT_ACTION_V1_FIELDS}
`;

export const DELETE_ACTION_V1 = gql`
  mutation DeleteAction($styraStackId: String!, $actionName: String!) {
    deleteActionV1(stackId: $styraStackId, actionName: $actionName)
      @rest(
        endpoint: "rbacV1"
        method: "DELETE"
        path: "/stacks/{args.stackId}/actions/{args.actionName}"
        type: "ActionV1"
      ) {
      NoContent
    }
  }
`;

export const UPDATE_ACTION_V1 = gql`
  mutation UpdateAction(
    $styraStackId: String!
    $actionName: String!
    $input: ActionUpdateInput!
  ) {
    updateActionV1(
      stackId: $styraStackId
      actionName: $actionName
      input: $input
    )
      @rest(
        endpoint: "rbacV1"
        method: "PATCH"
        path: "/stacks/{args.stackId}/actions/{args.actionName}"
        type: "ActionV1"
      ) {
      result @type(name: "ActionV1") {
        ...ActionV1Fields
      }
    }
  }
  ${FRAGMENT_ACTION_V1_FIELDS}
`;
