import { gql } from '@apollo/client';

/**
 * Fragments
 */

const FRAGMENT_SCOPE_V1_FIELDS = gql`
  fragment ScopeV1Fields on ScopeV1 {
    id
    name
  }
`;

/**
 * Queries
 */

export const STACK_SCOPES = gql`
  query StackScopes($styraStackId: String!) {
    stackScopes(stackId: $styraStackId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/scopes"
        type: "Scope"
      ) {
      id
      name
    }
  }
`;

export const SCOPE = gql`
  query Scope($styraStackId: String!, $scopeId: String!) {
    scope(stackId: $styraStackId, scopeId: $scopeId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/scopes/{args.scopeId}"
        type: "Scope"
      ) {
      id
      name
    }
  }
`;

/**
 * V1 Queries
 */

export const STACK_SCOPES_V1 = gql`
  query StackScopesV1($styraStackId: String!) {
    stackScopesV1(stackId: $styraStackId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/scopes"
        type: "ScopeListResponse"
      ) {
      results @type(name: "ScopeV1") {
        ...ScopeV1Fields
      }
    }
  }
  ${FRAGMENT_SCOPE_V1_FIELDS}
`;

export const SCOPE_V1 = gql`
  query Scope($styraStackId: String!, $scopeId: String!) {
    scopeV1(stackId: $styraStackId, scopeId: $scopeId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/scopes/id/{args.scopeId}"
        type: "ScopeResponse"
      ) {
      result @type(name: "ScopeV1") {
        ...ScopeV1Fields
      }
    }
  }
  ${FRAGMENT_SCOPE_V1_FIELDS}
`;

/**
 * Mutations
 */
// TODO: Improve typing of rest endpoints
export const CREATE_SCOPES = gql`
  mutation CreateScopes($styraStackId: String!, $input: ScopeCreationInput!) {
    createScopes(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbac"
        method: "POST"
        path: "/stacks/{args.stackId}/scopes"
        type: "ScopeCreationResult"
      ) {
      scopes @type(name: "Scope") {
        id
        name
      }
    }
  }
`;

export const UPDATE_SCOPE = gql`
  mutation UpdateScope(
    $styraStackId: String!
    $scopeId: String!
    $input: ScopeUpdateInput!
  ) {
    updateScope(stackId: $styraStackId, scopeId: $scopeId, input: $input)
      @rest(
        endpoint: "rbac"
        method: "PATCH"
        path: "/stacks/{args.stackId}/scopes/{args.scopeId}"
        type: "Scope"
      ) {
      NoContent
    }
  }
`;

export const DELETE_SCOPE = gql`
  mutation DeleteScope($styraStackId: String!, $scopeId: String!) {
    deleteScope(stackId: $styraStackId, scopeId: $scopeId)
      @rest(
        endpoint: "rbac"
        method: "DELETE"
        path: "/stacks/{args.stackId}/scopes/{args.scopeId}"
        type: "Scope"
      ) {
      NoContent
    }
  }
`;

/**
 * V1 Mutations
 */

export const CREATE_SCOPES_V1 = gql`
  mutation CreateScopes($styraStackId: String!, $input: ScopeCreationInput!) {
    createScopesV1(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbacV1"
        method: "POST"
        path: "/stacks/{args.stackId}/scopes"
        type: "ScopeListResponse"
      ) {
      results @type(name: "ScopeV1") {
        ...ScopeV1Fields
      }
    }
  }
  ${FRAGMENT_SCOPE_V1_FIELDS}
`;

export const UPDATE_SCOPE_V1 = gql`
  mutation UpdateScope(
    $styraStackId: String!
    $scopeId: String!
    $input: ScopeUpdateInput!
  ) {
    updateScopeV1(stackId: $styraStackId, scopeId: $scopeId, input: $input)
      @rest(
        endpoint: "rbacV1"
        method: "PATCH"
        path: "/stacks/{args.stackId}/scopes/id/{args.scopeId}"
        type: "ScopeV1"
      ) {
      result @type(name: "ScopeV1") {
        ...ScopeV1Fields
      }
    }
  }
  ${FRAGMENT_SCOPE_V1_FIELDS}
`;

export const DELETE_SCOPE_V1 = gql`
  mutation DeleteScope($styraStackId: String!, $scopeId: String!) {
    deleteScopeV1(stackId: $styraStackId, scopeId: $scopeId)
      @rest(
        endpoint: "rbacV1"
        method: "DELETE"
        path: "/stacks/{args.stackId}/scopes/id/{args.scopeId}"
        type: "ScopeV1"
      ) {
      NoContent
    }
  }
`;
