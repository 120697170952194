import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/styled/Button';
import { Code } from 'src/components/styled/Code';
import {
  SectionContentElement,
  SectionHeader,
  SectionHeaderTitle,
  SectionText,
} from 'src/components/styled/Section';
import history from 'src/router/history';

interface Props {
  /**
   * Error to display.
   */
  error: Error;
}

// TODO: Add proper design and support channel flow
export const ScreenErrorFallback: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation(['common']);

  const onTryAgain = () => history.go(0);

  return (
    <>
      <SectionHeader distance="large">
        <SectionHeaderTitle level={1}>
          {t('common:error.error')}
        </SectionHeaderTitle>
      </SectionHeader>

      <SectionText>{t('common:error.textScreenError')}</SectionText>

      <SectionContentElement>
        <Button onClick={onTryAgain}>{t('common:action.tryAgain')}</Button>
        <Button variant="secondary">
          {t('common:action.contactX', { name: t('common:support') })}
        </Button>
      </SectionContentElement>

      <SectionContentElement>
        <Code>{error.message}</Code>
      </SectionContentElement>
    </>
  );
};
