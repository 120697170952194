import * as React from 'react';
import styled from 'styled-components/macro';

import { BodyText } from 'src/components/styled/shared';
import { textMaxWidth } from 'src/helpers/constants';
import { getDistanceWithFallback } from 'src/helpers/utils';
import { DistanceSizes } from 'src/types';

const StyledBodyText = styled(BodyText)<Props>`
  max-width: ${textMaxWidth};
  margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};
`;

interface Props {
  /**
   * Text to display.
   */
  children?: React.ReactNode;
  /**
   * Set custom distance to the next element.
   * Useful when a visual heavy/light element is following.
   * @defaultValue large
   */
  distance?: DistanceSizes;
}

export const SectionText: React.FC<Props> = ({
  children,
  distance = 'large',
  ...rest
}) => (
  <StyledBodyText distance={distance} {...rest}>
    {children}
  </StyledBodyText>
);
