import { gql } from '@apollo/client';

/**
 * Fragments
 */

const FRAGMENT_ROLE_V1_FIELDS = gql`
  fragment RoleV1Fields on RoleV1 {
    id
    name
    permissions
    scope
  }
`;

/**
 * Queries
 */

export const STACK_ROLES = gql`
  query StackRoles($styraStackId: String!) {
    stackRoles(stackId: $styraStackId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/roles"
        type: "Role"
      ) {
      actions
      id
      name
      scope
    }
  }
`;

export const ROLE = gql`
  query Role($styraStackId: String!, $roleId: String!) {
    role(stackId: $styraStackId, roleId: $roleId)
      @rest(
        endpoint: "rbac"
        path: "/stacks/{args.stackId}/roles/{args.roleId}"
        type: "Role"
      ) {
      actions
      id
      name
      scope
    }
  }
`;

/**
 * V1 Queries
 */

export const STACK_ROLES_V1 = gql`
  query StackRolesV1($styraStackId: String!) {
    stackRolesV1(stackId: $styraStackId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/roles"
        type: "RolesListResponse"
      ) {
      results @type(name: "RoleV1") {
        ...RoleV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_V1_FIELDS}
`;

export const ROLE_V1 = gql`
  query RoleV1($styraStackId: String!, $roleId: String!) {
    roleV1(stackId: $styraStackId, roleId: $roleId)
      @rest(
        endpoint: "rbacV1"
        path: "/stacks/{args.stackId}/roles/id/{args.roleId}"
        type: "RoleResponse"
      ) {
      result @type(name: "RoleV1") {
        ...RoleV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_V1_FIELDS}
`;

/**
 * Mutations
 */

export const CREATE_ROLE = gql`
  mutation CreateRole($styraStackId: String!, $input: RoleCreationInput!) {
    createRole(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbac"
        method: "POST"
        path: "/stacks/{args.stackId}/roles"
        type: "Role"
      ) {
      actions
      id
      name
      scope
    }
  }
`;

export const CREATE_SCOPED_ROLE = gql`
  mutation CreateScopedRole(
    $input: RoleCreationInput!
    $scopeId: String!
    $styraStackId: String!
  ) {
    createRole(input: $input, scopeId: $scopeId, stackId: $styraStackId)
      @rest(
        endpoint: "rbac"
        method: "POST"
        path: "/stacks/{args.stackId}/scopes/{args.scopeId}/roles"
        type: "Role"
      ) {
      actions
      id
      name
      scope
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($styraStackId: String!, $roleId: string) {
    deleteRole(stackId: $styraStackId, roleId: $roleId)
      @rest(
        endpoint: "rbac"
        method: "DELETE"
        path: "/stacks/{args.stackId}/roles/{args.roleId}"
        type: "Role"
      ) {
      NoContent
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole(
    $styraStackId: String!
    $roleId: string
    $input: RoleUpdateInput!
  ) {
    updateRole(stackId: $styraStackId, roleId: $roleId, input: $input)
      @rest(
        endpoint: "rbac"
        method: "PATCH"
        path: "/stacks/{args.stackId}/roles/{args.roleId}"
        type: "Role"
      ) {
      NoContent
    }
  }
`;

/**
 * V1 Mutations
 */

export const CREATE_ROLE_V1 = gql`
  mutation CreateRoleV1($styraStackId: String!, $input: RoleCreationInput!) {
    createRoleV1(stackId: $styraStackId, input: $input)
      @rest(
        endpoint: "rbacV1"
        method: "POST"
        path: "/stacks/{args.stackId}/roles"
        type: "RoleCreationInput"
      ) {
      result @type(name: "RoleV1") {
        ...RoleV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_V1_FIELDS}
`;

export const CREATE_SCOPED_ROLE_V1 = gql`
  mutation CreateScopedRoleV1(
    $input: RoleCreationInput!
    $scopeName: String!
    $styraStackId: String!
  ) {
    createRoleV1(input: $input, scopeName: $scopeName, stackId: $styraStackId)
      @rest(
        endpoint: "rbacV1"
        method: "POST"
        path: "/stacks/{args.stackId}/scopes/{args.scopeName}/roles"
        type: "RoleV1"
      ) {
      result @type(name: "RoleV1") {
        ...RoleV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_V1_FIELDS}
`;

export const DELETE_ROLE_V1 = gql`
  mutation DeleteRole($styraStackId: String!, $roleId: string) {
    deleteRoleV1(stackId: $styraStackId, roleId: $roleId)
      @rest(
        endpoint: "rbacV1"
        method: "DELETE"
        path: "/stacks/{args.stackId}/roles/id/{args.roleId}"
        type: "RoleV1"
      ) {
      NoContent
    }
  }
`;

export const UPDATE_ROLE_V1 = gql`
  mutation UpdateRole(
    $styraStackId: String!
    $roleId: string
    $input: RoleUpdateInput!
  ) {
    updateRoleV1(stackId: $styraStackId, roleId: $roleId, input: $input)
      @rest(
        endpoint: "rbacV1"
        method: "PATCH"
        path: "/stacks/{args.stackId}/roles/id/{args.roleId}"
        type: "RoleV1"
      ) {
      result @type(name: "RoleV1") {
        ...RoleV1Fields
      }
    }
  }
  ${FRAGMENT_ROLE_V1_FIELDS}
`;
