import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface CustomEventData {
  /**
   * Additional properties
   */
  [key: string]: any;
  /**
   * Name of the component that contains the tracked event
   */
  component: string;
  /**
   * Event name. This should be in title case
   */
  name: string;
}

/**
 * Track custom events in Application Insights.
 */
export const useTrackEvent = () => {
  const appInsights = useAppInsightsContext();

  const trackEvent = (data: CustomEventData) => {
    const { name, ...properties } = data;

    appInsights.trackEvent({
      name,
      properties: {
        ...properties,
        route: window.location.pathname,
      },
    });
  };

  return { trackEvent };
};
