import * as React from 'react';
import styled from 'styled-components/macro';

import { getDistanceWithFallback } from 'src/helpers/utils';
import { DistanceSizes } from 'src/types';

interface ContainerProps {
  distance: DistanceSizes;
}

const Container = styled.section<ContainerProps>`
  &:not(:last-child) {
    margin-bottom: ${({ distance }) => getDistanceWithFallback(0, distance)};
  }
`;

interface Props {
  /**
   * Content representing the section.
   */
  children?: React.ReactNode;
  /**
   * Set custom distance to the next element.
   * Useful for when a visual heavy/light element is following.
   * @default xlarge
   */
  distance?: DistanceSizes;
}

export const Section: React.FC<Props> = ({ distance = 'xlarge', ...rest }) => (
  <Container distance={distance} {...rest} />
);
