import { useQuery } from '@apollo/client';

import { STACK_RBAC_METADATA } from 'src/queries/stack';
import {
  StackRbacMetadataQuery,
  StackRbacMetadataQueryVariables,
} from 'src/types/__generated__/types';

export const useStackRbacMetadata = (id?: string) => {
  const stackId = Number(id);

  const skip = !id || Number.isNaN(stackId);
  const variables = id ? { id: stackId } : undefined;

  const { data, error, loading } = useQuery<
    StackRbacMetadataQuery,
    StackRbacMetadataQueryVariables
  >(STACK_RBAC_METADATA, {
    skip,
    variables,
  });

  return {
    data: data?.stackById,
    error,
    loading,
  };
};
