import { useQuery } from '@apollo/client';

import { STACK_RESOURCES } from 'src/queries/rest/resources';
import { ResourceListResponse } from 'src/types/rest/rbac.v1';

export interface ResourcesQuery {
  stackResources: ResourceListResponse;
}

export interface ResourcesQueryVariables {
  styraStackId: string;
}

export const useStackResources = (styraStackId?: string) => {
  const skip = !styraStackId;
  const variables = styraStackId ? { styraStackId } : undefined;

  const { data, error, loading } = useQuery<
    ResourcesQuery,
    ResourcesQueryVariables
  >(STACK_RESOURCES, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
    variables,
  });

  return {
    data: data?.stackResources.results,
    error,
    loading,
  };
};
