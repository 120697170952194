import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { UPDATE_RESOURCE } from 'src/queries/rest/resources';
import { ResourceResponse, ResourceUpdateInput } from 'src/types/rest/rbac.v1';

interface UpdateResourceMutation {
  updateResource: ResourceResponse;
}

interface UpdateResourceMutationVariables {
  input: ResourceUpdateInput;
  resourceName: string;
  styraStackId: string;
}

interface UseUpdateResourceProps {
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useUpdateResource = ({
  onCompleted,
  t,
}: UseUpdateResourceProps) => {
  const [mutate, { data, error, loading }] = useMutation<
    UpdateResourceMutation,
    UpdateResourceMutationVariables
  >(UPDATE_RESOURCE, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.updated'),
          name: t('common:glossary.resource'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'updated',
        name: 'resource',
      });
    },
  });

  return {
    data,
    error,
    loading,
    mutate,
  };
};
