import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as React from 'react';
// eslint-disable-next-line no-restricted-imports,ordered-imports/ordered-imports
import {
  ErrorBoundary as OriginalErrorBoundary,
  ErrorBoundaryProps,
} from 'react-error-boundary';

import { reactPlugin } from 'src/components/shared/AppInsightsProvider';

const logError = (error: Error, info: { componentStack: string }) => {
  // Log caught errors on Application Insights
  reactPlugin.trackException({
    error: error,
    exception: error,
    properties: info,
    severityLevel: SeverityLevel.Error,
  });
};

/**
 * Application Insights "aware" error boundary
 */
export const ErrorBoundary = (
  props: React.PropsWithChildren<ErrorBoundaryProps>,
) => {
  return <OriginalErrorBoundary onError={logError} {...props} />;
};
