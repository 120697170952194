import {
  // eslint-disable-next-line no-restricted-imports
  BodyText as OriginalBodyText,
  // eslint-disable-next-line no-restricted-imports
  Checkbox as OriginalCheckbox,
  // eslint-disable-next-line no-restricted-imports
  FontWeight,
  Headline,
  // eslint-disable-next-line no-restricted-imports
  Label as OriginalLabel,
  Spinner,
  StandardProps,
  TabPage,
  // eslint-disable-next-line no-restricted-imports
  Tabs as OriginalTabs,
  TextField,
  Tooltip,
  css,
  distance,
  getFontStyle,
  themed,
} from '@zeiss/pharos';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ActionLink } from 'src/components/styled/ActionLink';
import { textMaxWidth } from 'src/helpers/constants';

export const StyledLink = styled(Link)`
  display: inline-block;
  color: inherit;
  text-decoration: none;
`;

export const TableItemLink = styled(ActionLink)`
  margin-right: 0.75rem;
  color: inherit;
  font-weight: inherit;

  &:hover,
  &:focus,
  &:active {
    color: ${themed(({ theme = {} }: StandardProps) => theme.text0)};
  }

  &:hover > * {
    color: inherit;
  }
`;

interface XYCenterProps {
  /**
   * Custom height.
   * @default 100vh
   */
  height?: string;
  /**
   * Custom width.
   * @default 100vw
   */
  width?: string;
}

export const XYCenter = styled.div<XYCenterProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : '100vw')};
  height: ${({ height }) => (height ? height : '100vh')};
`;

export const VerticalCenter = styled.div`
  display: flex;
  align-content: center;
`;

export const CenterAlignedText = styled.div`
  text-align: center;
`;

export const SmallTooltip = styled(Tooltip)`
  ${getFontStyle({ size: 'small' })}
`;

export const CtaText = styled.span`
  color: ${themed(({ theme = {} }: StandardProps) => theme.text0)};

  ${getFontStyle({ weight: 'medium' })}
  &:hover,
  &:focus,
  &:active {
    color: ${themed(({ theme = {} }: StandardProps) => theme.ui6)};
  }
`;

export const BoldText = styled.span`
  ${getFontStyle({ weight: 'medium' })}
`;

export const ItalicText = styled.span`
  font-style: italic;
`;

export const BrandingContainer = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const LogoContainer = styled.div`
  width: 2rem;
  height: 2rem;
`;

export const CurrentUserText = styled.span`
  padding-left: ${distance.xsmall};
  color: ${themed(({ theme = {} }: StandardProps) => theme.text2)};
`;

/**
 * -----------------------------------------------------------------------------
 * Precise UI style corrections according to the Sketch styleguide
 * -----------------------------------------------------------------------------
 */
export const BodyText = styled(OriginalBodyText)`
  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
`;

export const Checkbox = styled(OriginalCheckbox)`
  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
`;

export const Label = styled(OriginalLabel)`
  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
`;

export const Tabs = styled(OriginalTabs)`
  /* Limited fix, because active class is random */
  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};
`;

/**
 * -----------------------------------------------------------------------------
 * Tweaked Precise UI defaults (opinionated)
 * -----------------------------------------------------------------------------
 */
interface StyledHeadlineProps {
  weight?: FontWeight;
}

export const StyledHeadline = styled(Headline)<StyledHeadlineProps>`
  &&& {
    margin-bottom: 0;
    padding-bottom: 0;
    color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};

    ${({ weight }) => weight && getFontStyle({ weight })}
  }
`;

interface StyledTextareaProps {
  maxWidth?: string;
}

export const StyledTextarea = styled(TextField)<StyledTextareaProps>`
  color: ${themed(({ theme = {} }: StandardProps) => theme.text1)};

  ${({ maxWidth = textMaxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

export const StyledTabPage = styled(TabPage)`
  margin-top: ${distance.large};
`;

interface StyledSpinnerProps {
  inline?: boolean;
}

export const StyledSpinner = styled(Spinner)<StyledSpinnerProps>`
  ${({ inline }) =>
    inline &&
    css`
      display: inline-block;
    `}
  > label {
    margin-top: ${distance.medium};
    ${getFontStyle({ size: 'medium' })}
  }
`;
