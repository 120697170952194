import { TableCellEvent, TableMode } from '@zeiss/pharos';
import { RouteObject } from 'react-router-dom';
import {
  BreadcrumbComponentType,
  BreadcrumbsRoute,
} from 'use-react-router-breadcrumbs';

import {
  DatasourceStatus as DatasourceStatusEnum,
  System,
} from 'src/types/__generated__/types';

// TODO: Add to Precise UI
export interface AdditionalAnchorProps {
  /**
   * `rel` attribute. Space-separated list of link types for the relationship
   * between resource and current document.
   */
  rel?: HTMLLinkElement['rel'];
}

export type Context = 'Data Source' | 'Stack' | 'System';

export type Density = 'compact' | 'cozy' | 'default';

export type DistanceSizes = NamedSizes | 0;

export interface ExtendedRouteProps extends BreadcrumbsRoute {
  /**
   * Page layout to use.
   */
  layout?: PageLayout;
  /**
   * Path. In contrast to in `RouteProps`, required.
   */
  path: string;
  /**
   * Translation key used for nav and breadcrumb.
   */
  translationKey?: string;
}

export interface ExtendedRouteObject extends RouteObject {
  /**
   * Breadcrumb component.
   */
  breadcrumb?: BreadcrumbComponentType | string | null;
  /**
   * Content to render.
   */
  children?: ExtendedRouteObject[];
  /**
   * Props to pass down. Necessary to support passing down custom props to
   * custom `Breadcrumbs`.
   *  @see {@link https://github.com/icd2k3/use-react-router-breadcrumbs/releases/tag/2.0.0}
   */
  props?: Record<string, unknown>;
  /**
   * Translation key used for nav and breadcrumb.
   */
  translationKey?: string;
  /**
   * When set to `true` the breadcrumb is not wrapped in the default `NavLink`.
   *
   * This is useful when using a custom breadcrumb component, which could
   * otherwise lead to invalid semantic, e.g. `a` tag in `a` tag.
   */
  unwrappedBreadcrumb?: boolean;
}

export interface ForceSkip {
  forceSkip?: boolean;
}

export type FormSubmitMode = 'multiple' | 'single';

export type StackFormContext = 'stackCreateWizard' | 'stackUpdate';

export interface LayoutOptions {
  /**
   * Whether to disable layout margins or not.
   * @default false
   */
  disableMargin?: boolean;
  /**
   * Whether to disable layout paddings or not.
   * @default false
   */
  disablePadding?: boolean;
}

export type LinkedSystemData = Pick<
  System,
  'createdAt' | 'id' | 'name' | 'zeissIdApplicationIdentifier'
>;

export type MediaRatios = '1:1' | '2:1' | '4:3';

export interface MetaAside {
  alignment?: 'attached' | 'offsite';
  attachedDistance?: DistanceSizes;
  content: React.ReactNode;
}

export type NamedSizes =
  | 'large'
  | 'medium'
  | 'small'
  | 'xlarge'
  | 'xsmall'
  | 'xxlarge'
  | 'xxsmall'
  | 'xxxlarge';

export interface NavItemProps {
  /**
   * Boolean indicating if path is a link to an external url.
   */
  isExtUrl?: boolean;
  /**
   * Path to link to.
   */
  path: string;
  /**
   * Indicate if a navigation item should be behind a feature flag or not.
   */
  protected?: boolean;
  /**
   * Route identifier.
   */
  routeKey: RouteKey;
  /**
   * Translation key.
   */
  translationKey: string;
}

export type PageLayout =
  | 'custom'
  | 'default'
  | 'defaultWithoutPadding'
  | 'fullPage'
  | 'fullWithoutPadding'
  | 'wide'
  | 'wideWithSideBar';

export type RouteKey =
  | 'dashboard'
  | 'docs'
  | 'rbac'
  | 'settings'
  | 'stacks'
  | 'styra'
  | 'systems'
  | 'tokens';

export interface SetupGuideState {
  ctaEnabled: boolean;
}

// We need unknown because the flat form object
export interface StackDataSource extends UnknownFormData {
  environment: number[];
  id: string;
  importAcceptedTermsAndConditions: boolean;
  importAccountCustomFields: boolean;
  importClientApplications: boolean;
}

export type StandardLocationState = {
  prevPath?: string;
} | null;

export type StandardRouteParams =
  | 'actionName'
  | 'id'
  | 'resourceName'
  | 'roleId'
  | 'scopeId'
  | 'subjectId';

export enum UiStatusEnum {
  NotStarted = 'NOT_STARTED',
  Warning = 'WARNING',
}

/**
 * Status
 *
 * Universal status for the whole app.
 *
 * Notes:
 * - `DatasourceStatusEnum.Orphaned` is the initial backend status and can’t
 *   occur after processing. When possible, prefer `NotStarted` in the frontend
 *   codebase for the initial status, as it’s more precise.
 */
export type Status = DatasourceStatusEnum | UiStatusEnum;

export const StatusEnum = { ...DatasourceStatusEnum, ...UiStatusEnum };

export type StyleVariants =
  | 'danger'
  | 'dangerSecondary'
  | 'primary'
  | 'secondary'
  | 'transparentSecondary'
  | 'warning';

export type StandardStyleVariants = Extract<
  StyleVariants,
  'primary' | 'secondary'
>;

export interface TableCellEventWithMode<T> extends TableCellEvent<T> {
  mode?: TableMode;
}

export type UnknownFormData = Record<string, unknown>;

export type Validator = (value: string) => React.ReactChild | true;

export type ValidationRule = Record<string, Validator>;
