import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { CREATE_ACTIONS, CREATE_ACTIONS_V1 } from 'src/queries/rest/actions';
import { RbacVersion } from 'src/types/__generated__/types';
import { Action } from 'src/types/rest/rbac';
import { ActionListResponse } from 'src/types/rest/rbac.v1';

interface CreateActionsMutationV0 {
  createActions: Action[];
}

interface CreateActionsMutationV1 {
  createActionsV1: ActionListResponse;
}
type CreateActionsMutation = CreateActionsMutationV0 | CreateActionsMutationV1;

export interface CreateActionsMutationVariables {
  input: {
    names: string[];
  };
  styraStackId: string;
}

interface UseCreateActionsProps {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useCreateActions = ({
  onCompleted,
  rbacVersion,
  t,
}: UseCreateActionsProps) => {
  const CREATE_ACTIONS_MUTATION =
    rbacVersion === RbacVersion.V0 ? CREATE_ACTIONS : CREATE_ACTIONS_V1;

  const [mutate, { data, error, loading }] = useMutation<
    CreateActionsMutation,
    CreateActionsMutationVariables
  >(CREATE_ACTIONS_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.created'),
          name: t('common:glossary.action'),
        })}`,
        {
          type: 'success',
        },
      );
    },
    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'created',
        name: 'action',
      });
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as CreateActionsMutationV0).createActions
        : (data as CreateActionsMutationV1).createActionsV1.results
      : undefined,
    error,
    loading,
    mutate,
  };
};
