import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { UPDATE_SCOPE, UPDATE_SCOPE_V1 } from 'src/queries/rest/scopes';
import { RbacVersion } from 'src/types/__generated__/types';
import { ScopeResponse } from 'src/types/rest/rbac.v1';

interface UpdateScopeMutationV0 {
  updateScope: {
    NoContent: null;
  };
}

interface UpdateScopeMutationV1 {
  updateScopeV1: ScopeResponse;
}

type UpdateScopeMutation = UpdateScopeMutationV0 | UpdateScopeMutationV1;

export interface UpdateScopeMutationVariables {
  input: {
    name: string;
  };
  scopeId: string;
  styraStackId: string;
}

interface UseUpdateScopeProps {
  onCompleted?: () => void;
  rbacVersion?: RbacVersion | null;
  t: TFunction<'common'[]>;
}

export const useUpdateScope = ({
  onCompleted,
  rbacVersion,
  t,
}: UseUpdateScopeProps) => {
  const UPDATE_MUTATION =
    rbacVersion === RbacVersion.V0 ? UPDATE_SCOPE : UPDATE_SCOPE_V1;

  const [mutate, { data, error, loading }] = useMutation<
    UpdateScopeMutation,
    UpdateScopeMutationVariables
  >(UPDATE_MUTATION, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.updated'),
          name: t('common:glossary.scope'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'updated',
        name: 'scope',
      });
    },
  });

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as UpdateScopeMutationV0).updateScope
        : (data as UpdateScopeMutationV1).updateScopeV1.result
      : undefined,
    error,
    loading,
    mutate,
  };
};
