import { useMutation } from '@apollo/client';

import {
  STACK_ONBOARDING,
  UPDATE_STACK_ONBOARDING_STEP,
} from 'src/queries/rest/onboarding';
import {
  SetOnboardingStep,
  StackID,
  StackOnboarding,
  StackOnboardingStep,
} from 'src/types/rest/onboarding';

interface UpdateStackOnboardingStepMutation {
  updateStackOnboarding: StackOnboarding;
}

interface UpdateStackOnboardingStepMutationVariables {
  id: StackID;
  input: SetOnboardingStep;
  step: StackOnboardingStep;
}

export const useUpdateStackOnboardingStep = () => {
  const [mutate, { data, error, loading }] = useMutation<
    UpdateStackOnboardingStepMutation,
    UpdateStackOnboardingStepMutationVariables
  >(UPDATE_STACK_ONBOARDING_STEP, {
    refetchQueries: [STACK_ONBOARDING],
  });

  return {
    data,
    error,
    loading,
    mutate,
  };
};
