import { useQuery } from '@apollo/client';

import { USER_TOKENS } from 'src/queries/rest/token';
import { TokensResponse } from 'src/types/rest/tokens';

interface UserTokensQuery {
  userTokens: TokensResponse;
}

export const useUserTokens = () => {
  const { data, error, loading } = useQuery<UserTokensQuery>(USER_TOKENS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return {
    data: data?.userTokens.tokens,
    error,
    loading,
  };
};
