import { InMemoryCache } from '@apollo/client';

import { INITIALIZE_LOCAL_STATE } from 'src/queries/ui';
import introspectionQueryResultData from 'src/types/__generated__/fragmentTypes';

export const cache: InMemoryCache = new InMemoryCache({
  /**
   * Add support for fragments on unions and interfaces:
   * @see {@link https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically}
   */
  possibleTypes: introspectionQueryResultData.possibleTypes,
  /**
   * Customize how the cache interacts with specific types:
   * @see {@link https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields}
   */
  typePolicies: {
    Resource: {
      keyFields: ['name'],
    },
    Stack: {
      // To evict object from cache easily.
      merge: true,
    },
    System: {
      merge: true,
      // To evict object from cache easily.
    },
    ZeissIDAgreementDatasourceConfiguration: {
      // To evict object from cache easily.
      merge: true,
    },
  },
});

export const writeInitialCacheData = () => {
  cache.writeQuery({
    data: {
      isDebug: false,
      isMobileNavOpen: false,
    },
    query: INITIALIZE_LOCAL_STATE,
  });
};
