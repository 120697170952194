import { useQuery } from '@apollo/client';

import { ROLE, ROLE_V1 } from 'src/queries/rest/roles';
import { RbacVersion } from 'src/types/__generated__/types';
import { Role } from 'src/types/rest/rbac';
import { RoleResponse } from 'src/types/rest/rbac.v1';

interface RoleQueryV0 {
  role: Role;
}

interface RoleQueryV1 {
  roleV1: RoleResponse;
}

type RoleQuery = RoleQueryV0 | RoleQueryV1;

interface RoleQueryVariables {
  roleId: string;
  styraStackId: string;
}

interface UseRoleProps {
  rbacVersion?: RbacVersion | null;
  roleId?: string;
  styraStackId?: string | null;
}

export const useRole = ({
  rbacVersion,
  roleId,
  styraStackId,
}: UseRoleProps) => {
  const ROLE_QUERY = rbacVersion === RbacVersion.V0 ? ROLE : ROLE_V1;

  const skip = !styraStackId || !roleId || !rbacVersion;
  const variables =
    styraStackId && roleId ? { roleId, styraStackId } : undefined;

  const { data, error, loading } = useQuery<RoleQuery, RoleQueryVariables>(
    ROLE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip,
      variables,
    },
  );

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as RoleQueryV0).role
        : (data as RoleQueryV1).roleV1.result
      : undefined,
    error,
    loading,
  };
};
