export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  Boolean: { input: boolean; output: boolean };
  DatasourceIdentifier: { input: number; output: number };
  EmailAddress: { input: string; output: string };
  Float: { input: number; output: number };
  ID: { input: string; output: string };
  Int: { input: number; output: number };
  RegexString: { input: any; output: any };
  StackIdentifier: { input: number; output: number };
  StackName: { input: string; output: string };
  String: { input: string; output: string };
  SystemIdentifier: { input: number; output: number };
  SystemName: { input: string; output: string };
  ZeissIDIdentifier: { input: number; output: number };
  _Any: { input: any; output: any };
  _FieldSet: { input: any; output: any };
}

export interface CreatedStack {
  __typename: 'CreatedStack';
  rbacAPIKey?: Maybe<Scalars['String']['output']>;
  stack: Stack;
}

export type DatasourceConfiguration = ZeissIdAgreementDatasourceConfiguration;

export interface DatasourceConfigurationInput {
  zeissIDAgreements?: InputMaybe<ZeissIdAgreementDatasourceConfigurationInput>;
}

export interface DatasourceCreationResult {
  __typename: 'DatasourceCreationResult';
  created?: Maybe<DatasourceConfiguration>;
}

export interface DatasourceDeletionResult {
  __typename: 'DatasourceDeletionResult';
  id: Scalars['DatasourceIdentifier']['output'];
}

export enum DatasourceStatus {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Orphaned = 'ORPHANED',
  Running = 'RUNNING',
}

export enum ErrorCodes {
  Conflict = 'CONFLICT',
  Forbidden = 'FORBIDDEN',
  InsufficientAdministratorCount = 'INSUFFICIENT_ADMINISTRATOR_COUNT',
  InvalidAdministrator = 'INVALID_ADMINISTRATOR',
  InvalidConfigurationDatasource = 'INVALID_CONFIGURATION_DATASOURCE',
  InvalidEmailFormat = 'INVALID_EMAIL_FORMAT',
  InvalidStackName = 'INVALID_STACK_NAME',
  InvalidSystemName = 'INVALID_SYSTEM_NAME',
  Unauthenticated = 'UNAUTHENTICATED',
  UnknownDatasource = 'UNKNOWN_DATASOURCE',
  UnknownSystem = 'UNKNOWN_SYSTEM',
  UnknownUserEmail = 'UNKNOWN_USER_EMAIL',
}

export interface LinkedStack {
  __typename: 'LinkedStack';
  linkedAt: Scalars['String']['output'];
  stack: Stack;
}

export interface LinkedSystem {
  __typename: 'LinkedSystem';
  linkedAt: Scalars['String']['output'];
  system: System;
}

export interface Mutation {
  __typename: 'Mutation';
  createDatasourceConfiguration: DatasourceCreationResult;
  createStack: StackCreationResult;
  createSystem: System;
  deleteDatasource: DatasourceDeletionResult;
  deleteStackById: StackDeletionResult;
  deleteSystemById: SystemDeletionResult;
  updateDatasourceConfiguration: DatasourceCreationResult;
  updateStackById: StackUpdateResult;
  updateSystemById: SystemUpdateResult;
}

export interface MutationCreateDatasourceConfigurationArgs {
  input: DatasourceConfigurationInput;
  stackId: Scalars['StackIdentifier']['input'];
}

export interface MutationCreateStackArgs {
  input: StackCreationInput;
}

export interface MutationCreateSystemArgs {
  input: SystemCreationInput;
}

export interface MutationDeleteDatasourceArgs {
  id: Scalars['DatasourceIdentifier']['input'];
}

export interface MutationDeleteStackByIdArgs {
  id: Scalars['StackIdentifier']['input'];
}

export interface MutationDeleteSystemByIdArgs {
  id: Scalars['SystemIdentifier']['input'];
}

export interface MutationUpdateDatasourceConfigurationArgs {
  id: Scalars['DatasourceIdentifier']['input'];
  input: DatasourceConfigurationInput;
}

export interface MutationUpdateStackByIdArgs {
  id: Scalars['StackIdentifier']['input'];
  input: StackUpdateInput;
}

export interface MutationUpdateSystemByIdArgs {
  id: Scalars['SystemIdentifier']['input'];
  input: SystemUpdateInput;
}

export interface Query {
  __typename: 'Query';
  _service: _Service;
  isDebug: Scalars['Boolean']['output'];
  isMobileNavOpen: Scalars['Boolean']['output'];
  stackById?: Maybe<Stack>;
  systemById?: Maybe<System>;
  userByEmail?: Maybe<ZeissIdUser>;
  userStacks: Stack[];
  userSystems: System[];
  validateRegex: RegexValidationResult;
}

export interface QueryStackByIdArgs {
  id: Scalars['StackIdentifier']['input'];
}

export interface QuerySystemByIdArgs {
  id: Scalars['SystemIdentifier']['input'];
}

export interface QueryUserByEmailArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface QueryValidateRegexArgs {
  regexString: Scalars['RegexString']['input'];
}

export enum RbacVersion {
  V0 = 'v0',
  V1 = 'v1',
}

export interface RegexValidationResult {
  __typename: 'RegexValidationResult';
  error?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
}

export interface Stack {
  __typename: 'Stack';
  administrators: ZeissIdUser[];
  author: ZeissIdUser;
  datasources: DatasourceConfiguration[];
  id: Scalars['StackIdentifier']['output'];
  /** The systems linked to this Stack */
  linkedSystems: LinkedSystem[];
  name: Scalars['StackName']['output'];
  rbacEnabled: Scalars['Boolean']['output'];
  rbacVersion?: Maybe<RbacVersion>;
  styraStackId: Scalars['ID']['output'];
}

export interface StackCreationInput {
  administratorIds?: InputMaybe<Scalars['ZeissIDIdentifier']['input'][]>;
  datasources?: InputMaybe<DatasourceConfigurationInput[]>;
  linkedSystems?: InputMaybe<Scalars['SystemIdentifier']['input'][]>;
  name: Scalars['StackName']['input'];
  rbacEnabled: Scalars['Boolean']['input'];
  rbacVersion?: InputMaybe<RbacVersion>;
}

export interface StackCreationResult {
  __typename: 'StackCreationResult';
  created?: Maybe<CreatedStack>;
}

export interface StackDeletionResult {
  __typename: 'StackDeletionResult';
  id: Scalars['StackIdentifier']['output'];
}

export interface StackUpdateInput {
  administratorIds?: InputMaybe<Scalars['ZeissIDIdentifier']['input'][]>;
  linkedSystems?: InputMaybe<Scalars['SystemIdentifier']['input'][]>;
  name?: InputMaybe<Scalars['StackName']['input']>;
  rbacEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  rbacVersion?: InputMaybe<RbacVersion>;
}

export interface StackUpdateResult {
  __typename: 'StackUpdateResult';
  rbacAPIKey?: Maybe<Scalars['String']['output']>;
  stack?: Maybe<Stack>;
}

export interface System {
  __typename: 'System';
  administrators: ZeissIdUser[];
  author: ZeissIdUser;
  createdAt: Scalars['String']['output'];
  id: Scalars['SystemIdentifier']['output'];
  /** The stacks linked to this system */
  linkedStacks: LinkedStack[];
  name: Scalars['SystemName']['output'];
  styraSystemId?: Maybe<Scalars['ID']['output']>;
  zeissIdApplicationIdentifier?: Maybe<ZeissApplicationIdentifier>;
}

export interface SystemCreationInput {
  administratorIds?: InputMaybe<Scalars['ZeissIDIdentifier']['input'][]>;
  name: Scalars['SystemName']['input'];
  zeissIdApplicationIdentifier?: InputMaybe<ZeissApplicationIdentifierInput>;
}

export interface SystemDeletionResult {
  __typename: 'SystemDeletionResult';
  id: Scalars['SystemIdentifier']['output'];
}

export interface SystemUpdateInput {
  administratorIds?: InputMaybe<Scalars['ZeissIDIdentifier']['input'][]>;
  name?: InputMaybe<Scalars['SystemName']['input']>;
}

export interface SystemUpdateResult {
  __typename: 'SystemUpdateResult';
  system?: Maybe<System>;
}

export interface ZeissApplicationIdentifier {
  __typename: 'ZeissApplicationIdentifier';
  id: Scalars['ZeissIDIdentifier']['output'];
  zeissIdClientApplicationId: Scalars['ZeissIDIdentifier']['output'];
  zeissIdEnvironmentId: ZeissIdEnvironment;
}

export interface ZeissApplicationIdentifierInput {
  zeissIdClientApplicationId: Scalars['ZeissIDIdentifier']['input'];
  zeissIdEnvironmentId: ZeissIdEnvironment;
}

export interface ZeissIdAgreementDatasourceConfiguration {
  __typename: 'ZeissIDAgreementDatasourceConfiguration';
  agreementTemplateIDs: Scalars['ZeissIDIdentifier']['output'][];
  environment: ZeissIdEnvironment;
  finishedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['DatasourceIdentifier']['output'];
  importAcceptedTermsAndConditions: Scalars['Boolean']['output'];
  importAccountCustomFields: Scalars['Boolean']['output'];
  importClientApplications: Scalars['Boolean']['output'];
  startedAt?: Maybe<Scalars['String']['output']>;
  status: DatasourceStatus;
}

export interface ZeissIdAgreementDatasourceConfigurationInput {
  agreementTemplateIDs: Scalars['ZeissIDIdentifier']['input'][];
  environment: ZeissIdEnvironment;
  importAcceptedTermsAndConditions: Scalars['Boolean']['input'];
  importAccountCustomFields: Scalars['Boolean']['input'];
  importClientApplications: Scalars['Boolean']['input'];
}

export enum ZeissIdEnvironment {
  Prod = 'PROD',
  Stage = 'STAGE',
  Test = 'TEST',
}

export interface ZeissIdUser {
  __typename: 'ZeissIDUser';
  email: Scalars['String']['output'];
  id: Scalars['ZeissIDIdentifier']['output'];
}

export interface _Service {
  __typename: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
}

export interface DataSourceFieldsFragment {
  __typename: 'ZeissIDAgreementDatasourceConfiguration';
  agreementTemplateIDs: number[];
  environment: ZeissIdEnvironment;
  finishedAt?: string | null;
  id: number;
  importAcceptedTermsAndConditions: boolean;
  importAccountCustomFields: boolean;
  importClientApplications: boolean;
  startedAt?: string | null;
  status: DatasourceStatus;
}

export type AddDataSourceMutationVariables = Exact<{
  input: DatasourceConfigurationInput;
  stackId: Scalars['StackIdentifier']['input'];
}>;

export interface AddDataSourceMutation {
  __typename: 'Mutation';
  createDatasourceConfiguration: {
    __typename: 'DatasourceCreationResult';
    created?: {
      __typename: 'ZeissIDAgreementDatasourceConfiguration';
      agreementTemplateIDs: number[];
      environment: ZeissIdEnvironment;
      finishedAt?: string | null;
      id: number;
      importAcceptedTermsAndConditions: boolean;
      importAccountCustomFields: boolean;
      importClientApplications: boolean;
      startedAt?: string | null;
      status: DatasourceStatus;
    } | null;
  };
}

export type RemoveDataSourceMutationVariables = Exact<{
  id: Scalars['DatasourceIdentifier']['input'];
}>;

export interface RemoveDataSourceMutation {
  __typename: 'Mutation';
  deleteDatasource: { __typename: 'DatasourceDeletionResult'; id: number };
}

export type UpdateDataSourceConfigurationMutationVariables = Exact<{
  id: Scalars['DatasourceIdentifier']['input'];
  input: DatasourceConfigurationInput;
}>;

export interface UpdateDataSourceConfigurationMutation {
  __typename: 'Mutation';
  updateDatasourceConfiguration: {
    __typename: 'DatasourceCreationResult';
    created?: {
      __typename: 'ZeissIDAgreementDatasourceConfiguration';
      agreementTemplateIDs: number[];
      environment: ZeissIdEnvironment;
      finishedAt?: string | null;
      id: number;
      importAcceptedTermsAndConditions: boolean;
      importAccountCustomFields: boolean;
      importClientApplications: boolean;
      startedAt?: string | null;
      status: DatasourceStatus;
    } | null;
  };
}

export interface StackFieldsFragment {
  __typename: 'Stack';
  administrators: {
    __typename: 'ZeissIDUser';
    email: string;
    id: number;
  }[];
  author: { __typename: 'ZeissIDUser'; email: string; id: number };
  datasources: {
    __typename: 'ZeissIDAgreementDatasourceConfiguration';
    agreementTemplateIDs: number[];
    environment: ZeissIdEnvironment;
    finishedAt?: string | null;
    id: number;
    importAcceptedTermsAndConditions: boolean;
    importAccountCustomFields: boolean;
    importClientApplications: boolean;
    startedAt?: string | null;
    status: DatasourceStatus;
  }[];
  id: number;
  linkedSystems: {
    __typename: 'LinkedSystem';
    linkedAt: string;
    system: {
      __typename: 'System';
      createdAt: string;
      id: number;
      name: string;
      zeissIdApplicationIdentifier?: {
        __typename: 'ZeissApplicationIdentifier';
        id: number;
        zeissIdClientApplicationId: number;
        zeissIdEnvironmentId: ZeissIdEnvironment;
      } | null;
    };
  }[];
  name: string;
  rbacEnabled: boolean;
  rbacVersion?: RbacVersion | null;
  styraStackId: string;
}

export type StackQueryVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
}>;

export interface StackQuery {
  __typename: 'Query';
  stackById?: {
    __typename: 'Stack';
    administrators: {
      __typename: 'ZeissIDUser';
      email: string;
      id: number;
    }[];
    author: { __typename: 'ZeissIDUser'; email: string; id: number };
    datasources: {
      __typename: 'ZeissIDAgreementDatasourceConfiguration';
      agreementTemplateIDs: number[];
      environment: ZeissIdEnvironment;
      finishedAt?: string | null;
      id: number;
      importAcceptedTermsAndConditions: boolean;
      importAccountCustomFields: boolean;
      importClientApplications: boolean;
      startedAt?: string | null;
      status: DatasourceStatus;
    }[];
    id: number;
    linkedSystems: {
      __typename: 'LinkedSystem';
      linkedAt: string;
      system: {
        __typename: 'System';
        createdAt: string;
        id: number;
        name: string;
        zeissIdApplicationIdentifier?: {
          __typename: 'ZeissApplicationIdentifier';
          id: number;
          zeissIdClientApplicationId: number;
          zeissIdEnvironmentId: ZeissIdEnvironment;
        } | null;
      };
    }[];
    name: string;
    rbacEnabled: boolean;
    rbacVersion?: RbacVersion | null;
    styraStackId: string;
  } | null;
}

export type StackNameQueryVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
}>;

export interface StackNameQuery {
  __typename: 'Query';
  stackById?: { __typename: 'Stack'; id: number; name: string } | null;
}

export type StackRbacEnabledQueryVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
}>;

export interface StackRbacEnabledQuery {
  __typename: 'Query';
  stackById?: {
    __typename: 'Stack';
    id: number;
    rbacEnabled: boolean;
    rbacVersion?: RbacVersion | null;
  } | null;
}

export type StackRbacMetadataQueryVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
}>;

export interface StackRbacMetadataQuery {
  __typename: 'Query';
  stackById?: {
    __typename: 'Stack';
    id: number;
    rbacEnabled: boolean;
    rbacVersion?: RbacVersion | null;
    styraStackId: string;
  } | null;
}

export type StackStyraIdQueryVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
}>;

export interface StackStyraIdQuery {
  __typename: 'Query';
  stackById?: { __typename: 'Stack'; id: number; styraStackId: string } | null;
}

export type UserStacksQueryVariables = Exact<Record<string, never>>;

export interface UserStacksQuery {
  __typename: 'Query';
  userStacks: {
    __typename: 'Stack';
    administrators: {
      __typename: 'ZeissIDUser';
      email: string;
      id: number;
    }[];
    author: { __typename: 'ZeissIDUser'; email: string; id: number };
    datasources: {
      __typename: 'ZeissIDAgreementDatasourceConfiguration';
      agreementTemplateIDs: number[];
      environment: ZeissIdEnvironment;
      finishedAt?: string | null;
      id: number;
      importAcceptedTermsAndConditions: boolean;
      importAccountCustomFields: boolean;
      importClientApplications: boolean;
      startedAt?: string | null;
      status: DatasourceStatus;
    }[];
    id: number;
    linkedSystems: {
      __typename: 'LinkedSystem';
      linkedAt: string;
      system: {
        __typename: 'System';
        createdAt: string;
        id: number;
        name: string;
        zeissIdApplicationIdentifier?: {
          __typename: 'ZeissApplicationIdentifier';
          id: number;
          zeissIdClientApplicationId: number;
          zeissIdEnvironmentId: ZeissIdEnvironment;
        } | null;
      };
    }[];
    name: string;
    rbacEnabled: boolean;
    rbacVersion?: RbacVersion | null;
    styraStackId: string;
  }[];
}

export type UserStacksAndSystemsQueryVariables = Exact<Record<string, never>>;

export interface UserStacksAndSystemsQuery {
  __typename: 'Query';
  userStacks: {
    __typename: 'Stack';
    id: number;
    name: string;
    rbacEnabled: boolean;
    rbacVersion?: RbacVersion | null;
    styraId: string;
  }[];
  userSystems: {
    __typename: 'System';
    id: number;
    name: string;
    styraId?: string | null;
  }[];
}

export type CreateStackMutationVariables = Exact<{
  input: StackCreationInput;
}>;

export interface CreateStackMutation {
  __typename: 'Mutation';
  createStack: {
    __typename: 'StackCreationResult';
    created?: {
      __typename: 'CreatedStack';
      rbacAPIKey?: string | null;
      stack: {
        __typename: 'Stack';
        administrators: {
          __typename: 'ZeissIDUser';
          email: string;
          id: number;
        }[];
        author: { __typename: 'ZeissIDUser'; email: string; id: number };
        datasources: {
          __typename: 'ZeissIDAgreementDatasourceConfiguration';
          agreementTemplateIDs: number[];
          environment: ZeissIdEnvironment;
          finishedAt?: string | null;
          id: number;
          importAcceptedTermsAndConditions: boolean;
          importAccountCustomFields: boolean;
          importClientApplications: boolean;
          startedAt?: string | null;
          status: DatasourceStatus;
        }[];
        id: number;
        linkedSystems: {
          __typename: 'LinkedSystem';
          linkedAt: string;
          system: {
            __typename: 'System';
            createdAt: string;
            id: number;
            name: string;
            zeissIdApplicationIdentifier?: {
              __typename: 'ZeissApplicationIdentifier';
              id: number;
              zeissIdClientApplicationId: number;
              zeissIdEnvironmentId: ZeissIdEnvironment;
            } | null;
          };
        }[];
        name: string;
        rbacEnabled: boolean;
        rbacVersion?: RbacVersion | null;
        styraStackId: string;
      };
    } | null;
  };
}

export type UpdateStackMutationVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
  input: StackUpdateInput;
}>;

export interface UpdateStackMutation {
  __typename: 'Mutation';
  updateStackById: {
    __typename: 'StackUpdateResult';
    rbacAPIKey?: string | null;
    stack?: {
      __typename: 'Stack';
      administrators: {
        __typename: 'ZeissIDUser';
        email: string;
        id: number;
      }[];
      author: { __typename: 'ZeissIDUser'; email: string; id: number };
      datasources: {
        __typename: 'ZeissIDAgreementDatasourceConfiguration';
        agreementTemplateIDs: number[];
        environment: ZeissIdEnvironment;
        finishedAt?: string | null;
        id: number;
        importAcceptedTermsAndConditions: boolean;
        importAccountCustomFields: boolean;
        importClientApplications: boolean;
        startedAt?: string | null;
        status: DatasourceStatus;
      }[];
      id: number;
      linkedSystems: {
        __typename: 'LinkedSystem';
        linkedAt: string;
        system: {
          __typename: 'System';
          createdAt: string;
          id: number;
          name: string;
          zeissIdApplicationIdentifier?: {
            __typename: 'ZeissApplicationIdentifier';
            id: number;
            zeissIdClientApplicationId: number;
            zeissIdEnvironmentId: ZeissIdEnvironment;
          } | null;
        };
      }[];
      name: string;
      rbacEnabled: boolean;
      rbacVersion?: RbacVersion | null;
      styraStackId: string;
    } | null;
  };
}

export type DeleteStackMutationVariables = Exact<{
  id: Scalars['StackIdentifier']['input'];
}>;

export interface DeleteStackMutation {
  __typename: 'Mutation';
  deleteStackById: { __typename: 'StackDeletionResult'; id: number };
}

export interface SystemFieldsFragment {
  __typename: 'System';
  administrators: {
    __typename: 'ZeissIDUser';
    email: string;
    id: number;
  }[];
  author: { __typename: 'ZeissIDUser'; id: number };
  createdAt: string;
  id: number;
  linkedStacks: {
    __typename: 'LinkedStack';
    linkedAt: string;
    stack: { __typename: 'Stack'; id: number; name: string };
  }[];
  name: string;
  styraSystemId?: string | null;
  zeissIdApplicationIdentifier?: {
    __typename: 'ZeissApplicationIdentifier';
    id: number;
    zeissIdClientApplicationId: number;
    zeissIdEnvironmentId: ZeissIdEnvironment;
  } | null;
}

export type UserSystemsQueryVariables = Exact<Record<string, never>>;

export interface UserSystemsQuery {
  __typename: 'Query';
  userSystems: {
    __typename: 'System';
    administrators: {
      __typename: 'ZeissIDUser';
      email: string;
      id: number;
    }[];
    author: { __typename: 'ZeissIDUser'; id: number };
    createdAt: string;
    id: number;
    linkedStacks: {
      __typename: 'LinkedStack';
      linkedAt: string;
      stack: { __typename: 'Stack'; id: number; name: string };
    }[];
    name: string;
    styraSystemId?: string | null;
    zeissIdApplicationIdentifier?: {
      __typename: 'ZeissApplicationIdentifier';
      id: number;
      zeissIdClientApplicationId: number;
      zeissIdEnvironmentId: ZeissIdEnvironment;
    } | null;
  }[];
}

export type SystemQueryVariables = Exact<{
  id: Scalars['SystemIdentifier']['input'];
}>;

export interface SystemQuery {
  __typename: 'Query';
  systemById?: {
    __typename: 'System';
    administrators: {
      __typename: 'ZeissIDUser';
      email: string;
      id: number;
    }[];
    author: { __typename: 'ZeissIDUser'; id: number };
    createdAt: string;
    id: number;
    linkedStacks: {
      __typename: 'LinkedStack';
      linkedAt: string;
      stack: { __typename: 'Stack'; id: number; name: string };
    }[];
    name: string;
    styraSystemId?: string | null;
    zeissIdApplicationIdentifier?: {
      __typename: 'ZeissApplicationIdentifier';
      id: number;
      zeissIdClientApplicationId: number;
      zeissIdEnvironmentId: ZeissIdEnvironment;
    } | null;
  } | null;
}

export type SystemNameQueryVariables = Exact<{
  id: Scalars['SystemIdentifier']['input'];
}>;

export interface SystemNameQuery {
  __typename: 'Query';
  systemById?: { __typename: 'System'; id: number; name: string } | null;
}

export type CreateSystemMutationVariables = Exact<{
  input: SystemCreationInput;
}>;

export interface CreateSystemMutation {
  __typename: 'Mutation';
  createSystem: {
    __typename: 'System';
    administrators: {
      __typename: 'ZeissIDUser';
      email: string;
      id: number;
    }[];
    author: { __typename: 'ZeissIDUser'; id: number };
    createdAt: string;
    id: number;
    linkedStacks: {
      __typename: 'LinkedStack';
      linkedAt: string;
      stack: { __typename: 'Stack'; id: number; name: string };
    }[];
    name: string;
    styraSystemId?: string | null;
    zeissIdApplicationIdentifier?: {
      __typename: 'ZeissApplicationIdentifier';
      id: number;
      zeissIdClientApplicationId: number;
      zeissIdEnvironmentId: ZeissIdEnvironment;
    } | null;
  };
}

export type UpdateSystemMutationVariables = Exact<{
  id: Scalars['SystemIdentifier']['input'];
  input: SystemUpdateInput;
}>;

export interface UpdateSystemMutation {
  __typename: 'Mutation';
  updateSystemById: {
    __typename: 'SystemUpdateResult';
    system?: {
      __typename: 'System';
      administrators: {
        __typename: 'ZeissIDUser';
        email: string;
        id: number;
      }[];
      author: { __typename: 'ZeissIDUser'; id: number };
      createdAt: string;
      id: number;
      linkedStacks: {
        __typename: 'LinkedStack';
        linkedAt: string;
        stack: { __typename: 'Stack'; id: number; name: string };
      }[];
      name: string;
      styraSystemId?: string | null;
      zeissIdApplicationIdentifier?: {
        __typename: 'ZeissApplicationIdentifier';
        id: number;
        zeissIdClientApplicationId: number;
        zeissIdEnvironmentId: ZeissIdEnvironment;
      } | null;
    } | null;
  };
}

export type DeleteSystemMutationVariables = Exact<{
  id: Scalars['SystemIdentifier']['input'];
}>;

export interface DeleteSystemMutation {
  __typename: 'Mutation';
  deleteSystemById: { __typename: 'SystemDeletionResult'; id: number };
}

export type IsMobileNavOpenQueryVariables = Exact<Record<string, never>>;

export interface IsMobileNavOpenQuery {
  __typename: 'Query';
  isMobileNavOpen: boolean;
}

export type IsDebugQueryVariables = Exact<Record<string, never>>;

export interface IsDebugQuery {
  __typename: 'Query';
  isDebug: boolean;
}

export type InitializeLocalStateQueryVariables = Exact<Record<string, never>>;

export interface InitializeLocalStateQuery {
  __typename: 'Query';
  isDebug: boolean;
  isMobileNavOpen: boolean;
}

export type UserEmailQueryVariables = Exact<{
  email: Scalars['EmailAddress']['input'];
}>;

export interface UserEmailQuery {
  __typename: 'Query';
  userByEmail?: { __typename: 'ZeissIDUser'; email: string; id: number } | null;
}

export type ValidateRegexQueryVariables = Exact<{
  regexString: Scalars['RegexString']['input'];
}>;

export interface ValidateRegexQuery {
  __typename: 'Query';
  validateRegex: { __typename: 'RegexValidationResult'; isValid: boolean };
}
