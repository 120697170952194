import { useMutation } from '@apollo/client';
import { TFunction } from 'i18next';

import { showErrorNotification, showNotification } from 'src/helpers/utils';
import { DELETE_RESOURCE } from 'src/queries/rest/resources';

interface DeleteResourceMutation {
  deleteResource: {
    NoContent: null;
  };
}

export interface DeleteResourceMutationVariables {
  resourceName: string;
  styraStackId: string;
}

interface UseDeleteResourceProps extends DeleteResourceMutationVariables {
  onCompleted?: () => void;
  t: TFunction<'common'[]>;
}

export const useDeleteResource = ({
  onCompleted,
  resourceName,
  styraStackId,
  t,
}: UseDeleteResourceProps) => {
  const [mutate, { data, error, loading, reset }] = useMutation<
    DeleteResourceMutation,
    DeleteResourceMutationVariables
  >(DELETE_RESOURCE, {
    onCompleted: () => {
      onCompleted && onCompleted();

      showNotification(
        `${t('common:notification.successX', {
          action: t('common:action.deleted'),
          name: t('common:glossary.resource'),
        })}`,
        {
          type: 'success',
        },
      );
    },

    onError: ({ graphQLErrors }) => {
      showErrorNotification(graphQLErrors, t, {
        action: 'deleted',
        name: 'resource',
      });
    },

    update: (cache) => {
      const id = `Resource:${JSON.stringify({
        name: resourceName,
      })}`;

      cache.evict({ id });
      cache.gc();
    },

    variables: {
      resourceName: encodeURIComponent(resourceName),
      styraStackId,
    },
  });

  return {
    data: data?.deleteResource,
    error,
    loading,
    mutate,
    reset,
  };
};
