import { useState } from 'react';

import { DEFAULT_FILTER, minCharsForSearch } from 'src/helpers/constants';
import { searchItems } from 'src/helpers/utils';
import { KeyPath } from 'src/types';
import { Scope } from 'src/types/rest/rbac';
import { Scope as ScopeV1 } from 'src/types/rest/rbac.v1';

interface RbacEntityData {
  scope?: Scope | ScopeV1 | null;
  scope_name?: string;
}

interface UseRbacSearchAndFilterProps<T> {
  /**
   * Data to filter and search.
   */
  data: T[];
  /**
   * Scope filter to apply.
   */
  scopeFilter: string;
  /**
   * List of {@link SearchItemProps}(s) in T to search in.
   */
  searchParams: KeyPath<T>[];
}

/**
 * Supports:
 * - `ExtendedRoleBinding`
 * - `Role`
 */
export const useRbacSearchAndFilter = <T extends RbacEntityData>({
  data,
  scopeFilter,
  searchParams,
}: UseRbacSearchAndFilterProps<T>) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData =
    scopeFilter !== DEFAULT_FILTER
      ? data.filter((data) => {
          if (data.scope) {
            return data.scope?.id === scopeFilter;
          }
          if (data.scope_name) {
            return data.scope_name === scopeFilter;
          }
        })
      : data;

  const filteredAndSearchedData =
    searchQuery.length >= minCharsForSearch
      ? searchItems({
          items: filteredData,
          params: searchParams,
          query: searchQuery,
        })
      : filteredData;

  return {
    data: filteredAndSearchedData,
    setSearchQuery,
  };
};
