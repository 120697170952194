import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'src/components/styled/Button';
import {
  Section,
  SectionContentElement,
  SectionHeader,
  SectionHeaderTitle,
  SectionText,
} from 'src/components/styled/Section';
import { XYCenter } from 'src/components/styled/shared';
import history from 'src/router/history';

// TODO: Add proper design and support channel flow
export const FatalErrorFallback: React.FC = () => {
  const { t } = useTranslation(['common']);

  const onReload = () => history.go(0);

  return (
    <XYCenter>
      <Section>
        <SectionHeader distance="large">
          <SectionHeaderTitle level={1}>
            {t('common:error.error')}
          </SectionHeaderTitle>
        </SectionHeader>

        <SectionText>{t('common:error.textFatalError')}</SectionText>

        <SectionContentElement>
          <Button onClick={onReload}>
            {t('common:action.reloadX', {
              name: t('common:glossary.system'),
            })}
          </Button>
          <Button variant="secondary">
            {t('common:action.contactX', { name: t('common:support') })}
          </Button>
        </SectionContentElement>
      </Section>
    </XYCenter>
  );
};
