import { useQuery } from '@apollo/client';

import { STACK_SCOPES, STACK_SCOPES_V1 } from 'src/queries/rest/scopes';
import { RbacVersion } from 'src/types/__generated__/types';
import {
  ScopesQuery,
  ScopesQueryV0,
  ScopesQueryV1,
  ScopesQueryVariables,
} from './types';

interface UseStackScopesProps {
  rbacVersion?: RbacVersion | null;
  styraStackId?: string;
}

export const useStackScopes = ({
  rbacVersion,
  styraStackId,
}: UseStackScopesProps) => {
  const skip = !styraStackId || !rbacVersion;
  const variables = styraStackId ? { styraStackId } : undefined;

  const STACK_SCOPES_QUERY =
    rbacVersion === RbacVersion.V0 ? STACK_SCOPES : STACK_SCOPES_V1;

  const { data, error, loading } = useQuery<ScopesQuery, ScopesQueryVariables>(
    STACK_SCOPES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip,
      variables,
    },
  );

  return {
    data: data
      ? rbacVersion === RbacVersion.V0
        ? (data as ScopesQueryV0).stackScopes
        : (data as ScopesQueryV1).stackScopesV1.results
      : undefined,
    error,
    loading,
  };
};
