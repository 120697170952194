import { AnchorProps } from '@zeiss/pharos';
import { useLocation } from 'react-router-dom';

import { basename } from 'src/helpers/constants';

export const createURI = (routePathname: string, to: AnchorProps['to']) => {
  const trailingSlash =
    routePathname.charAt(routePathname.length - 1) === '/' ? '' : '/';

  if (typeof to === 'string') {
    // Support absolute and relative links
    if (to.charAt(0) !== '/')
      return `${basename}${routePathname}${trailingSlash}${to}`;
    return `${basename}${to}`;
  }

  if (typeof to === 'object' && to.pathname) {
    return {
      ...to,
      ...{
        pathname:
          to.pathname.charAt(0) !== '/'
            ? `${basename}${routePathname}${trailingSlash}${to.pathname}`
            : `${basename}${to.pathname}`,
      },
    };
  }

  return;
};

/**
 * Append `basename`
 *
 * `history` v5 does not support setting a `basename` anymore. As we use the
 * `HistoryRouter` to make the `history` also available outside `react-router`
 * (to Precise UI), we need to append the `basename` in all `Anchor` components.
 * @see {@link https://github.com/remix-run/history/issues/810}
 *
 * TODO:
 * - Remove once Precise UI is upgraded to support `react-router` v6.
 */
export const useAppendBasename = (to: AnchorProps['to']): AnchorProps['to'] => {
  const { pathname } = useLocation();

  const res = createURI(pathname, to);
  return res;
};
