import {
  // eslint-disable-next-line no-restricted-imports
  Button as OriginalButton,
  // eslint-disable-next-line no-restricted-imports
  ButtonProps as OriginalButtonProps,
  ButtonStyle,
  StandardProps,
  displayFrom,
  themed,
} from '@zeiss/pharos';
import * as React from 'react';
import styled, { css } from 'styled-components/macro';

import { useAppendBasename } from 'src/hooks';
import { StyleVariants } from 'src/types';

type ComponentStyleVariants = Extract<
  StyleVariants,
  | 'danger'
  | 'dangerSecondary'
  | 'primary'
  | 'secondary'
  | 'transparentSecondary'
>;

type ButtonWidths = '100%' | 'auto' | 'min-content';

interface ButtonStyleProps {
  mobileWidthStyle: ButtonWidths;
  variant: ComponentStyleVariants;
  widthStyle: ButtonWidths;
}

/**
 * Fixes bleed-through of non-standard props
 * @see {@link https://github.com/styled-components/styled-components/issues/1198#issuecomment-425650423}
 */
const StyledButton = styled(
  ({ mobileWidthStyle, variant, widthStyle, ...props }) => (
    <OriginalButton {...props} />
  ),
)<ButtonStyleProps>`
  width: ${({ mobileWidthStyle }) => mobileWidthStyle};
  margin-top: 0;
  margin-bottom: 0;

  ${({ variant }) => {
    if (variant === 'danger') {
      return css`
        background-color: ${themed(
          ({ theme = {} }: StandardProps) => theme.notificationColorError,
        )};

        &:hover,
        &:focus,
        &:active {
          background-color: #96023a;
        }
      `;
    }

    if (variant === 'dangerSecondary') {
      return css`
        color: ${themed(
          ({ theme = {} }: StandardProps) => theme.notificationColorError,
        )};
        border-color: ${themed(
          ({ theme = {} }: StandardProps) => theme.notificationColorError,
        )};

        &:hover,
        &:focus,
        &:active {
          color: #96023a;
          border-color: #96023a;
        }
      `;
    }

    if (variant === 'transparentSecondary') {
      return css`
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      `;
    }
  }}

  ${displayFrom('medium')<ButtonStyleProps>`
    width: ${({ widthStyle }) => widthStyle};
  `}
`;

export interface ButtonProps extends OriginalButtonProps {
  /**
   * @deprecated in AuthZ, use `variant` instead.
   */
  buttonStyle?: ButtonStyle;
  /**
   * Custom button width on mobile breakpoint.
   * @default min-content
   */
  mobileWidthStyle?: ButtonWidths;
  /**
   * Style variant.
   * @default primary
   */
  variant?: ComponentStyleVariants;
  /**
   * Custom button width on non-mobile breakpoints.
   * @default auto
   */
  widthStyle?: ButtonWidths;
}

/**
 * Button
 *
 * Based on `Precise UI` button, but with some opinionated tweaks, and
 * support for `history` v5.
 *
 * If multiple buttons are placed alongside each other wrap them with
 * `Group` to control their distance to one another.
 */
export const Button: React.FC<ButtonProps> = ({
  children,
  mobileWidthStyle = 'min-content',
  to,
  variant = 'primary',
  widthStyle = 'auto',
  ...rest
}) => {
  const resTo = useAppendBasename(to);

  // Precise UI uses the `buttonStyle` prop instead of `variant`
  const buttonStyle =
    variant === 'secondary' ||
    variant === 'dangerSecondary' ||
    variant === 'transparentSecondary'
      ? 'secondary'
      : 'primary';

  return (
    <StyledButton
      buttonStyle={buttonStyle}
      mobileWidthStyle={mobileWidthStyle}
      to={resTo}
      variant={variant}
      widthStyle={widthStyle}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
