import { useQuery } from '@apollo/client';

import { STACK_ONBOARDING } from 'src/queries/rest/onboarding';
import { ForceSkip } from 'src/types';
import {
  StackID,
  StackOnboarding,
  StackOnboardingStep,
} from 'src/types/rest/onboarding';

// Order matters
const stepOrder: readonly StackOnboardingStep[] = Object.freeze([
  'systems',
  'account',
]);

interface StackOnboardingQuery {
  stackOnboarding: StackOnboarding;
}

interface StackOnboardingQueryVariables {
  id: StackID;
}

interface UseStackOnboardingProps extends ForceSkip {
  id?: string;
}

export const useStackOnboarding = ({
  forceSkip,
  id,
}: UseStackOnboardingProps) => {
  const skip = forceSkip || !id;
  const variables = id ? { id } : undefined;

  const { data, error, loading } = useQuery<
    StackOnboardingQuery,
    StackOnboardingQueryVariables
  >(STACK_ONBOARDING, {
    skip,
    variables,
  });

  const { steps } = data?.stackOnboarding ?? {};

  const remainingSteps = steps
    ? Object.entries(steps)
        .map(([key, rest]) => ({
          name: key as StackOnboardingStep,
          ...rest,
        }))
        .filter(({ done }) => !done)
        .sort(
          (a, b) =>
            (stepOrder as string[]).indexOf(a.name) -
            (stepOrder as string[]).indexOf(b.name),
        )
    : [];

  return {
    data: {
      ...data?.stackOnboarding,
      remainingSteps,
    },
    error,
    loading,
  };
};
