/**
 * Based on {@link https://github.com/stripe/react-stripe-js/blob/master/src/utils/usePrevious.ts} (MIT)
 */

import * as React from 'react';

export const usePrevious = <T>(value: T): T => {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
