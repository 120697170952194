import { gql } from '@apollo/client';

export const USER_EMAIL = gql`
  query UserEmail($email: EmailAddress!) {
    userByEmail(email: $email) {
      id
      email
    }
  }
`;
